import AuthedClient, {
  GetProcessesPerformanceResponse,
} from "../client/AuthedClient";
import { Page } from "../components/Page";
import { useAuthedClient } from "../context/AuthedClientContext";
import { Filters } from "../context/FiltersContext";
import { ApplicationsPerformanceBody } from "../components/PerformanceComponents";
export const ApplicationsPerformance = () => {
  const authedClient = useAuthedClient();
  return (
    <Page
      fetchData={(filters) => fetchData(authedClient, filters)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Dashboard",
        subTitle: "Applications Performance",
      }}
    />
  );
};
async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<GetProcessesPerformanceResponse> {
  const { startDate, endDate, group, user } = filters;
  return await authedClient.getProcessesPerformance(
    startDate,
    endDate,
    group,
    user
  );
}
function shouldShowNoData(data: GetProcessesPerformanceResponse): boolean {
  const newArray = data.filter((filter) => {
    if ((filter.totalDurationInSeconds / 60).toFixed(2) === "0.00") {
      return 0;
    } else {
      return filter;
    }
  });
  return newArray.length === 0;
}
function body(data: GetProcessesPerformanceResponse) {
  return <ApplicationsPerformanceBody processes={data} />;
}
