import { useEffect, useState } from "react";
import {
  GetLicenseMonitoringResponse,
  GetLicenseMonitoringResponseDetailsProcess,
} from "../../client/AuthedClient";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { useFilters } from "../../context/FiltersContext";
import { ToastFunction } from "../../utils/ToastFunction";
import {
  Table,
  // width150pxStyle, width80pxStyle
} from "../Table";
import { LicenseMonitoringModal } from "./";
import { DetailsIcon } from "../Icons";
import StateIcon from "../Icons/StateIcon";
type DetailsState = Array<GetLicenseMonitoringResponseDetailsProcess>;
export const LicenseMonitoringTable = (props: {
  license: GetLicenseMonitoringResponse;
}) => {
  const { license } = props;
  const [showModal, setShowModal] = useState(false);
  const [licenseApps, setlicenseApps] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const filters = useFilters();
  const { startDate, endDate, group, user } = filters;
  const authedClient = useAuthedClient();
  const [details, setDetails] = useState<DetailsState>([]);
  const [objDetails, setObjDetails] = useState<any>([]);

  const handleModalData = (applicationId: number, appId: number) => {
    authedClient
      .getApplicationLicense()
      .then((res) => {
        const objDetails = res.filter(
          (item: any) => item.applicationId === appId
        );
        setObjDetails(objDetails);
      })
      .catch((err) => console.log(err));
    authedClient
      .getLicenseMonitoringDetails(
        applicationId,
        startDate,
        endDate,
        group,
        user
      )
      .then((res) => {
        if (res.length > 0) {
          setDetails(res);
          setShowModal(true);
        } else {
          ToastFunction("No Data Available.");
        }
      });
  };
  useEffect(() => {
    authedClient.getApplicationLicense().then((res: any) => {
      setlicenseApps(res);
    });
  }, [authedClient]);
  const rowStrings = {
    key: "applicationName",
    items: license.map((name) => [name.applicationName]),
  };
  function getRecordId(id: number) {
    const targetObj: any = licenseApps.filter(
      (el: any) => el.applicationId === id
    )[0];
    const recordId = targetObj.details[0].recordId;
    return recordId;
  }
  const tableColumns = [
    {
      name: "Application Name",
      attr: "applicationName",
      style: { width: "155px" },
    },
    {
      name: "Application Version",
      attr: "applicationVersion",
      style: { width: "165px" },
    },
    // {
    //   name: "Total License Capacity",
    //   attr: "numberOfLicenses",
    //   style: { width: "190px" },
    // },
    {
      name: "Available Licenses",
      attr: "numberOfActiveLicenses",
      style: { width: "130px" },
    },
    // {
    //   name: "Application Usage",
    //   attr: "numberOfDevices",
    //   style: { width: "160px" },
    // },
    {
      name: "Utilization Percentage",
      attr: "utilization",
      style: { width: "190px" },
    },
    // {
    //   name: "License Consumption",
    //   attr: "consumption",
    //   style: { width: "180px" },
    // },
    { name: "Details", attr: "", style: { width: "85px" } },
  ];
  const rows = (array: any) => {
    return array.map((lic: any, index: number) => (
      <tr key={index}>
        <td>{lic.applicationName}</td>
        <td>
          {lic.applicationVersion === null ? "-" : lic.applicationVersion}
        </td>
        {/* <td>{lic.numberOfLicenses}</td> */}
        <td>{lic.numberOfActiveLicenses}</td>
        {/* <td>{lic.numberOfDevices}</td> */}
        <td className="percent-val d-flex">
          <div className="d-flex w-50">
            <div className="d-flex justify-content-between item-val w-100">
              <div>
                {lic.utilization > 0 ? lic.utilization.toFixed(2) : 0} %
              </div>
              <div>
                {lic.utilization > 0 && (
                  <div>
                    {50 <= lic.utilization.toFixed(2) &&
                      lic.utilization.toFixed(2) <= 80 && (
                        <StateIcon color="#FFCC55" />
                      )}
                    {lic.utilization.toFixed(2) < 50 && (
                      <StateIcon color="#F04438" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`state-info ${
              lic.utilization.toFixed(2) < 50 ? "low" : "medium"
            }`}
          >
            {50 <= lic.utilization.toFixed(2) &&
              lic.utilization.toFixed(2) <= 80 &&
              "Medium"}
            {lic.utilization.toFixed(2) < 50 && "Low"}
          </div>
        </td>
        {/* <td>{lic.consumption}</td> */}
        <td>
          <DetailsIcon
            handleOpenModal={() => {
              handleModalData(
                getRecordId(lic.applicationId),
                lic.applicationId
              );
              setModalTitle(lic.applicationName);
            }}
          />
        </td>
      </tr>
    ));
  };
  const rowsData: any = [];
  const excelData: any = [];
  license
    .filter((app: any) => app.numberOfActiveLicenses > 0)
    .forEach((el: any) => {
      let utilization =
        el.numberOfActiveLicenses > 0
          ? (el.numberOfDevices / el.numberOfActiveLicenses) * 100
          : 0;
      let utilizationLabel =
        50 <= utilization && utilization <= 80
          ? "medium"
          : utilization < 50
          ? "low"
          : "";
      let excelObj = {
        applicationName: el.applicationName,
        applicationVersion:
          el.applicationVersion === null ? "-" : el.applicationVersion,
        numberOfLicenses: el.numberOfActiveLicenses,
        // numberOfDevices: el.numberOfDevices,
        utilization:
          utilization > 0
            ? utilization.toFixed(2) + `% ${utilizationLabel}`
            : 0,
        // consumption: el.numberOfActiveLicenses - el.numberOfDevices,
      };
      let rowsObj = {
        applicationId: el.applicationId,
        applicationName: el.applicationName,
        applicationVersion:
          el.applicationVersion === null ? "-" : el.applicationVersion,
        numberOfLicenses:
          el.numberOfActiveLicenses + el.numberOfInActiveLicenses,
        numberOfActiveLicenses: el.numberOfActiveLicenses,
        // numberOfDevices: el.numberOfDevices,
        utilization: utilization,
        // consumption: el.numberOfActiveLicenses - el.numberOfDevices,
      };
      excelData.push(excelObj);
      rowsData.push(rowsObj);
    });
  return (
    <>
      <Table
        pageTitle={"License Monitoring"}
        rows={rows}
        rowsData={rowsData}
        excelData={excelData}
        columns={tableColumns}
        rowsStrings={rowStrings}
      />
      {showModal && details.length > 0 ? (
        <LicenseMonitoringModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalTitle={modalTitle}
          details={details}
          objDetails={objDetails}
        />
      ) : (
        ""
      )}
    </>
  );
};
