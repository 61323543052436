import { MaliciousTypes } from "../staticData/securityMonitoring";

interface TimeObject {
  [key: string]: { [incident: string]: number };
}

interface seriesData {
  name: string;
  data: number[];
}

export function transformNumbersToMaliciousTypes(timeobject: any): any {
  const result: any = {};

  // Iterate over each time slot in the timeobject
  for (const timeSlot in timeobject) {
    const incidents = timeobject[timeSlot];
    result[timeSlot] = {};

    // Iterate over each incident in the current time slot
    for (const incident in incidents) {
      const incidentKey = parseInt(incident);
      const incidentName = MaliciousTypes[incidentKey];
      result[timeSlot][incidentName] = incidents[incident];
    }
  }

  return result;
}

export function transformTimeObjectToseriesData(
  timeobject: TimeObject
): seriesData[] {
  // Initialize an object to store the results temporarily
  const tempData: { [incident: string]: number[] } = {};

  // Get the time slots and sort them to maintain order
  const timeSlots = Object.keys(timeobject);

  // Iterate over each time slot and populate tempData
  timeSlots.forEach((timeSlot) => {
    const incidents = timeobject[timeSlot];
    for (const incident in incidents) {
      if (!tempData[incident]) {
        tempData[incident] = new Array(timeSlots.length).fill(0);
      }
      const index = timeSlots.indexOf(timeSlot);
      tempData[incident][index] = incidents[incident];
    }
  });

  // Convert tempData to the desired format
  const seriesData: seriesData[] = [];
  for (const incident in tempData) {
    seriesData.push({ name: incident, data: tempData[incident] });
  }

  return seriesData;
}
