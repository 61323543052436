import Image from "./devicesMonitoring.svg";
function DeviceMonitorIcon() {
  const style = {
    width: "21px",
    height: "27px",
  };
  return <img src={Image} alt="" style={style} />;
}

export default DeviceMonitorIcon;
