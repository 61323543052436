import ReactApexChart from "react-apexcharts";
import {
  transformNumbersToMaliciousTypes,
  transformTimeObjectToseriesData,
} from "../../utils/maliciousUrls";
import { colorArray } from "../ItPerfromanceComponents/DountChart";

export function StackedChart(data: any) {
  const categories = Object.keys(data.data);

  const maliciousTypes = transformNumbersToMaliciousTypes(data.data);
  const seriesData = transformTimeObjectToseriesData(maliciousTypes);
  if (seriesData.length === 0) return <div></div>;
  return (
    <div
      className="py-4 w-50 my-4 rounded"
      style={{ backgroundColor: "#ECEEF9" }}
    >
      <h3 style={{ fontWeight: "500" }} className="px-4">
        Malicious URL Times{" "}
      </h3>
      <ReactApexChart
        series={seriesData}
        options={{
          colors: colorArray,

          chart: {
            type: "bar",
            height: 350,
            width: "50%",
            stacked: true,
          },

          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: "smooth",
            width: 1,
            colors: ["#fff"],
          },

          xaxis: {
            categories: categories,
            //   title: {
            //     text: "X title",
            //   },
            labels: {
              rotateAlways: true,
              rotate: -45,
              maxHeight: 2,
            },
          },
          fill: {
            opacity: 1,
          },
          yaxis: {
            title: {
              //   text: "titles",
              offsetX: -5,
              style: {
                fontWeight: 400,
                fontSize: "1rem",
              },
            },
            labels: {
              formatter: (value: any) => {
                return value;
              },
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 5,
          },

          tooltip: {
            y: {
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          plotOptions: {
            bar: {
              // horizontal: true,
              columnWidth: "30", // Adjust the bar width here
              borderRadius: 15, // Adjust the border radius to round the corners

              dataLabels: {
                position: "top",
              },
            },
          },
        }}
        type="bar"
        height={300}
        width={"100%"}
        color={"blue"}
      />
    </div>
  );
}
