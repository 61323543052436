import React from "react";
import { Navigate } from "react-router-dom";
import { User } from "../context/AuthContext";

interface ProtectedComponentProps {
  component: React.ComponentType<any>;
  user: User;
  requiredPermissions: string[];
}

const ProtectedComponent = ({
  component: Component,
  user,
  requiredPermissions,
  ...rest
}: ProtectedComponentProps) => {
  const hasPermission = () =>
    requiredPermissions.some((permission) =>
      user.permissions.includes(permission)
    );

  const userHasRoles = () => Array.isArray(user.roles) && user.roles.length > 0;

  if (!userHasRoles() || !hasPermission())
    return <Navigate to={"/unauthorized"} />;

  return <Component {...rest} />;
};

export default ProtectedComponent;
