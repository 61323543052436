import { CloseButton, Modal } from "react-bootstrap";
import {
  GetLicenseMonitoringDetails,
  GetLicenseMonitoringResponseDetailsProcess,
  AppLicenseMonitorType,
} from "../../client/AuthedClient";
import { Table } from "../Table";
import { TabbedContainer } from "../TabbedContainer";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";
export const LicenseMonitoringModal = (props: {
  showModal: boolean;
  setShowModal: (boolean: boolean) => void;
  modalTitle: string;
  details: GetLicenseMonitoringDetails;
  objDetails: AppLicenseMonitorType;
}) => {
  const { showModal, modalTitle, setShowModal, details, objDetails } = props;
  const rowStrings = {
    key: "computerName",
    items: details.map((detail: GetLicenseMonitoringResponseDetailsProcess) => [
      detail.computerName,
    ]),
  };
  const columns = [{ name: "Device Name", attr: "computerName" }];
  const rows = (array: Array<GetLicenseMonitoringResponseDetailsProcess>) => {
    return array.map((detail, index) => (
      <tr key={index}>
        <td>{detail.computerName}</td>
      </tr>
    ));
  };
  /**
   * License expiration Tab.
   */
  const LicenseExpirationColumns = [
    {
      name: "Number Of Licenses",
      attr: "numberOfLicenses",
      style: { width: "170px" },
    },
    {
      name: "Status",
      attr: "status",
      style: { width: "85px" },
    },
    {
      name: "license Start Date",
      attr: "licenseStartDate",
      style: { width: "155px" },
    },
    {
      name: "license Expiration Date",
      attr: "licenseExpirationDate",
      style: { width: "190px" },
    },
    {
      name: "Type",
      attr: "type",
      style: { width: "190px" },
    },
  ];
  const LicenseExpirationRows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr
        key={index}
        className={`${process.status === "Expired" ? "expiredRow" : ""}`}
      >
        <td>{process.numberOfLicenses}</td>
        <td>{process.status}</td>
        <td>
          {ConverToLocalAndFormat(process.licenseStartDate, "dd/MM/yyyy")}
        </td>
        <td>
          {process.licenseExpirationDate
            ? ConverToLocalAndFormat(
                process.licenseExpirationDate,
                "dd/MM/yyyy"
              )
            : "-"}
        </td>
        <td>
          {process.licenseExpirationDate === null
            ? "Perpetual"
            : "Subscription"}
        </td>
      </tr>
    ));
  };
  const LicenseExpirationRowStrings = {
    key: "numberOfLicenses",
    items: objDetails?.[0]?.details.map((process: any) => [
      process.numberOfLicenses,
    ]),
  };
  const LicenseExpirationRowsData: any = [];
  const LicenseExpirationExcelData: any = [];
  objDetails?.[0]?.details.forEach((el: any) => {
    const licenseStartDateFormat = ConverToLocalAndFormat(
      el.licenseStartDate,
      "D/M/YYYY"
    );
    const licenseExpiredDateFormat = ConverToLocalAndFormat(
      el.licenseExpirationDate,
      "D/M/YYYY"
    );
    let excelObj = {
      numberOfLicenses:
        el.numberOfLicenses === null ? "-" : el.numberOfLicenses,
      status: el.status === null ? "-" : el.status,
      licenseStartDate:
        el.licenseStartDate === null ? "-" : licenseStartDateFormat,
      licenseExpirationDate:
        el.licenseExpirationDate === null ? "-" : licenseExpiredDateFormat,
      Type: el.licenseExpirationDate === null ? "Perpetual" : "Subscription",
    };
    let obj = {
      recordId: el.recordId,
      status: el.status,
      numberOfLicenses: el.numberOfLicenses,
      licenseStartDate: el.licenseStartDate,
      licenseExpirationDate: el.licenseExpirationDate,
      Type: el.licenseExpirationDate === null ? "Perpetual" : "Subscription",
    };
    LicenseExpirationExcelData.push(excelObj);
    LicenseExpirationRowsData.push(obj);
  });
  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
    >
      <div className="Main-modal">
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <CloseButton onClick={() => setShowModal(false)} />
        </Modal.Header>
        <Modal.Body>
          <TabbedContainer
            tabs={[
              {
                title: "Devices",
                body: (
                  <Table
                    pageTitle={"License Monitoring Devices"}
                    rows={rows}
                    rowsData={details.map(
                      (el: GetLicenseMonitoringResponseDetailsProcess) => ({
                        computerName: el.computerName,
                      })
                    )}
                    excelData={details.map(
                      (el: GetLicenseMonitoringResponseDetailsProcess) => ({
                        computerName: el.computerName,
                      })
                    )}
                    columns={columns}
                    rowsStrings={rowStrings}
                  />
                ),
              },
              {
                title: "License Expiration",
                body: (
                  <Table
                    pageTitle={"License Expiration Monitoring"}
                    rows={LicenseExpirationRows}
                    rowsData={LicenseExpirationRowsData}
                    excelData={LicenseExpirationExcelData}
                    columns={LicenseExpirationColumns}
                    rowsStrings={LicenseExpirationRowStrings}
                  />
                ),
              },
            ]}
          />
        </Modal.Body>
      </div>
    </Modal>
  );
};
