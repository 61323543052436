import { fetchData, items } from "../../screens/ApplicationsCompliance";
import { Filters } from "../../context/FiltersContext";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { useCallback, useState } from "react";
import {
  GetApplicationsAndDevicesComplianceDetail,
  GetApplicationsAndDevicesComplianceResponse,
} from "../../client/AuthedClient";
import { Page } from "../Page";
import { ApplicationsComplianceTable } from "./ComplianceComponents/ApplicationsComplianceTable";
import RadialBarChart from "./ComplianceComponents/RadialBarChart";
import { useParams } from "react-router-dom";
import { DevicesComplianceTab } from "./ComplianceComponents/DevicesComplianceTab";
import Tabs from "../../layout/Tabs";
export const ApplicationCompilanceTabPage = (): any => {
  const authedClient = useAuthedClient();
  const [ComplianceTab, setComplianceTab] = useState("Non Compliant");

  const fetchDataCallback = useCallback(
    (filters: Filters) => fetchData(authedClient, filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const shouldShowNoDataCallback = useCallback(
    (data: GetApplicationsAndDevicesComplianceResponse) =>
      data.totalApplicationsCount === 0,
    []
  );
  const { applicationsCompliance } = useParams();
  const tab =
    applicationsCompliance === "applicationsCompliance"
      ? ApplicationsComplianceTab
      : DevicesComplianceTab;

  function ApplicationsComplianceTab(
    data: GetApplicationsAndDevicesComplianceResponse,
    onDataChange: () => void
  ) {
    const allowedApplication: Array<GetApplicationsAndDevicesComplianceDetail> =
      [];
    const notAllowedApplication: Array<GetApplicationsAndDevicesComplianceDetail> =
      [];
    data.applicationsDetails.forEach((app) => {
      if (app.isAllowed) allowedApplication.push(app);
      if (!app.isAllowed) notAllowedApplication.push(app);
    });

    return (
      <div className="row white-box">
        <RadialBarChart
          complied={data.allowedApplicationsCount}
          notComplied={data.notAllowedApplicationsCount}
          text="Applications"
        />
        <Tabs
          titles={["Non Compliant", "Compliant"]}
          activeTab={ComplianceTab}
          setActiveTab={setComplianceTab}
        />
        {ComplianceTab === "Compliant" && (
          <ApplicationsComplianceTable
            onDataChange={onDataChange}
            applications={allowedApplication}
            pdfTitle={"Compliant"}
          />
        )}
        {ComplianceTab === "Non Compliant" && (
          <ApplicationsComplianceTable
            onDataChange={onDataChange}
            applications={notAllowedApplication}
            pdfTitle={"Non Compliant"}
          />
        )}
      </div>
    );
  }
  return (
    <Page
      fetchData={fetchDataCallback}
      shouldShowNoData={shouldShowNoDataCallback}
      body={tab}
      title={{
        mainTitle: "Dashboard",
        subTitle: "Applications Compliance",
      }}
      items={items}
    />
  );
};
