/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { NavLink, To } from "react-router-dom";
export type PageLinksProps = {
  items?: any;
};
function PageLinks(props: PageLinksProps) {
  return (
    <div className="customTab">
      <ul className="nav nav-tabs">
        {props?.items?.map(
          (
            item: {
              path: To;
              title:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
            },
            index: number
          ) => {
            return (
              <li className="nav-item" key={index}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to={item?.path}
                >
                  {item?.title}
                </NavLink>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
}
export default PageLinks;
