import styled from "styled-components";
import { Circle } from "@styled-icons/fluentui-system-filled/Circle";
const Stateicon = styled(Circle)`
  width: 16px;
  height: 16px;
`;
function StateIcon({ color }: { color: string }) {
  return <Stateicon color={`${color}`} />;
}
export default StateIcon;
