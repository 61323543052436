import AuthedClient, {
  GetApplicationsUsageSummaryResponse,
} from "../client/AuthedClient";
import { ApplicationsUsageSummaryTable } from "../components/ApplicationUsageSummary/ApplicationUsageSummaryTable";
import { Page } from "../components/Page";
import { useAuthedClient } from "../context/AuthedClientContext";
import { Filters } from "../context/FiltersContext";

export const ApplicationsUsageSummary = () => {
  const authedClient = useAuthedClient();

  return (
    <div className="app-usage-filters">
      <Page
        fetchData={(filters) => fetchData(authedClient, filters)}
        shouldShowNoData={shouldShowNoData}
        body={body}
        title={{
          mainTitle: "Applications Monitoring",
          subTitle: "Applications Usage Report",
        }}
      />
    </div>
  );
};

async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<GetApplicationsUsageSummaryResponse> {
  const { group, user } = filters;

  return await authedClient.getApplicationsUsageSummary(group, user);
}

function shouldShowNoData(data: GetApplicationsUsageSummaryResponse): boolean {
  return data.applicationsData.length === 0;
}

function body(data: GetApplicationsUsageSummaryResponse) {
  return (
    <div className="white-box m-0">
      <div className="row">
        <div className="col-lg-12">
          <ApplicationsUsageSummaryTable data={data} />
        </div>
      </div>
    </div>
  );
}
