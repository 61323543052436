import { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import {
  GetProcessesVisitedSitesDetailsProcess,
  GetVisitedSitesResponse,
  GetVisitedSitesResponseProcess,
} from "../../client/AuthedClient";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { useFilters } from "../../context/FiltersContext";
import { ToastFunction } from "../../utils/ToastFunction";
import { Table, width100pxStyle, width62pxStyle } from "../Table";
import { VisitedSitesModal } from "./VisitedSitesModal";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";
import { DetailsIcon } from "../Icons";

//this function wrap Tab name with very long characters , this effect the pdf table
export function wrapText(text: string) {
  const charactersPerLine = 40;
  const words = text.match(new RegExp(`.{1,${charactersPerLine}}`, "g"));
  let lines = [""];
  words?.forEach((word) => {
    const line = lines[lines.length - 1];
    if ((line + word).length <= charactersPerLine) {
      lines[lines.length - 1] += (line === "" ? "" : " ") + word;
    } else lines.push(word);
  });

  return lines.join("<br>");
}

//this removes the browser name from the tab name
export const handleString = (siteName: string) => {
  const searchRegExp = new RegExp(" — ", "g");
  siteName = siteName.replace(searchRegExp, "-");
  if (siteName.includes("-")) {
    const newString = siteName.lastIndexOf("-");
    siteName = siteName.slice(0, newString);
  }
  return wrapText(siteName);
};

export const VisitedSitesTable = (props: {
  sites: GetVisitedSitesResponse;
}) => {
  const { sites } = props;
  const authedClient = useAuthedClient();
  const filters = useFilters();
  const { startDate, endDate, user, group } = filters;
  type DetailsState = Array<GetProcessesVisitedSitesDetailsProcess>;
  const [details, setDetails] = useState<DetailsState>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleModalData = (appName: string) => {
    authedClient
      .getVisitedSitesDetails(appName, startDate, endDate, group, user)
      .then((res) => {
        if (res.length > 0) {
          setDetails(res);
          setShowModal(true);
        } else {
          ToastFunction("No Data Available.");
        }
      });
  };
  const colwidth =
    window.innerWidth <= 505
      ? { width: "150px" }
      : window.innerWidth >= 1400
      ? { width: "60px" }
      : width100pxStyle;
  const columns = [
    { name: "Website", attr: "tabName" },
    { name: "Browser", attr: "applicationName" },
    { name: "Total Duration %", attr: "percentage", style: colwidth },
    { name: "Last Visit", attr: "lastVisit", style: colwidth },
    { name: "Details", attr: "", style: width62pxStyle },
  ];
  const rowStrings = {
    key: "tabName",
    items: sites.map((site) => [site.tabName]),
  };

  const rows = (array: Array<GetVisitedSitesResponseProcess>) => {
    return array.map((site, index) => {
      return (
        <tr key={index}>
          <td
            dangerouslySetInnerHTML={{ __html: handleString(site.tabName) }}
          ></td>
          <td>{site.applicationName}</td>
          <td>{site.percentage}%</td>
          <td>{site.lastVisit}</td>
          <td>
            <DetailsIcon
              handleOpenModal={() => {
                handleModalData(site.tabName);
                setModalTitle(site.tabName);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const rowsData = sites.map((el) => {
    const obj = {
      tabName: el.tabName,
      applicationName: el.applicationName,
      percentage:
        el.percentage < 0.005
          ? el.percentage.toFixed(3)
          : el.percentage.toFixed(2),
      lastVisit: ConverToLocalAndFormat(el.lastVisit, "dd/MM/yyyy HH:mm:ss"),
    };
    return obj;
  });

  const excelData = sites.map((el) => {
    const obj = {
      tabName: handleString(el.tabName),
      applicationName: el.applicationName,
      percentage:
        el.percentage < 0.005
          ? el.percentage.toFixed(3)
          : el.percentage.toFixed(2),
      lastVisit: ConverToLocalAndFormat(el.lastVisit, "dd/MM/yyyy HH:mm:ss"),
    };
    return obj;
  });
  return (
    <>
      <div>
        <Table
          pageTitle={"Visited Sites"}
          rows={rows}
          rowsData={rowsData}
          excelData={excelData}
          columns={columns}
          rowsStrings={rowStrings}
        />
      </div>
      {showModal ? (
        <Modal
          keyboard={false}
          centered={true}
          scrollable={true}
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
            <CloseButton onClick={() => setShowModal(false)} />
          </Modal.Header>
          <Modal.Body>
            <VisitedSitesModal details={details} />
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};
