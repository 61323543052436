/* eslint-disable array-callback-return */
import "../../css/ToolTip&LegendStyles.css";
// import { GetItPerformanceResponse } from "../../client/AuthedClient";
import ReactApexChart from "react-apexcharts";
export const colorArray = [
  "#ffd101",
  "#00f991",
  "#00c2ff",
  "#8c00ff",
  "#fe00b0",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];
function DountChart(props: { data: any; title: string; attr: any }) {
  const { data, title, attr } = props;

  let totalAvgUsage: any = 0;
  let yAxisData: number[] = [];
  let labels: string[] = [];
  let otherFound = false;
  let otherTotalAvgUsage: any = 0;
  let otherString = "others";
  (function () {
    for (var i = 0; i < data.length; i++) {
      totalAvgUsage +=
        attr === "averageCPU"
          ? data[i].averageCPU
          : data[i].averageMemoryPercentage;
    }
    data?.forEach((item: any) => {
      const itemYAxis =
        attr === "averageCPU"
          ? (item.averageCPU / totalAvgUsage) * 100
          : (item.averageMemoryPercentage / totalAvgUsage) * 100;
      if (itemYAxis < 5) {
        otherTotalAvgUsage += itemYAxis;
      } else {
        yAxisData.push(itemYAxis);
        labels.push(item.processName);
      }
    });
    if (!otherFound) {
      labels.push(otherString);
      otherFound = true;
    }
    yAxisData.push(otherTotalAvgUsage);
  })();
  function renderAppName(name: string) {
    if (name.length > 40) {
      const index = name.indexOf(":");
      if (index > 0) return name.slice(0, index);
      else return name;
    } else return name;
  }
  return (
    <div>
      <h3>{title}</h3>
      <ReactApexChart
        options={{
          colors: colorArray,
          labels: labels,
          grid: {
            padding: {
              top: 40,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            onItemHover: {
              highlightDataSeries: false,
            },
          },
          chart: {
            type: "donut",
            height: "500px",
            width: 100,
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              const valFixed = Number(val).toFixed(1);
              return valFixed + "%";
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: "50%",
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 500,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          tooltip: {
            custom: function ({ series, seriesIndex }) {
              if (labels[seriesIndex] === "others") {
                const HTML: any = `<div class="custom-tooltip">
                    <div class="custom-tooltip-main-child">
                    <ul class="tooltip-class-ul">${data
                      .filter((app: any) => {
                        const itemYAxis =
                          attr === "averageCPU"
                            ? (app.averageCPU / totalAvgUsage) * 100
                            : (app.averageMemoryPercentage / totalAvgUsage) *
                              100;
                        if (itemYAxis < 5) {
                          return app;
                        }
                      })
                      .map((app: any, index: number) => {
                        const item =
                          attr === "averageCPU"
                            ? (app.averageCPU / totalAvgUsage) * 100
                            : (app.averageMemoryPercentage / totalAvgUsage) *
                              100;
                        switch (true) {
                          case index < 10:
                            const truncateName =
                              app.processName.length > 25
                                ? app.processName.slice(0, 25) + "..."
                                : app.processName;
                            return `<li class="tooltip-class-li" key={${index}}>•
                          ${renderAppName(truncateName)} ${
                              attr === "averageCPU"
                                ? app.averageCPU === 0
                                  ? 0
                                  : item.toFixed(1)
                                : app.averageMemoryPercentage === 0
                                ? 0
                                : item.toFixed(1)
                            }%</li>`;
                          case index === 11:
                            return `<li class="tooltip-class-li" key={${index}}>•  ...</li>`;
                        }
                      })}
                    </ul>
                    </div>
                </div>`;
                return HTML.replaceAll(",", "");
              } else {
                return `<div class="custom-tooltip">
                    <div class="custom-tooltip-main-child">
                    ${renderAppName(labels[seriesIndex])} ${series[
                  seriesIndex
                ].toFixed(1)}%
                    </div>
                </div>`;
              }
            },
          },
        }}
        series={yAxisData}
        type="donut"
        height={403}
      />
    </div>
  );
}
export default DountChart;
