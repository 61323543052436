import Image from "./EmployeesProductivityV2.svg";
function EmployeesMonitorIcon() {
  const style = {
    width: "21px",
    height: "27px",
  };
  return <img src={Image} alt="" style={style} />;
}

export default EmployeesMonitorIcon;
