import { GetApplicationsAndDevicesComplianceResponse } from "../../../client/AuthedClient";
import { ApplicationsComplianceDevicesTable } from "./ApplicationsComplianceDevicesTable";
import RadialBarChart from "./RadialBarChart";

export function DevicesComplianceTab(
  data: GetApplicationsAndDevicesComplianceResponse
) {
  return (
    <div className="row">
      <div className="col-xl-12">
        <RadialBarChart
          complied={data.compliedDevicesCount}
          notComplied={data.notCompliedDevicesCount}
          text="Devices"
        />
      </div>
      <div className="col-xl-12 ">
        <ApplicationsComplianceDevicesTable devices={data.devicesList} />
        {/* <h1 className="me-auto">Non Compliant Devices</h1> */}
      </div>
    </div>
  );
}
