

import {  useParams } from 'react-router-dom';
import { useCallback } from "react";
import { useAuthedClient } from "../../../context/AuthedClientContext";
import { Filters } from "../../../context/FiltersContext";
import "../../../css/it-performanceStyles.css";
import { Page } from "../../Page";
import { fetchData, items } from "../../../screens/ItPerformance";
import { MemoryPerformanceScreenBody } from "./MemoryPerformancePage";
import { DiskPerformanceScreenBody } from "./DiskPerformancePage";
import { cpuPerformanceScreenBody } from './CpuPerformancePage';

export const ItPerformancePage = (): any => {
  const authedClient = useAuthedClient();
  const fetchDataCallback = useCallback(
    (filters: Filters) => fetchData(authedClient, filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const shouldShowNoDataCallback = useCallback(
    (data: any) => data[0].length === 0,
    []
  );
   const {itPerformancePage}=useParams()
   const tab =itPerformancePage ==='cpuPerformance'? cpuPerformanceScreenBody: itPerformancePage === "memoryPerformance"? MemoryPerformanceScreenBody:  DiskPerformanceScreenBody ;
  return (
    <Page
      fetchData={fetchDataCallback}
      shouldShowNoData={shouldShowNoDataCallback}
      body={tab}
      title={{
        mainTitle: "Dashboard",
        subTitle: "CPU Performance",
      }}
      items={items}
    />
  );
};

