import { Link } from "react-router-dom";

export function AccessDenied() {
  return (
    <div
      className="m-4 p-4 d-flex flex-column font-weight-normal h-100"
      style={{ fontSize: "1rem ", fontWeight: "500" }}
    >
      <h1 className="pb-4">Access Denied</h1>
      <p>
        We're sorry, but you do not have permission to access this resource.
      </p>
      <p>
        Your current user account does not have the necessary permissions to
        view this page or perform the requested action.
      </p>
      <p>
        If you believe you should have access to this resource,please contact
        your system administrator or IT support team for assistance.
      </p>
      <p>
        <Link to="/">Nexey Home Page</Link>
      </p>
    </div>
  );
}
