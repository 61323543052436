import { DateTime } from "luxon";

import http from "../httpService";

const url = "/UrlDashboard/UrlLabelCounts";
http.setAuthorizationToken();

export function getMaliciousActivitiesURLs(
  fromDate: DateTime,
  toDate: DateTime,
  computerId: number | undefined
) {
  return http.get(
    `${url}?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()} ${
      computerId !== undefined ? `&computerId=${computerId}` : ""
    }`
  );
}
