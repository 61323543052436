import { useState } from "react";
import {
  GetApplicationsAndDevicesComplianceDetail,
  GetNotCompliedApplicationsInstancesProcess,
} from "../../../client/AuthedClient";
import { useFilters } from "../../../context/FiltersContext";
import { Table } from "../../Table";
import { ApplicationsComplianceAddModal } from "./ApplicationsComplianceAddModal";
import { ApplicationsComplianceDetailsModal } from "./ApplicationsComplianceDetailsModal";
import { DetailsIcon } from "../../Icons";
import { getDevicesComplianceInstances } from "../../../services/GetDevicesComplianceInstances";

export const ApplicationsComplianceTable = (props: {
  applications: Array<GetApplicationsAndDevicesComplianceDetail>;
  onDataChange: () => void;
  pdfTitle: string;
}) => {
  const { applications, onDataChange, pdfTitle } = props;
  const filters = useFilters();
  const { group, user } = filters;
  const [lgShow, setLgShow] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [addMessageApplicationName] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [details, setDetails] = useState<
    Array<GetNotCompliedApplicationsInstancesProcess>
  >([]);

  function handleDetailsResponse(
    applicationId: number,
    isAllowed: boolean,
    currentGroup: number,
    currentUser: number | undefined
  ) {
    getDevicesComplianceInstances(
      applicationId,
      isAllowed,
      currentGroup,

      currentUser
    ).then((res: any) => {
      setDetails(res.data.data);
    });
  }

  const columns = [
    {
      name: "Application Name",
      attr: "applicationName",
      style: { minWidth: "170px" },
    },

    {
      name: "Application Version",
      attr: "applicationVersion",
      style: { width: "170px" },
    },
    {
      name: "No. Of Devices",
      attr: "devicesCount",
      style: { width: "130px" },
    },
    { name: "Details", attr: "", style: { width: "80px" } },
  ];
  user && delete columns[2];

  const excelData = applications.map((item: any) => {
    return {
      applicationName: item.applicationName,
      applicationVersion:
        item.applicationVersion === null || item.applicationVersion === ""
          ? "-"
          : item.applicationVersion,
      devicesCount: item.devicesCount,
    };
  });
  const rows = (array: Array<GetApplicationsAndDevicesComplianceDetail>) => {
    return array.map((application, index) => (
      <tr key={index}>
        <td>{application.applicationName}</td>
        <td>
          {application.applicationVersion === null ||
          application.applicationVersion === ""
            ? "-"
            : application.applicationVersion}
        </td>
        {!user && <td>{application.devicesCount}</td>}
        <td>
          <DetailsIcon
            handleOpenModal={() => {
              setModalTitle(application.applicationName);
              handleDetailsResponse(
                application.applicationId,
                application.isAllowed,
                group,
                user
              );
              setTimeout(() => {
                setLgShow(true);
              }, 500);
            }}
          />
        </td>
      </tr>
    ));
  };
  const rowStrings = {
    key: "applicationName",
    items: applications.map((application) => [application.applicationName]),
  };
  return (
    <div className="col-xl-12 globalSpaceBetweenContainers">
      {/* <h1 className="me-auto">Non Compliant Applications</h1> */}
      <Table
        pageTitle={`${pdfTitle} Applications`}
        rows={rows}
        rowsData={applications}
        columns={columns}
        rowsStrings={rowStrings}
        excelData={excelData}
      />
      <ApplicationsComplianceDetailsModal
        lgShow={lgShow}
        setLgShow={setLgShow}
        modalTitle={modalTitle}
        details={details}
        pdfTitle={pdfTitle}
      />
      <ApplicationsComplianceAddModal
        addMessageApplicationName={addMessageApplicationName}
        onDataChange={onDataChange}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
      />
    </div>
  );
};
