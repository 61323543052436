import { Table } from "../../Table";
import { formatDateFromISO } from "../../../utils/DateUtility";
import { wrapText } from "../../VisitedSites/VisitedSitesTable";
import { NoData } from "../../NoData";

const columns = [
  {
    name: "Website",
    attr: "visitetSites",
  },
  {
    name: "Last visited date",
    attr: "lastVisitedDate",
  },
  {
    name: "Duration %",
    attr: "duration",
  },
];

export default function UserActivity(data: any) {
  const rowStrings = {
    key: "tabName",
    items: data.data.length > 0 && data?.data,
  };
  const rows = (array: Array<any>) => {
    return array.map((data, index) => {
      return (
        <tr key={index}>
          <td dangerouslySetInnerHTML={{ __html: wrapText(data.tabName) }}></td>
          <td>{formatDateFromISO(data.lastVisit)}</td>
          <td>{Math.round(data.percentage)}%</td>
        </tr>
      );
    });
  };

  let documentData: any[] = data.data.map((site: any) => {
    return {
      tabName: site.tabName,
      lastVisit: formatDateFromISO(site.lastVisit),
      percentage: Math.round(site.percentage) + "%",
    };
  });

  return (
    <div>
      {data.data.length > 0 ? (
        <Table
          pageTitle={"User Activity"}
          // intialPage={intialPage}
          rows={rows}
          rowsData={data.data}
          excelData={documentData}
          columns={columns}
          rowsStrings={rowStrings}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
}
