import { CloseButton, Modal } from "react-bootstrap";
import { GetApplicationsUsagePerUser } from "../../client/AuthedClient";
import { Table } from "../Table";

export const ApplicationsUsageSummaryModal = (props: {
  showModal: boolean;
  setShowModal: (boolean: boolean) => void;
  modalTitle: string;
  details: GetApplicationsUsagePerUser | undefined;
}) => {
  const { showModal, modalTitle, setShowModal, details } = props;
  const rowStrings = {
    key: "computerName",
    items: details?.data?.map((detail) => [detail.computerName]),
  };

  const columns = [
    {
      name: "Device Name",
      attr: "computerName",
      // style: window.innerWidth > 1000 ? { width: "100px" } : { width: "180px" },
    },
    {
      name: "Last Month %",
      attr: "lastMonth",
      style: { width: "130px" },
    },
    {
      name: "Last Quarter %",
      attr: "lastQuarter",
      style: { width: "140px" },
    },
    {
      name: "Last Half %",
      attr: "lastHalf",
      style: { width: "115px" },
    },
    {
      name: "Last Year %",
      attr: "lastYear",
      style: { width: "115px" },
    },
  ];

  const rows = (array: Array<any>) => {
    return array.map((detail, index) => {
      console.log(detail);

      return (
        <tr key={index}>
          <td>{detail.computerName}</td>
          <td>{detail.lastMonth}%</td>
          <td>{detail.lastQuarter}%</td>
          <td>{detail.lastHalf}%</td>
          <td>{detail.lastYear}%</td>
        </tr>
      );
    });
  };

  // const month = (lastMonthDuration / lastMonthTotalDuration) * 100;

  const lastMonthTotalDuration: any = details?.lastMonthTotalDuration;
  const lastQuarterTotalDuration: any = details?.lastQuarterTotalDuration;
  const lastHalfTotalDuration: any = details?.lastHalfTotalDuration;
  const lastYearTotalDuration: any = details?.lastYearTotalDuration;

  const rowsDataArray: any = details?.data?.map((el: any) => {
    let lastMonth, lastQuarter, lastHalf, lastYear: any;
    lastMonth = (el.lastMonthDuration / lastMonthTotalDuration) * 100;
    lastMonth =
      lastMonth === 100
        ? lastMonth
        : Number.isNaN(lastMonth) || lastMonth <= 0
        ? 0
        : lastMonth < 0.005
        ? lastMonth?.toFixed(3)
        : lastMonth?.toFixed(2);

    lastQuarter = (el.lastQuarterDuration / lastQuarterTotalDuration) * 100;
    lastQuarter =
      lastQuarter === 100
        ? lastQuarter
        : Number.isNaN(lastQuarter) || lastQuarter <= 0
        ? 0
        : lastQuarter < 0.005
        ? lastQuarter?.toFixed(3)
        : lastQuarter?.toFixed(2);

    lastHalf = (el.lastHalfDuration / lastHalfTotalDuration) * 100;
    lastHalf =
      lastHalf === 100
        ? lastHalf
        : Number.isNaN(lastHalf) || lastHalf <= 0
        ? 0
        : lastHalf < 0.005
        ? lastHalf?.toFixed(3)
        : lastHalf?.toFixed(2);

    lastYear = (el.lastYearDuration / lastYearTotalDuration) * 100;
    lastYear =
      lastYear === 100
        ? lastYear
        : Number.isNaN(lastYear) || lastYear <= 0
        ? 0
        : lastYear < 0.005
        ? lastYear?.toFixed(3)
        : lastYear?.toFixed(2);
    return {
      computerName: el.computerName,
      lastMonth: +lastMonth,
      lastQuarter: +lastQuarter,
      lastHalf: +lastHalf,
      lastYear: +lastYear,
    };
  });
  const excelData: any = details?.data?.map((el: any) => {
    let lastMonth, lastQuarter, lastHalf, lastYear: any;
    lastMonth = (el.lastMonthDuration / lastMonthTotalDuration) * 100;
    lastMonth =
      lastMonth === 100
        ? lastMonth
        : Number.isNaN(lastMonth) || lastMonth <= 0
        ? 0
        : lastMonth < 0.005
        ? lastMonth?.toFixed(3)
        : lastMonth?.toFixed(2);

    lastQuarter = (el.lastQuarterDuration / lastQuarterTotalDuration) * 100;
    lastQuarter =
      lastQuarter === 100
        ? lastQuarter
        : Number.isNaN(lastQuarter) || lastQuarter <= 0
        ? 0
        : lastQuarter < 0.005
        ? lastQuarter?.toFixed(3)
        : lastQuarter?.toFixed(2);

    lastHalf = (el.lastHalfDuration / lastHalfTotalDuration) * 100;
    lastHalf =
      lastHalf === 100
        ? lastHalf
        : Number.isNaN(lastHalf) || lastHalf <= 0
        ? 0
        : lastHalf < 0.005
        ? lastHalf?.toFixed(3)
        : lastHalf?.toFixed(2);

    lastYear = (el.lastYearDuration / lastYearTotalDuration) * 100;
    lastYear =
      lastYear === 100
        ? lastYear
        : Number.isNaN(lastYear) || lastYear <= 0
        ? 0
        : lastYear < 0.005
        ? lastYear?.toFixed(3)
        : lastYear?.toFixed(2);

    return {
      computerName: el.computerName,
      lastMonth: +lastMonth,
      lastQuarter: +lastQuarter,
      lastHalf: +lastHalf,
      lastYear: +lastYear,
    };
  });
  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
        <CloseButton onClick={() => setShowModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Table
          pageTitle={"Application Summary Usage Modal"}
          rows={rows}
          rowsData={rowsDataArray}
          excelData={excelData}
          columns={columns}
          rowsStrings={rowStrings}
        />
      </Modal.Body>
    </Modal>
  );
};
