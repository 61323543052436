import { DateRangePicker } from "rsuite";
import enUS from "rsuite/locales/en_US";
import { DateTime } from "luxon";
import "./FilterWithRangeDate.css";
// import "rsuite/dist/rsuite.min.css"; // Import the rsuite styles
// import "../../css/PageHeaderStyles.css";

function FilterWithRangeDate(props: any) {
  // Convert JavaScript Date to Luxon DateTime and handle the change
  const handleChange = (dateRange: Date[] | null): void => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const luxonRange: [DateTime, DateTime] = [
        DateTime.fromJSDate(dateRange[0]),
        DateTime.fromJSDate(dateRange[1]),
      ];
      props.HandelUAFilterChange(luxonRange);
    }
  };
  const { afterToday }: any = DateRangePicker;
  const options: Array<{
    name: string;
    startDate: DateTime;
    endDate: DateTime;
  }> = [
    {
      name: "Today",
      startDate: DateTime.now(),
      endDate: DateTime.now(),
    },
    {
      name: "Yesterday",
      startDate: DateTime.now().minus({ day: 1 }),
      endDate: DateTime.now().minus({ day: 1 }),
    },
    {
      name: "Last 7 Days",
      startDate: DateTime.now().minus({ day: 6 }),
      endDate: DateTime.now(),
    },
    {
      name: "Last 30 Days",
      startDate: DateTime.now().minus({ day: 29 }),
      endDate: DateTime.now(),
    },
    {
      name: "This month",
      startDate: DateTime.now().set({ day: 1 }),
      endDate: DateTime.now(),
    },
    {
      name: "Last month",
      startDate: DateTime.now().minus({ month: 1 }).set({ day: 1 }),
      endDate: DateTime.now().set({ day: 1 }).minus({ day: 1 }),
    },
  ];
  const dateRangePickerRanges = options.map((option) => ({
    label: option.name,
    value: [option.startDate.toJSDate(), option.endDate.toJSDate()],
    closeOverlay: true,
  }));
  return (
    <div className="page-title justify-content-sm-center flex-sm-column d-md-flex flex-md-row justify-content-md-between align-items-center">
      <div className="topFilter row g-2 mb-2 mb-md-0">
        <div className="col-auto rsuite-custom-class filterBody">
          <div className="topFilter row g-2">
            <DateRangePicker
              cleanable={false}
              format="MMM dd, yyyy"
              locale={enUS}
              value={[
                props.filterVal[0].toJSDate(),
                props.filterVal[1].toJSDate(),
              ]}
              disabledDate={afterToday()}
              ranges={dateRangePickerRanges as any}
              onChange={handleChange}
              placement="topEnd"
            />
            <i className="icon-down-open-mini" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterWithRangeDate;