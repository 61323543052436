import { useRemoteActions } from "./RemoteActionsContext";
import RemoteActionsTable from "./RemoteActionsTable";
import FormModal from "./FormModal";
import { DeleteModal } from "../DataEntry";
import { NoData } from "../NoData";
import DiscardChangesModal from "../common/DiscardChangesModal/DiscardChangesModal";
import "./RemoteActions.css";
import { useEffect } from "react";
import { Action } from "../../services/RemoteActionsService";

const RemoteActionsBody = ({ data }: { data: Action[] }) => {
  const {
    action,
    actions,
    showFormModal,
    showDeleteModal,
    showDiscardModal,
    handleAddNewAction,
    deleteButtonHandler,
    handleCloseDeleteModal,
    handleCloseDiscardModal,
    CloseAndReset,
    setActionsState,
  } = useRemoteActions();
  useEffect(() => {
    setActionsState(data);
  }, [data, setActionsState]);
  const deleteButton = (
    <div>
      <button onClick={() => deleteButtonHandler()} className="delete-btn">
        Delete
      </button>
    </div>
  );

  return (
    <>
      <div className="remoteAvtionsHeader">
        <div>Actions List</div>
        <button onClick={() => handleAddNewAction()}><i className="icon-plus-circle" /> New Action</button>
      </div>

      {actions.length > 0 ? (
        <>
          <RemoteActionsTable />
          {showDeleteModal && (
            <DeleteModal
              showDeleteModal={showDeleteModal}
              handleCloseDeleteModal={handleCloseDeleteModal}
              currentAppName={action.actionName}
              deleteButton={deleteButton}
            />
          )}
        </>
      ) : (
        <NoData />
      )}
      {showFormModal && <FormModal />}
      {showDiscardModal && (
        <DiscardChangesModal
          showDiscardModal={showDiscardModal}
          handleCloseDiscardModal={handleCloseDiscardModal}
          onDiscardClicked={CloseAndReset}
        />
      )}
    </>
  );
};

export default RemoteActionsBody;
