/**
 * Returns a rounded number.
 * @param number The number to be rounded.
 * @param desimalPlaces Number of digits after the decimal point.
 */
export const roundNumber = (number: number, desimalPlaces: number): number => {
  const dP: number = Math.pow(10, desimalPlaces);
  return Math.round(number * dP) / dP;
};

/**
 * Returns a percentage number.
 * @param denominator The denominator number .
 * @param numerator The numerator number.
 */
export const calculatePercentage = (
  denominator: number,
  numerator: number
): number => (denominator / numerator) * 100;
