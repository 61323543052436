import { LockClosed } from "@styled-icons/fluentui-system-filled/LockClosed";
import styled from "styled-components";
const LockClosedIcon = styled(LockClosed)`
  color: #6a6a6a;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    color: #36429e;
  }
  &:focus,
  &:active {
    color: #4453c5;
  }
`;
function LockPasswordIcon(props: { handlePass?: () => void }) {
  return <LockClosedIcon onClick={props.handlePass} />;
}

export default LockPasswordIcon;
