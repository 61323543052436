import http from "./httpService";

const actionsUrl = "/RemoteAction/GetAllRemoteActions";
const excutionUrl = "/Execution/ExecuteAction";
const getResultUrl = "/Execution/GetComputerActionsResult";

export type Action = {
  id: number;
  category: number;
  actionName: string;
  description: string;
  command: string;
};
export type ActionHistory = {
  id: number;
  time: Date;
  remoteActionId: number;
  result: string;
  executionDate: Date;
  isSuccess: boolean;
};
export type Result = {
  isSuccess: boolean;
  message: string;
};
export type MappedActionHistory = {
  actionName: string;
  remoteActionId: number;
  result: string;
  details: string;
  executionDate: Date;
  isSuccess: boolean;
};

export async function GetActions() {
  const res = await http.get(actionsUrl);
  return res.data.data;
}


export async function GetActionsHistory(computerId: string) {
  const actionHistory = await http.get(
    `${getResultUrl}?computerId=${computerId}`
  );

  return actionHistory;
}
export async function ExecuteAction(
  remoteActionId: string,
  computerId: string
) {
  const res = await http.post(
    `${excutionUrl}?computerId=${computerId}&remoteActionId=${remoteActionId}`
  );

  return res;
}
