/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import { map12To24 } from "../../utils/time-util/map12to24";
export const colorArray = [
  "#4453C5",
  "#19D2EB",
  "#FFB200",
  "#F04438",
  "#E6B333",
  "#fe00b0",
  "#FF1A66",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
];
export let allAppPieData: any = [];
const alldata: Array<string> = [];
let xAxisForChart: any = [];
let sum: any = 0;
export function getchartappsNames(data: []) {
  data?.map((hour: any) => {
    if (hour.apps.length > 0) {
      hour.apps.map((app: any) => {
        alldata.push(app.processName);
        return 0;
      });
    }
    return 0;
  });

  let uniquename = new Set<string>();
  for (const data of alldata) {
    uniquename.add(data);
  }

  return uniquename;
}
// pie chart calc
export function groupdatatopieData(res: any) {
  allAppPieData = [];
  let pieData: any = [];
  const names: any = getchartappsNames(res?.data);
  const namesarr: any = [];
  let sum = 0;
  let others = 0;
  let irrlevantNames: any = [];
  names.forEach((element: any) => {
    namesarr.push(element);
  });
  namesarr.map((el: any) => {
    res?.data?.map((apps: any) => {
      if (apps?.apps?.length > 0) {
        apps?.apps?.map((app: any) => {
          if (app?.processName === el) {
            sum += app?.duration;
          }
          return 0;
        });
      }
      return 0;
    });
  });
  namesarr.map((el: any, number: number) => {
    let duration = 0;

    res?.data?.map((apps: any) => {
      if (apps?.apps?.length > 0) {
        apps?.apps?.map((app: any) => {
          if (app?.processName === el) {
            duration += app?.duration;
          }
          return 0;
        });
      }
      return 0;
    });
    allAppPieData.push({ name: el, percentage: (duration / sum) * 100 });
    if ((duration / sum) * 100 > 10) {
      pieData.push(duration);
    } else {
      others += duration;
      irrlevantNames.push(number);
    }
  });
  for (let i = 0; i < irrlevantNames.length; i++) {
    delete namesarr[irrlevantNames[i]];
  }
  pieData.push(others);
  namesarr.push("others");
  return [pieData, namesarr, sum];
}
export const renderAppName = (name: string) => {
  if (name.length > 22) {
    const index = name.indexOf(":");
    if (index > 0) return name.slice(0, index) + "...";
    else return name;
  } else return name;
};
export const namesArr = (tempnamnes: any) => {
  let namesarr: any[] = [];
  for (var i = 0; i < tempnamnes.length; i++) {
    if (tempnamnes[i] !== undefined) {
      namesarr.push(tempnamnes[i]);
    }
  }
  return namesarr;
};
// accordion calc
export const handleAccordionData = (res: any) => {
  const accordionData = res?.data?.reduce((acc: any, el: any) => {
    const apps = el.apps;
    if (apps.length > 0) {
      const hourObj = {
        hour:
          res.timeLabel === "Hour"
            ? map12To24(String(apps[0].xAxisValue))
            : apps[0].xAxisValue,
        show: false,
        AppData: apps.map((app: any) => ({
          ApplicationName: app.processName,
          minutes: app.duration / 60,
        })),
        sumData:
          apps.reduce((sum: any, app: any) => sum + app.duration, 0) / 60,
      };
      acc.push(hourObj);
    }
    return acc;
  }, []);
  return accordionData;
};
// line chart calc
const updateChartXAxisValues = (res: any) => {
  const { data: { data, timeLabel } }: any = res;
  if (timeLabel !== "Hour") {
    xAxisForChart = data?.map((item: any) => {
      return item?.xAxisValue;
    });
  } else {
    xAxisForChart = data?.map((item: any) => {
      return map12To24(String(item?.xAxisValue));
    });
  }
};
const handleChartYAxisValues = (
  objIndex: number,
  element: any,
  xAxis: [],
  currentUser: number,
  yAxis: any
) => {
  if (objIndex !== -1) {
    for (let i = 0; i < xAxis.length; i++) {
      if (element.xAxisValue === xAxis[i]) {
        if (currentUser === 0) {
          const dur = `${((element?.duration / sum) * 100).toFixed(2)}%`;
          yAxis[objIndex].data[i] = dur;
        } else {
          const dur = (element?.duration / 60).toFixed(2);
          yAxis[objIndex].data[i] = dur;
        }
      }
    }
  } else {
    const objData: any[] = [];
    for (let i = 0; i < xAxis.length; i++) {
      if (element.xAxisValue === xAxis[i]) {
        if (currentUser === 0) {
          const dur = `${((element?.duration / sum) * 100).toFixed(2)}%`;
          objData[i] = dur;
        } else {
          const dur = (element?.duration / 60).toFixed(2);
          objData[i] = dur;
        }
      } else {
        objData[i] = null;
      }
    }
    yAxis.push({
      name: element?.processName,
      data: objData,
    });
  }
};
const handleChartData = (
  data: any,
  currentUser: any,
  yAxis: any,
  xAxis: any
) => {
  data?.forEach((item: any) => {
    item.apps.forEach((el: any) => {
      sum += el.duration;
    });
    item.apps.forEach((element: any) => {
      const objIndex = yAxis.findIndex(
        (el: any) => el.name === element?.processName
      );
      handleChartYAxisValues(objIndex, element, xAxis, currentUser, yAxis);
    });
    sum = 0;
  });
};
export const chartData = (res: any, currentUser: any): [][] => {
  let xAxis: any = [];
  let yAxis: any = [];
  const { data } = res;
  xAxis = data?.data?.map((item: any) => {
    return item?.xAxisValue;
  });
  updateChartXAxisValues(res);
  handleChartData(data.data, currentUser, yAxis, xAxis);
  return [yAxis, xAxisForChart];
};
