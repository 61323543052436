import http from "./httpService";

const url = "/Computer/GetAllComputers";

const deviceUrl = "/Computer/GetAllDevicesFromComputerSummaries	";
const deviceInfoUrl = "/Computer/GetDeviceInfo";
const deviceAppsUrl = "/Computer/GetDeviceApps";
http.setAuthorizationToken();

export function getAllComputers() {
  return http.get(url);
}

export function getAllDevices(groupId, computerId) {
  console.log(computerId);
  if (computerId) return http.get(`${deviceUrl}?computerId=${computerId}`);
  return http.get(`${deviceUrl}`);
}

export function getDeviceInfo(computerId) {
  return http.get(deviceInfoUrl + `?computerId=${computerId}`);
}

export function getDeviceApps(computerId) {
  return http.get(deviceAppsUrl + `?computerId=${computerId}`);
}
