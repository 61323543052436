import { Link, Outlet, useParams } from "react-router-dom";
import PageLinks from "../PageLinks";
import { useEffect, useMemo, useState, SetStateAction } from "react";
import DeviceInformation from "./tabs/DeviceInformation";
import SystemResources from "./tabs/SystemResources";
import Applications from "./tabs/Applications";
import UserActivity from "./tabs/UserActivity";
import RemoteActions from "./tabs/RemoteActions/RemoteActions";
import { Page } from "../Page";
import {
  getAllComputers,
  getDeviceInfo,
  getDeviceApps,
} from "../../services/Devices";
import { useAuthedClient } from "../../context/AuthedClientContext";
import AuthedClient from "../../client/AuthedClient";
import { Filters } from "../../context/FiltersContext";
import { NoData } from "../NoData";
import FilterWithRangeDate from "../FilterWithRangeDate/FilterWithRangeDate";
import { DateTime } from "luxon";
import {
  GetActions,
  GetActionsHistory,
} from "../../services/RemoteActionPageService";
export default function SingleDevice() {
  const authedClient = useAuthedClient();

  const { id, tab, name } = useParams();
  const tabs = useMemo(
    () => [
      {
        title: "Device Information",
        path: `/device-view/Device-Information/${id}/${name}`,
      },
      {
        title: `System Resources`,
        path: `/device-view/System-Resources/${id}/${name}`,
      },
      {
        title: `Applications`,
        path: `/device-view/Applications/${id}/${name}`,
      },
      {
        title: `User Activity`,
        path: `/device-view/User-Activity/${id}/${name}`,
      },
      {
        title: `Remote Actions`,
        path: `/device-view/Remote-Actions/${id}/${name}`,
      },
    ],
    [id, name]
  );
  const today = DateTime.now();
  const last30Days = today.minus({ days: 29 });

  let [userActivityFilter, setUserActivityFilter] = useState<
    [DateTime, DateTime]
  >([last30Days, today]);

  const onUAFilterChange = (
    filtersArr: SetStateAction<[DateTime, DateTime]>
  ) => {
    setUserActivityFilter(filtersArr);
  };


  useEffect(() => {}, [tab]);

  function body(data: any) {
    return (
      <div className="page-container">
        <p className="h3 py-3">
          <Link className="mx-2" to="/devices-list">
            &#129120;
          </Link>
          Device Details
        </p>
        <PageLinks items={tabs} />
        {data.length === 0 && (
          <div className="p-4">
            <NoData />
          </div>
        )}

        {tab === "Device-Information" && data.deviceInfoData && (
          <DeviceInformation data={data.deviceInfoData.data} />
        )}
        {tab === "System-Resources" && data.systemResourcesData && (
          <SystemResources data={data.systemResourcesData} />
        )}
        {tab === "Applications" && data.deviceAppsData && (
          <Applications data={data.deviceAppsData.data} />
        )}
        {tab === "User-Activity" && data.visitedSitesData && (
          <>
            <FilterWithRangeDate
              HandelUAFilterChange={onUAFilterChange}
              filterVal={userActivityFilter}
            />
            <UserActivity data={data.visitedSitesData} />
          </>
        )}
        {tab === "Remote-Actions" && data.Actions && data.ActionsHistory && (
          <RemoteActions
            actionsData={data.Actions}
            actionsHistoryData={data.ActionsHistory.data.data}
          />
        )}

        <Outlet />
      </div>
    );
  }

  return (
    <div className="app-usage-filters">
      <Page
        fetchData={(filters) => {
          return (async () => {
            let deviceAppsData: any;
            let visitedSitesData: any;
            let deviceInfoData: any;
            let systemResourcesData: any;
            let Actions: any;
            let ActionsHistory: any;

            if (tab === "User-Activity") {
              visitedSitesData = await getVisitedSites(
                authedClient,
                {
                  ...filters,
                  startDate: userActivityFilter[0],
                  endDate: userActivityFilter[1],
                },
                parseInt(id || "1")
              );
            }

            if (tab === "Device-Information") {
              deviceInfoData = await getDeviceInfo(id);
            }

            if (tab === "Applications") {
              deviceAppsData = await getDeviceApps(id);
            }

            if (tab === "System-Resources") {
              systemResourcesData = await getAllComputers();
            }
            if (tab === "Remote-Actions") {
              Actions = await GetActions();

              if (id !== undefined)
                ActionsHistory = await GetActionsHistory(id);
            }
            return {
              visitedSitesData,
              deviceInfoData,
              deviceAppsData,
              systemResourcesData,
              Actions,
              ActionsHistory,
            };
          })();
        }}
        shouldShowNoData={() => false}
        body={body}
        title={{
          mainTitle: "Device",
          subTitle: tab as string,
        }}
      />
    </div>
  );
}

async function getVisitedSites(
  authedClient: AuthedClient,
  filters: Filters,
  id: number
): Promise<any> {
  const { startDate, endDate, group } = filters;

  return await authedClient.getVisitedSites(startDate, endDate, group, id);
}
