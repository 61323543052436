import { useState } from "react";
import { AgentAvailabilityTable } from "./AgentAvailabilityTabel";
import RadialBarChart from "./RadialBarChart";
export const AgentAvailabilityBody = ({ data }: any) => {
  const {
    data: tabelData,
    activeDevices,
    inActiveDevices,
    totalDevices,
  } = data;
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [deviceId, setDeviceID] = useState<number | null>(null);

  // variables for the Guage chart
  const percentageOfActiveDevices = (activeDevices / totalDevices) * 100;

  const onDetailsClick = async (computerName: string, computerId: number) => {
    setShowModal(true);
    setModalTitle(computerName);
    setDeviceID(computerId);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <RadialBarChart
          percentage={percentageOfActiveDevices}
          totalDevices={totalDevices}
          activeDevices={activeDevices}
          inActiveDevices={inActiveDevices}
        />
      </div>
      <div className="col-xl-12">
        <AgentAvailabilityTable
          tabelData={tabelData}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          modalTitle={modalTitle}
          onDetailsClick={onDetailsClick}
          deviceId={deviceId}
        />
      </div>
    </div>
  );
};
