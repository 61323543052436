import { DateTime } from "luxon";
import http from "../httpService";

const url = "/UrlDashboard/ComputerInfoByUrl?url=";
http.setAuthorizationToken();

export function getMaliciousActivitiesURLDetails(
  domain: string,
  fromDate: DateTime,
  toDate: DateTime,
  computerId: number | undefined,
  group: number | undefined
) {
  return http.get(
    `${url}${domain}&fromDate=${fromDate.toISODate()}&toData=${toDate.toISODate()}${
      computerId !== undefined ? `&computerId=${computerId}` : ""
    }${group !== 0 ? `&groupId=${group}` : ""}  
    `
  );
}
