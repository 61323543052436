import { ShieldCheckmark } from "@styled-icons/ionicons-sharp/ShieldCheckmark";
import styled from "styled-components";
const ShieldCheckmarStyled = styled(ShieldCheckmark)`
  width: 21px;
  height: 27px;
  color: #fff;
`;
function ShieldCheckmarkIcon(props: { handlePass?: () => void }) {
  return <ShieldCheckmarStyled onClick={props.handlePass} />;
}

export default ShieldCheckmarkIcon;
