import { CloseButton, Modal } from "react-bootstrap";
import "./DiscardChangesModal.css";

type DiscardModalType = {
  showDiscardModal: boolean;
  onDiscardClicked: () => void;
  handleCloseDiscardModal: () => void;
};

const DiscardChangesModal = ({
  showDiscardModal,
  onDiscardClicked,
  handleCloseDiscardModal,
}: DiscardModalType) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDiscardClicked();
  };
  return (
    <Modal
      className={`${
        showDiscardModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleCloseDiscardModal}
      show={showDiscardModal}
      // size="lg"
    >
      <form onSubmit={handleSubmit} className="modal-content">
        <Modal.Header>
          <Modal.Title>Discard Changes</Modal.Title>
          <CloseButton onClick={handleCloseDiscardModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="DiscardModalText">
            Are you sure you want to discard the changes you made?
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "0.625rem 1.5rem" }}>
          <div className="DiscardModalFooter">
            <button
              onClick={handleCloseDiscardModal}
              type="button"
              className="DiscardModalCanclebtn"
              data-bs-dismiss="modal"
            >
              Back
            </button>
            <button type="submit" className="btn btn-danger">
              Discard
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DiscardChangesModal;
