import { useEffect, useState } from "react";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { AllowedApps } from "../../client/AuthedClient";
import { Loader } from "../../components/Loader/Loader";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
import { DeleteModal } from "../../components/DataEntry";
import { Dropdown } from "react-bootstrap";
import {
  InstalledApplicationsTable,
  InstalledApplicationsModal,
} from "../../components/InstalledApplications";

type State =
  | {
      kind: "loading";
    }
  | {
      kind: "loaded";
      processes: Array<AllowedApps>;
    }
  | { kind: "errored" }
  | any;

export const InstalledApplications = () => {
  const authedClient = useAuthedClient();

  const [state, setState] = useState<State>({ kind: "loading" });
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentApp, setCurrentApp] = useState<undefined | number>(undefined);
  const [currentApps, setCurrentApps] = useState(0);
  const [currentAppName, setCurrentAppName] = useState<string>("");
  const [appUsers, setAppUsers] = useState<Array<any>>([]);

  const activeApplications = [
    "All Applications",
    "Allowed Applications",
    "Disallowed Applications",
  ];

  useEffect(() => {
    callGetAllowedApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApps]);

  function handleResponseReceived(response: Array<AllowedApps>) {
    setState({
      kind: "loaded",
      processes: response,
      displayed: response.filter((el: any) => {
        if (Number(currentApps) === 0) {
          return el;
        } else if (Number(currentApps) === 1) {
          return el.isAllowed === true;
        } else if (Number(currentApps) === 2) {
          return el.isAllowed === false;
        } else {
          return 0;
        }
      }),
    });
  }

  function handleErrored() {
    setState({ kind: "errored" });
  }

  function callGetAppUsers(id: number, name: string) {
    authedClient.getAppUsers(id).then((res) => {
      setAppUsers(res);
      getCurrentApp(id, name);
      setShowModal(true);
    });
  }

  function callGetAllowedApps() {
    authedClient
      .getAllowedApps()
      .then((res) => {
        handleResponseReceived(res);
      })
      .catch(() => handleErrored());
  }

  function callDeleteAllowedApps(id: number, applicationName: string) {
    authedClient.deleteAllowedApplications(id).then((res) => {
      if (res) {
        handleCloseDeleteModal();
        callGetAllowedApps();
        ToastSuccessFunction(`Success Delete Application ${applicationName}`);
      } else {
        handleCloseDeleteModal();
        ToastFunction(
          "Application is currently installed on one or more computer"
        );
      }
    });
  }

  function callAllowDisAllow(id: number) {
    authedClient.allowDisallowApp(id).then(() => {
      callGetAllowedApps();
    });
  }

  function getCurrentApp(id: number, name: string) {
    setCurrentApp(id);
    setCurrentAppName(name);
  }

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseAddModal = () => setShowModal(false);

  const handleDelete = (recordId: number, applicationName: string) => {
    getCurrentApp(recordId, applicationName);
    setShowDeleteModal(true);
  };

  const deleteButton = (
    <div>
      <button
        onClick={() => callDeleteAllowedApps(currentApp!, currentAppName)}
        className="delete-btn"
      >
        Delete
      </button>
    </div>
  );

  const InstalledApplicationsHeader = () => (
    // <div className="page-title justify-content-sm-center flex-sm-column d-md-flex flex-md-row justify-content-md-between align-items-center">
    <div className="page-title">
      {/* <div>
        <h2 className="me-auto">Configuration</h2>
        <h3 className="me-auto">
          <span>Configuration /</span> Installed Applications
        </h3>
      </div> */}
      <div className="d-grid w-100">
        <div className="topFilter row g-2" style={{ justifySelf: "flex-end" }}>
          <div className="col-auto">
            <div className=" w-auto">
              <div className="dropdown bootstrap-select installed-apps-dropdown">
                <Dropdown
                  onSelect={(eventKey: any) => setCurrentApps(Number(eventKey))}
                >
                  <Dropdown.Toggle
                    variant="light"
                    style={
                      false
                        ? { pointerEvents: "none", width: "auto" }
                        : { width: "auto" }
                    }
                  >
                    {currentApps === 0
                      ? "All Applications"
                      : currentApps === 1
                      ? "Allowed Applications"
                      : "Disallowed Applications"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {activeApplications.map((applications, i) => (
                      <Dropdown.Item
                        key={i}
                        eventKey={i}
                        active={currentApps === i}
                        className={`${
                          currentApps === i
                            ? "page-header-dropdown page-header-dropdown-light-text-active"
                            : "page-header-dropdown"
                        }`}
                      >
                        {applications}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="page-container">
      <InstalledApplicationsHeader />
      {state.kind === "loading" ? (
        <Loader />
      ) : state.kind === "loaded" ? (
        <>
          <InstalledApplicationsTable
            currentApps={currentApps}
            handleDelete={handleDelete}
            processes={state.displayed}
            callGetAppUsers={callGetAppUsers}
            callAllowDisAllow={callAllowDisAllow}
          />
          <InstalledApplicationsModal
            appUsers={appUsers}
            showModal={showModal}
            handleCloseAddModal={handleCloseAddModal}
            currentAppName={currentAppName}
          />
          <DeleteModal
            showDeleteModal={showDeleteModal}
            handleCloseDeleteModal={handleCloseDeleteModal}
            currentAppName={currentAppName}
            deleteButton={deleteButton}
          />
        </>
      ) : (
        <div>Something wrong happened. Please, contact the developers.</div>
      )}
    </div>
  );
};
