import { PropsWithChildren } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { AuthedClientProvider } from "./AuthedClientContext";
import { PublicClientProvider } from "./PublicClientContext";
import SideBarContext from "./SideBarContext";

function AppProviders(props: PropsWithChildren<{}>) {
  return (
    <PublicClientProvider>
      <AuthProvider>
        <SideBarContext>
          <AuthedClientProvider>
            <Router>{props.children}</Router>
          </AuthedClientProvider>
        </SideBarContext>
      </AuthProvider>
    </PublicClientProvider>
  );
}

export { AppProviders };
