/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-redeclare */
import { useContext, createContext, useState } from "react";
const TimeUtilizationContext = createContext<any>(undefined);
export const useTimeUtilizationContext = () =>
  useContext(TimeUtilizationContext);
function TimeUtilizationResponseProvider({ children }: any) {
  const [res, setRes] = useState<any>([]);
  return (
    <TimeUtilizationContext.Provider
      value={{
        res,
        setRes
      }}
    >
      {children}
    </TimeUtilizationContext.Provider>
  );
}
export { TimeUtilizationResponseProvider };
