import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
import { AxiosResponse } from "axios";

// export type InsertUpdateApiResponse = {
//   data: boolean;
//   message: string;
//   status: string;
//   statusCode: number;
// };
type Props = {
  title: string;
  visibility: boolean;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  callBackFunction: (data: any) => Promise<AxiosResponse<any, any>>;
  urlValue: string | null;
  data?: any;
  updateTable?: () => Promise<void>;
};
const urlRegex =
  /^(https?:\/\/)?([a-zA-Z\d-]+\.)*([a-zA-Z\d-]+\.[a-zA-Z]{2,6})(\/[\w.-]*)*\/?$/;

export default function DomainModal({
  title,
  visibility,
  setVisibility,
  callBackFunction,
  urlValue,
  data,
  updateTable,
}: Props) {
  const [domain, setDomain] = useState<string | null>(null);
  const [domainError, setDomainError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [type, setType] = useState<number | null>(data ? data.labelId : null);
  const validateDomain = () => {
    setDomainError(false);

    if (domain) {
      setDomainError(!urlRegex.test(domain));

      return urlRegex.test(domain);
    }
    if (urlValue) {
      setDomainError(!urlRegex.test(urlValue));

      return urlRegex.test(urlValue.trim());
    }
  };

  const handelSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validDomain = validateDomain();
    console.log(typeError, "error inside submit");

    if (type === null && data === null) {
      console.log("error ");
      setTypeError(true);
    }

    let insertUrlData: {
      url: string | null;
      labelId: number | null;
      id?: number;
    } = {
      url: domain ?? urlValue,
      labelId: type, // parseInt(type as string),
    };
    if (data) {
      insertUrlData = {
        id: data.id,
        url: domain ?? urlValue,
        labelId: type,
      };
    }
    console.log(insertUrlData, "new url Data");
    // console.log("is Valid ?", valid);
    // console.log("no type error?", !typeError);

    if (validDomain && !(type === null && data === null)) {
      setVisibility(false);
      try {
        callBackFunction(insertUrlData).then((response: any) => {
          if (response.data.data === true) {
            if (title === "Update Domain/URL")
              ToastSuccessFunction("Url Updated Successfully");
            else ToastSuccessFunction("New Url Added Successfully");
            updateTable && updateTable();
            handelCloseModal();
          }
          if (response.data.data === false) {
            if (response.data.statusCode === 1)
              ToastFunction("This URL already exists");
            else {
              ToastFunction(response.data.message);
            }
            return;
          }
        });
      } catch (error) {
        ToastFunction("Something went wrong!");
      }
    }

  };

  const handelCloseModal = () => {
    setVisibility(false);
    setDomain(null);
    setDomainError(false);
    setType(null);
    setTypeError(false);
  };
  return (
    <Modal
      className={`${
        visibility ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={() => {}}
      show={visibility}
      size="lg"
    >
      <form onSubmit={(e) => handelSubmit(e)} className="modal-content">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <CloseButton
            onClick={() => {
              handelCloseModal();
            }}
          />
        </Modal.Header>

        <Modal.Body>
          <div className="row g-3">
            <div className="col-lg-6">
              <label className="form-label">
                Domain/URL <span className="error-span">*</span>
              </label>
              <input
                type="text"
                maxLength={60}
                placeholder={title}
                value={`${domain === null ? urlValue : domain}`}
                onChange={(e) => {
                  setDomain(e.target.value.trim());
                }}
                className={`form-control ${domainError ? "error-input" : ""}`}
                required
              />
              {domainError && (
                <span className="error-span">
                  "Enter Valid Url format ex: www.example.com"
                </span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                Type <span className="error-span">*</span>
              </label>

              <select
                aria-controls="dtBasicExample"
                className="custom-select-form text-secondary form-control form-control-sm"
                onChange={(e) => {
                  setTypeError(false);
                  setType(parseInt(e.target.value));
                }}
              >
                <option
                  className="text-secondary flex justify-content-between"
                  selected
                  defaultValue={0}
                  disabled
                  hidden
                >
                  Type
                </option>
                <option
                  value={1}
                  selected={data?.labelId === 1}
                  className="text-secondary"
                >
                  Phishing
                </option>{" "}
                <option
                  value={2}
                  selected={data?.labelId === 2}
                  className="text-secondary"
                >
                 Defacement
                </option>
                <option
                  value={3}
                  selected={data?.labelId === 3}
                  className="text-secondary"
                >
                  Malware
                </option>
              </select>
              {typeError && (
                <p className="error-span mt-2">"Please choose Type"</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => handelCloseModal()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary px-4 mx-2 "
            disabled={typeError}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
