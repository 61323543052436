import { useState } from "react";
import {
  GetApplicationsUsagePerUser,
  GetApplicationsUsageSummary,
} from "../../client/AuthedClient";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { useFilters } from "../../context/FiltersContext";
import { ToastFunction } from "../../utils/ToastFunction";
import { Table } from "../Table";
import { ApplicationsUsageSummaryModal } from "./ApplicationUsageSummaryModal";
import { DetailsIcon } from "../Icons";
import moment from "moment";
export const ApplicationsUsageSummaryTable = (props: {
  data: GetApplicationsUsageSummary;
}) => {
  const { data } = props;
  const newEndPointArray = data.applicationsData;
  const filters = useFilters();
  const { group, user } = filters;
  const authedClient = useAuthedClient();
  const [details, setDetails] = useState<GetApplicationsUsagePerUser>();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleModalData = async (appName: string) => {
    let str = "";
    str = appName.replace(/\+/g, "%2B");
    str = str.replace(/:/, "%3A");
    await authedClient
      .getApplicationUsagePerUser(str, group, user)
      .then((res) => {
        if (res.data.length > 0) {
          setDetails(res);
          setModalTitle(appName);
          setShowModal(true);
        } else {
          ToastFunction("No data Found for this user");
        }
      });
  };
  const rowStrings = {
    key: "processName",
    items: newEndPointArray.map((name: any) => [name.processName]),
  };
  const dunamicCol = (desk: any, mobile: any) =>
    window.innerWidth > 1000 ? { width: `${desk}` } : { width: `${mobile}` };

  const today = new Date();

  const tooltibContent = (months: number) => {
    const date = new Date();
    const result = new Date(date.setMonth(date.getMonth() - months));
    return (
      moment(result).format("YYYY-MM-DD") +
      " to " +
      moment(today).format("YYYY-MM-DD")
    );
  };

  let tableColumns = [
    {
      name: "Application Name",
      attr: "processName",
      style: dunamicCol("400px", "200px"),
    },
    {
      name: "Last Month %",
      attr: "lastMonth",
      style: { width: "130px" },
      tooltib: tooltibContent(1),
    },
    {
      name: "Last Quarter %",
      attr: "lastQuarter",
      style: { width: "140px" },
      tooltib: tooltibContent(3),
    },
    {
      name: "Last Half %",
      attr: "lastHalf",
      style: { width: "110px" },
      tooltib: tooltibContent(6),
    },
    {
      name: "Last Year %",
      attr: "lastYear",
      style: { width: "115px" },
      tooltib: tooltibContent(12),
    },
  ];
  if (!user)
    tableColumns.push({
      name: "Details",
      attr: "",
      style: { width: "80px" },
    });
  const rows = (array: Array<any>) => {
    return array.map((application, index) => {
      return (
        <tr key={index}>
          <td>{application.processName}</td>
          <td>{application.lastMonth}%</td>
          <td>{application.lastQuarter}%</td>
          <td>{application.lastHalf}%</td>
          <td>{application.lastYear}%</td>
          {user === undefined && (
            <td>
              <DetailsIcon
                handleOpenModal={() => handleModalData(application.processName)}
              />
            </td>
          )}
        </tr>
      );
    });
  };
  const arrayNew =
    newEndPointArray.length > 0
      ? newEndPointArray.map((el: any) => {
          let lastMonth, lastQuarter, lastHalf, lastYear: any;
          lastMonth =
            (el.lastMonthDurationInSeconds /
              data.lastMonthTotalDurationInSeconds) *
            100;
          lastMonth =
            lastMonth === 100
              ? lastMonth
              : Number.isNaN(lastMonth) || lastMonth === 0
              ? 0
              : lastMonth < 0.005
              ? lastMonth?.toFixed(3)
              : lastMonth?.toFixed(2);
          lastQuarter =
            (el.lastQuarterDurationInSeconds /
              data.lastQuarterTotalDurationInSeconds) *
            100;
          lastQuarter =
            lastQuarter === 100
              ? lastQuarter
              : Number.isNaN(lastQuarter) || lastQuarter === 0
              ? 0
              : lastQuarter < 0.005
              ? lastQuarter?.toFixed(3)
              : lastQuarter?.toFixed(2);
          lastHalf =
            (el.lastHalfDurationInSeconds /
              data.lastHalfTotalDurationInSeconds) *
            100;
          lastHalf =
            lastHalf === 100
              ? lastHalf
              : lastHalf === 0
              ? 0
              : lastHalf < 0.005
              ? lastHalf?.toFixed(3)
              : lastHalf?.toFixed(2);
          lastYear =
            (el.lastYearDurationInSeconds /
              data.lastYearTotalDurationInSeconds) *
            100;
          lastYear =
            lastYear === 100
              ? lastYear
              : lastYear === 0
              ? 0
              : lastYear < 0.005
              ? lastYear?.toFixed(3)
              : lastYear?.toFixed(2);
          return {
            processName: el.processName,
            lastMonth: +lastMonth,
            lastQuarter: +lastQuarter,
            lastHalf: +lastHalf,
            lastYear: +lastYear,
          };
        })
      : [];
  const excelData = newEndPointArray.map((el: any) => {
    let lastMonth, lastQuarter, lastHalf, lastYear: any;
    lastMonth =
      (el.lastMonthDurationInSeconds / data.lastMonthTotalDurationInSeconds) *
      100;
    lastMonth =
      lastMonth === 100
        ? lastMonth
        : Number.isNaN(lastMonth) || lastMonth === 0
        ? 0
        : lastMonth < 0.005
        ? lastMonth?.toFixed(3)
        : lastMonth?.toFixed(2);
    lastQuarter =
      (el.lastQuarterDurationInSeconds /
        data.lastQuarterTotalDurationInSeconds) *
      100;
    lastQuarter =
      lastQuarter === 100
        ? lastQuarter
        : Number.isNaN(lastQuarter) || lastQuarter === 0
        ? 0
        : lastQuarter < 0.005
        ? lastQuarter?.toFixed(3)
        : lastQuarter?.toFixed(2);
    lastHalf =
      (el.lastHalfDurationInSeconds / data.lastHalfTotalDurationInSeconds) *
      100;
    lastHalf =
      lastHalf === 100
        ? lastHalf
        : lastHalf === 0
        ? 0
        : lastHalf < 0.005
        ? lastHalf?.toFixed(3)
        : lastHalf?.toFixed(2);
    lastYear =
      (el.lastYearDurationInSeconds / data.lastYearTotalDurationInSeconds) *
      100;
    lastYear =
      lastYear === 100
        ? lastYear
        : lastYear === 0
        ? 0
        : lastYear < 0.005
        ? lastYear?.toFixed(3)
        : lastYear?.toFixed(2);
    return {
      processName: el.processName,
      lastMonth: +lastMonth,
      lastQuarter: +lastQuarter,
      lastHalf: +lastHalf,
      lastYear: +lastYear,
    };
  });
  return (
    <>
      <Table
        pageTitle={"Application Usage Summary"}
        rows={rows}
        rowsData={arrayNew}
        excelData={excelData}
        columns={tableColumns}
        rowsStrings={rowStrings}
      />
      {showModal && newEndPointArray.length > 0 ? (
        <ApplicationsUsageSummaryModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalTitle={modalTitle}
          details={details}
        />
      ) : (
        <></>
      )}
    </>
  );
};
