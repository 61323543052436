import { Filters } from "../context/FiltersContext";
import { getAgentDevicesAvailability } from "../services/AgentDevicesAvailabilityService";
import { ToastFunction } from "./../utils/ToastFunction";
import { Page } from "../components/Page";
import { AgentAvailabilityBody } from "./../components/AgentAvailability/AgentAvailabilityBody";
import "../css/AgentAvailabilityStyles.css";
import "../css/ChartContainerStyles.css";
export const AgentAvailability = () => {
  return (
    <Page
      fetchData={(filters) => fetchData(filters)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Dashboard",
        subTitle: "Agent Availability",
      }}
    />
  );
};

const fetchData = async (filters: Filters) => {
  const { startDate, endDate, group, user } = filters;

  try {
    const res = await getAgentDevicesAvailability(
      startDate,
      endDate,
      group,
      user
    );
    const data = res?.data?.data;
    return data;
  } catch (error: any) {
    ToastFunction("Something went woring");
  }
};

function shouldShowNoData(data: any): boolean {
  return data?.length === 0;
}

function body(data: any) {
  return <AgentAvailabilityBody data={data} />;
}
