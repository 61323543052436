import { useEffect, useState } from "react";
import { Pagination } from "../DomainManagement/DomainTable";

type Props = {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  numberOfPages: number;
  totalRowsCount: number;
  paginationLogic: Pagination;
};
export const TablePaginationWithFetch = ({
  currentPage,
  pageSize,
  numberOfPages,
  totalRowsCount,
  paginationLogic,
  totalItems,
}: Props) => {
  const [screenWidth, setScreenWidth] = useState(700);
  const [visibleCount, setVisibleCount] = useState(5);

  useEffect(() => {
    if (window.innerWidth <= screenWidth) {
      setVisibleCount(3);
      setScreenWidth(window.innerWidth);
    } else {
      setVisibleCount(5);
      setScreenWidth(700);
    }
  }, [screenWidth]);

  let pageNumbersButtons = [];
  for (let i = 1; i <= numberOfPages; i++) {
    pageNumbersButtons.push(
      <li
        key={i}
        className={`${
          currentPage === i
            ? "paginate_button page-item active"
            : "paginate_button page-item"
        }`}
      >
        <button
          className="page-link"
          onClick={() => paginationLogic.setPageNumber(i)}
        >
          {i}
        </button>
      </li>
    );
  }
  if (totalRowsCount !== 0) {
    return (
      <div className="row table-pagination-row">
        <div className="col-sm-12 col-md-5">
          <div
            className="dataTables_info table-pagination-entries"
            id="dtBasicExample_info"
            role="status"
            aria-live="polite"
          >
            Showing {currentPage === 1 ? "1" : (currentPage - 1) * pageSize} to{" "}
            {currentPage === 1
              ? Math.min(pageSize, totalItems)
              : Math.min(
                  totalItems,
                  pageSize * (currentPage - 1) + pageSize
                )}{" "}
            of {totalItems} entries
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <div
            className="dataTables_paginate paging_simple_numbers table-pagination-numbers"
            id="dtBasicExample_paginate"
          >
            <ul className="pagination">
              <li
                className="paginate_button page-item previous"
                id="dtBasicExample_previous"
              >
                <button
                  className="page-link"
                  onClick={() => paginationLogic.setPageNumber(currentPage - 1)}
                  disabled={currentPage <= 1 ? true : false}
                >
                  Previous
                </button>
              </li>
              {numberOfPages > 5 && currentPage > visibleCount - 2 && (
                <li className="paginate_button page-item">
                  <button className="page-link">...</button>
                </li>
              )}
              {pageNumbersButtons.map((i) => i)}
              {numberOfPages > 5 &&
                currentPage < numberOfPages - Math.floor(visibleCount / 2) && (
                  <li className="paginate_button page-item ">
                    <button
                      className="page-link"
                      onClick={() =>
                        paginationLogic.setPageNumber(currentPage + 1)
                      }
                    >
                      ...
                    </button>
                  </li>
                )}
              <li
                className="paginate_button page-item next"
                id="dtBasicExample_next"
              >
                <button
                  className="page-link"
                  onClick={() => paginationLogic.setPageNumber(currentPage + 1)}
                  disabled={currentPage === numberOfPages ? true : false}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
