import AuthedClient from "../client/AuthedClient";
import { Filters } from "../context/FiltersContext";
import { Outlet } from "react-router-dom";
type item = {
  title: string;
  path: string;
};
export const items: Array<item> = [
  {
    title: "CPU Performance",
    path: "/it-performance/cpuPerformance",
  },
  {
    title: "Memory Performance",
    path: "/it-performance/memoryPerformance",
  },
  {
    title: "Disk Performance",
    path: "/it-performance/diskPerformance",
  },
];
export const ItPerformance = () => {
  return <Outlet />;
};
export async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<Array<any>> {
  const { startDate, endDate, group, user } = filters;
  const res1 = await authedClient.getProcessesItPerformance(
    startDate,
    endDate,
    group,
    user
  );
  const res2 = await authedClient.getLineChartData(
    startDate,
    endDate,
    group,
    user
  );
  const dataarr = [res1, res2];
  return dataarr;
}
