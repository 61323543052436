import * as React from "react";
import { PropsWithChildren, useEffect, useState } from "react";
import { usePublicClient } from "./PublicClientContext";
import { ToastFunction } from "../utils/ToastFunction";
import { setAuthorizationToken } from "../services/httpService";
import jwt_decode from "jwt-decode";

export type User = {
  username: string;
  roles: string[];
  permissions: string[];
  token: string;
  expiration: number;
  title: string;
  fullName: string;
};

export type Auth = {
  user: User | undefined;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  validationError: { show: boolean; message: string };
  setValidationError: (validationError: {
    show: boolean;
    message: string;
  }) => void;
};

const AuthContext = React.createContext<Auth | undefined>(undefined);

const InitializeUser = (userObject: any): User => {
  const userData: any = jwt_decode(userObject.token);

  return {
    username: userObject.username,
    roles: userData.Role?.split(","),
    permissions: userData.Permissions?.split(","),
    token: userObject.token,
    expiration: userData.exp,
    title: userData.Title,
    fullName: userData.FullName,
  };
};

function AuthProvider(props: PropsWithChildren<{}>) {
  const publicClient = usePublicClient();
  const [validationError, setValidationError] = useState({
    show: false,
    message: "",
  });

  const USER_LOCALSTORAGE_KEY = "user";

  const userJson = localStorage.getItem(USER_LOCALSTORAGE_KEY);

  const [user, setUser] = useState<User | undefined>(
    userJson === null ? undefined : InitializeUser(JSON.parse(userJson))
  );

  // Monitor token expiration and auto-logout if expired
  useEffect(() => {
    if (user?.expiration) {
      const expirationTime = user.expiration * 1000 - Date.now();

      if (expirationTime <= 0) {
        logout();
      } else {
        const timeoutId = setTimeout(() => {
          logout();
        }, expirationTime);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [user]);

  const login = async (username: string, password: string) => {
    try {
      const loginResponse: any = await publicClient.login(username, password);
      const userData: any = jwt_decode(loginResponse.token);
      const userObj = InitializeUser({
        ...userData,
        ...loginResponse,
        username,
      });
      if (userObj?.roles?.includes("User")) {
        ToastFunction("Wrong Username or Password!");
      } else {
        localStorage.setItem(
          USER_LOCALSTORAGE_KEY,
          JSON.stringify({ username: username, token: loginResponse.token })
        );
        setUser(userObj);
        setAuthorizationToken();
      }
    } catch (e) {
      // ToastFunction("Wrong Username or Password!");
      setValidationError({
        show: true,
        message: "Wrong Username or Password!",
      });

      throw e;
    }
  };

  const logout = () => {
    localStorage.clear();
    setUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{ user, login, logout, validationError, setValidationError }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
