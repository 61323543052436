import { ReactElement, Dispatch, SetStateAction } from "react";
import { Tab, Tabs } from "react-bootstrap";

const INITIAL_TAB_INDEX = 0;

export function TabbedContainer(props: {
  tabs: Array<{ title: string; body: ReactElement }>;
  setTabId?: Dispatch<SetStateAction<string>>;
}) {
  const { tabs, setTabId } = props;
  const handleTabId = (e: any) => {
    if (setTabId) {
      setTabId(e.target.id);
    }
  };
  return (
    <div className="customTab">
      <Tabs
        defaultActiveKey={INITIAL_TAB_INDEX}
        id="uncontrolled-tab-example"
        onClick={handleTabId}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} eventKey={index} title={tab.title}>
            {tab.body}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
