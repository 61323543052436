import http from "./httpService";

const url = "/RemoteAction";

export type IsShow = null | {
  key: number;
};
export type Action = {
  id: number;
  commandCategoryId: number;
  actionName: string;
  description: string;
  command: string;
};
export type MappedAction = {
  id: number;
  commandCategoryId: number;
  categoryName: string;
  actionName: string;
  description: string;
  command: string;
};

export async function GetActions(): Promise<{ data: { data: Action[] } }> {
  return await http.get(`${url}/GetAllRemoteActions`);
}

export async function UpdateAction(action: Action) {
  return await http.put(`${url}/UpdateRemoteAction`, action);
}

export async function AddAction(action: Action) {
  return await http.post(`${url}/InsertRemoteAction`, action);
}

export async function DeleteAction(id: number) {
  return await http.delete(`${url}/DeleteRemoteAction/${id}`);
}
