import { AxiosResponse } from "axios";
import DevicesTable from "../components/Devices/DevicesTable";
import { Page } from "../components/Page";
import { Filters } from "../context/FiltersContext";
import { getAllDevices } from "../services/Devices";

export const DevicesList = () => {
  return (
    <div className="app-usage-filters">
      <Page
        fetchData={(filters) => fetchData(filters)}
        shouldShowNoData={shouldShowNoData}
        body={body}
        title={{
          mainTitle: "Devices List",
          subTitle: "Devices List",
        }}
      />
    </div>
  );
};

export async function fetchData(
  filters: Filters
): Promise<AxiosResponse<any, any>> {
  const { group, user } = filters;
  return await getAllDevices(group, user);
}

function shouldShowNoData(data: any): boolean {
  return data.data.length === 0;
}

function body(data: any) {
  return (
    <div className="white-box m-0">
      <div className="row">
        <div className="col-lg-12">
          <DevicesTable data={data.data} />
        </div>
      </div>
    </div>
  );
}
