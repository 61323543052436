import { ReactElement } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import "../../css/DeleteModalStyles.css";
export const DeleteModal = (props: {
  showDeleteModal: boolean;
  handleCloseDeleteModal: () => void;
  currentAppName: string | null;
  deleteButton: ReactElement<HTMLButtonElement>;
}) => {
  const {
    showDeleteModal,
    handleCloseDeleteModal,
    currentAppName,
    deleteButton,
  } = props;
  return (
    <Modal
      className={`${
        showDeleteModal ? "popUpModal details-modal-opened" : "popUpModal"
      } delete-modal`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleCloseDeleteModal}
      show={showDeleteModal}
      size="lg"
    >
      <div className="Modal-Container">
        <Modal.Header>
          <div>
            <CloseButton onClick={handleCloseDeleteModal} />
          </div>
        </Modal.Header>
        <Modal.Body className="delete-modal-body">
          <div className="d-flex flex-column justify-content-center align-content-center align-items-center">
            <div className="Delete-icon-main-container">
              <div className="Delete-icon-container">
                <i className="icon-trash-empty"></i>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="text-center delete-Modal-Description">
                <h4>Are you sure?</h4>
                <p>
                  You are about to delete {currentAppName}, once deleted you can
                  not undo this action.
                </p>
              </div>
              <div className="delete-Modal-Btns-Container d-flex">
                <div>
                  <button
                    onClick={handleCloseDeleteModal}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
                {deleteButton}
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
