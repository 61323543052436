import { sum } from "lodash";
import ReactApexChart from "react-apexcharts";
import { roundNumber } from "../../utils/Mathimatics";
import "../../css/ToolTip&LegendStyles.css";
export const VisitedSitesPieChart = (props: any) => {
  const { sites } = props;
  const colorsArray = [
    "#ffd101",
    "#00f991",
    "#00c2ff",
    "#8c00ff",
    "#fe00b0",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];
  // Pie Data calculations
  const sitesNames: any = [];
  const sitesPercentages: any = [];
  const otherSitesNames: any = [];
  const othersPercentages: any = [];
  (function () {
    sites.forEach(
      (site: { tabName: string; percentage: number }) => {
        let cleanName = site.tabName.replace("—", "");
        cleanName = cleanName.length > 50 ? cleanName + "..." : cleanName;
        if (site.percentage > 5) {
          sitesNames.push(cleanName);
          sitesPercentages.push(site.percentage);
        } else {
          otherSitesNames.push(cleanName);
          othersPercentages.push(site.percentage);
        }
      }
    );
    if (otherSitesNames.length > 0) {
      sitesNames.push("Others");
    }
    sitesPercentages.push(sum(othersPercentages));
  })();
  return (
    <>
      <div className="ChartMainConatiner">
        <div className="ChartHeading">
          <h3>Total Duration</h3>
        </div>
        <div className="Chart-paragraph">
          <p className="mb-0">Visited sites percentage</p>
        </div>
        <div className="ChartVectorConatiner">
          <div className="visited-sites-pie-chart">
            <div>
              <ReactApexChart
                width={"330"}
                series={sitesPercentages}
                type="pie"
                options={{
                  colors: colorsArray,
                  legend: {
                    show: false,
                  },
                  labels: sitesNames,
                  responsive: [
                    {
                      options: {
                        chart: {
                          width: "100%",
                        },
                        legend: {
                          show: false,
                        },
                      },
                    },
                  ],
                  tooltip: {
                    custom: function ({ seriesIndex }) {
                      if (sitesNames[seriesIndex] === "Others") {
                        const HTML: any = `<div  class="custom-tooltip">
                        <div class="custom-tooltip-main-child">
                        <ul class="tooltip-class-ul">${otherSitesNames.map(
                          (name: string, index: number) => {
                            let cleanName =
                              name.length > 22
                                ? name.slice(0, 22) + "..."
                                : name;
                            switch (true) {
                              case index < 10:
                                return `<li class="tooltip-class-li" key={${index}}>
                                <div class="tooltip-class-tabDiv d-flex">
                                <div class="tooltip-class-tabName">•</div>
                                <div class="tooltip-class-tabName">${cleanName}</div>
                                <div>${roundNumber(
                                  othersPercentages[index],
                                  1
                                )}%</div>
                                </div></li>`;
                              case index === 10:
                                return `<li class="tooltip-class-li" key={${index}}>•  ...</li>`;
                            }
                            return "";
                          }
                        )}</ul>
                        </div>
                        <div>`;
                        return HTML.replaceAll(",", "");
                      } else {
                        let cleanName =
                          sitesNames[seriesIndex].length > 60
                            ? sitesNames[seriesIndex].slice(0, 60) + "..."
                            : sitesNames[seriesIndex];
                        return `<div class="custom-tooltip">
                        <div class="custom-tooltip-main-child">${cleanName}</div>
                        </div>`;
                      }
                    },
                  },
                }}
              />
            </div>
            <div className="legend-container">
              <ul className="legend-list">
                {sitesNames.map((siteName: string, index: number) => (
                  <li key={index} className="legend-item">
                    <span
                      className="legend-bullet"
                      style={{
                        backgroundColor: colorsArray[index],
                      }}
                    ></span>
                    <span className="legend-title">{siteName}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
