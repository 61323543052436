import { CloseButton, Modal } from "react-bootstrap";

const DeleteRolesModal = ({
  user,
  showDeleteRolesModal,
  handleCloseDeleteRolesModal,
  handleDelete,
}: any) => {
  const onDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleDelete();
  };
  return (
    <Modal
      className={`${
        showDeleteRolesModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleCloseDeleteRolesModal}
      show={showDeleteRolesModal}
      size="lg"
    >
      <form onSubmit={onDelete} className="modal-content">
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to delete all roles associated with{" "}
            {user.username}
          </Modal.Title>
          <CloseButton onClick={handleCloseDeleteRolesModal} />
        </Modal.Header>
        <Modal.Footer style={{ padding: "0.625rem 1.5rem" }}>
          <div className="AssignRoleModalFooter">
            <button
              onClick={handleCloseDeleteRolesModal}
              type="button"
              className="AssignRoleModalCanclebtn"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="submit" className="AssignRoleModalDeletebtn">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteRolesModal;
