import { DateTime } from "luxon";

import http from "../httpService";

const url = "UrlDashboard/MatchedLabelCounts";
http.setAuthorizationToken();

export function getMatchedLabelCounts(
  fromDate: DateTime,
  toDate: DateTime,
  computerId: number | undefined
) {
  return http.get(
    `${url}?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()} ${
      computerId !== undefined ? `&computerId=${computerId}` : ""
    }`
  );
}
