import http from "./httpService";
const url = "/Role";

export async function GetRoles() {
  try {
    const res = await http.get(`${url}/GetRoles`);
    return res.data.data;
  } catch (error) {
    console.error("Error fetching roles:", error);
    throw error;
  }
}

export async function GetUsersRoles() {
  try {
    const res = await http.get(`${url}/GetUsersRoles`);
    return res.data.data;
  } catch (error) {
    console.error("Error fetching users roles:", error);
    throw error;
  }
}

export async function GetUsersWithoutRoles() {
  try {
    const res = await http.get(`${url}/GetUsersWithoutRoles`);
    return res.data.data;
  } catch (error) {
    console.error("Error fetching users without roles:", error);
    throw error;
  }
}

export async function AssignRoles(user) {
  try {
    const body = {
      userId: user.id,
      roleNames: user.roles,
    };
    const res = await http.post(`${url}/AssignRoles`, body);
    return res.data.data;
  } catch (error) {
    console.error("Error fetching users without roles:", error);
    throw error;
  }
}
