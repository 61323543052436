import { Modal } from "react-bootstrap";
import { GetProcessesPerformanceDetailsProcess } from "../../client/AuthedClient";
import { Table } from "../Table";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";

export const ApplicationPerformanceFailuresModal = (
  props: {
    details: Array<GetProcessesPerformanceDetailsProcess>;
  },
  tabId?: string | undefined
) => {
  const { details } = props;

  const columns = [
    { name: "Device Name", attr: "computerName" },
    { name: "Timestamp", attr: "sampleTime" },
  ];

  const rowStrings = {
    key: "computerName",
    items: details.map((detail) => [detail.computerName]),
  };
  const rows = (array: Array<GetProcessesPerformanceDetailsProcess>) => {
    return array.map((detail, index) => (
      <tr key={index}>
        <td>{detail.computerName}</td>
        <td>{detail.sampleTime}</td>
      </tr>
    ));
  };

  return (
    <Modal.Body>
      {details.length > 0 ? (
        <Table
          pageTitle={"Application Performance Failures"}
          rows={rows}
          rowsData={details.map((el) => ({
            computerName: el.computerName,
            sampleTime: ConverToLocalAndFormat(
              el.sampleTime,
              "dd/MM/yyyy HH:mm:ss"
            ),
          }))}
          excelData={details.map((el) => ({
            computerName: el.computerName,
            sampleTime: ConverToLocalAndFormat(
              el.sampleTime,
              "dd/MM/yyyy HH:mm:ss"
            ),
          }))}
          columns={columns}
          rowsStrings={rowStrings}
          tabId={tabId}
        />
      ) : (
        <div className="flex-grow-1 text-center">
          <h4>No Failure Data</h4>
        </div>
      )}
    </Modal.Body>
  );
};
