import GuageChart from "react-gauge-chart";

export const ApplicationsPerfromanceGuageChart = (props: {
  percentage: number;
  showSidebar: boolean;
}) => {
  const { percentage, showSidebar } = props;
  return (
    <div
    // className="application-performance-chart-number"
    // style={{ textAlign: "center" }}
    >
      <GuageChart
        id="gauge-chart5"
        needleColor="#4453C5"
        cornerRadius={0}
        nrOfLevels={6}
        percent={percentage}
        arcPadding={0.02}
        arcWidth={0.4}
        hideText={true}
        style={{
          width:
            window.matchMedia("(min-width:1024px)").matches && !showSidebar
              ? "150px"
              : window.matchMedia("(max-width:520px)").matches
              ? "85px"
              : "100px",
          height: window.matchMedia("(max-width:520px)").matches
            ? "58px"
            : "50px",
          fontSize: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: !showSidebar ? "10px" : "",
        }}
        textColor={"black"}
        arcsLength={[0.3, 0.3, 0.4, 0.5, 0.6, 0.7]}
        colors={[
          "#FD4848",
          "#4453C5",
          "#FFD777",
          "#FFBA4A",
          "#FF8244",
          "#34B53A",
        ]}
      />
      {/* <h3 style={{ color: "rgb(79 84 88)" }}>Availability</h3> */}
    </div>
  );
};
