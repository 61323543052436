import { useState } from "react";
import AuthedClient from "../../client/AuthedClient";
import DomainModal from "../../components/DomainManagement/DomainModal";
import { DomainsTable } from "../../components/DomainManagement/DomainTable";
import { Page } from "../../components/Page";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { Filters } from "../../context/FiltersContext";
import {
  GetAllURLsWithPagination,
  UrlWithPAginationResponse,
} from "../../services/configMaliciuosUrl/URLsWithPagination";
import { addMaliciousUrl } from "../../services/configMaliciuosUrl/AddMaliciousUrl";

export const DomainManagement = () => {
  const authedClient = useAuthedClient();
  const [addDomainModalVisibility, setAddDomainModalVisibility] =
    useState(false);
  const [rowsSize, setRowsSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  function Body(data: UrlWithPAginationResponse) {
    return (
      <div className="white-box m-0">
        <div style={{ textAlign: "right", marginBottom: "5px" }}>
          <button
            onClick={() => setAddDomainModalVisibility(true)}
            className="btn btn-sm btn-success"
          >
            <i className="icon-plus-circle" /> Add New
          </button>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <DomainsTable
              tableData={data}
              paginationLogic={{ setRowsSize, setPageNumber }}
              searchLogic={{ searchValue, setSearchValue }}
              numberOfRowsLogic={{ rowsSize, setRowsSize }}
            />{" "}
          </div>
          <DomainModal
            title="Add Domain/URL"
            urlValue=""
            visibility={addDomainModalVisibility}
            setVisibility={setAddDomainModalVisibility}
            callBackFunction={addMaliciousUrl}
            data={null}
          />
        </div>
      </div>
    );
  }
  async function fetchData(
    //@ts-ignore
    authedClient: AuthedClient,
    //@ts-ignore
    filters: Filters
  ): Promise<UrlWithPAginationResponse> {
    return await GetAllURLsWithPagination(rowsSize, pageNumber, searchValue);
  }
  return (
    <Page
      fetchData={(filters: Filters) => fetchData(authedClient, filters)}
      shouldShowNoData={shouldShowNoData}
      body={Body}
      title={{
        mainTitle: "Security Monitoring configuration",
        subTitle: "Domain Management",
      }}
    />
  );
};

function shouldShowNoData(data: UrlWithPAginationResponse): boolean {
  return data.data.length === 0;
}
