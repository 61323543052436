import http from "./httpService";

const url = "/ProcessesPerformance/GetApplicationsUsageForDateRangePerApp";

// { headers: { Authorization: `Bearer ${userobj.token}` } }

function applicationUsageUrl(fromDate, toDate, applicationName) {
  return `${url}?from=${fromDate}&to=${toDate}&processName=${applicationName}`;
}

function getAuthorizationToken() {
  const user = localStorage.getItem("user");
  const userobj = user !== null && JSON.parse(user);
  return userobj.token;
}

export function getApplicationUsage(fromDate, toDate, applicationName) {
  return http.get(applicationUsageUrl(fromDate, toDate, applicationName), {
    headers: { Authorization: `Bearer ${getAuthorizationToken()}` },
  });
}
