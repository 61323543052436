import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAuthedClient } from "../context/AuthedClientContext";
import userImage from "../img/user-pic.jpg";
import { ChangePassword } from "../layout/ChangePassword";

type UserDetails = {
  id: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  fullName: string | null;
  title: string;
  phoneNumber: string | null;
};

export const Profile = () => {
  const [changePassword, setChangePassword] = useState(false);

  const authedClient = useAuthedClient();
  const [currentUserInfo, setCurrentUserInfo] = useState<
    UserDetails | undefined
  >({
    id: "",
    userName: "",
    normalizedUserName: "",
    email: "",
    fullName: "",
    title: "",
    phoneNumber: "",
  });

  async function getCurrentProfile() {
    await authedClient
      .getCurrentProfileSummary()
      .then((res) => setCurrentUserInfo(res.data));
  }

  useEffect(() => {
    getCurrentProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseChangePassword = () => setChangePassword(false);

  const handleCloseAllModals = () => {
    handleCloseChangePassword();
  };

  return (
    <div className="page-container">
      <div className="page-title d-lg-flex profile-header">
        <h1>{currentUserInfo?.userName}'s Information</h1>
        <button
          className="profile-button"
          onClick={() => setChangePassword(true)}
        >
          Change Password
        </button>
      </div>
      <div className="page-body">
        <div className="profile-container">
          <span className="container-title">Basic Info</span>
          <div className="profile-card">
            <label>Photo</label>
            <img src={userImage} alt="" />
          </div>
          <div className="profile-card">
            <label>Username </label>
            <p>{currentUserInfo?.userName}</p>
          </div>
          <div className="profile-card">
            <label>Fullname </label>
            <p>{`${currentUserInfo?.fullName}`}</p>
          </div>
          <div className="profile-card">
            <label>Title </label>
            <p>{currentUserInfo?.title}</p>
          </div>
        </div>

        <div className="profile-container">
          <span className="container-title">Contact Info</span>
          <div className="profile-card">
            <label>Phone number </label>
            <p>{`${currentUserInfo?.phoneNumber}`}</p>
          </div>
          <div className="profile-card">
            <label>Email </label>
            <p>{`${currentUserInfo?.email}`}</p>
          </div>
        </div>
      </div>
      {changePassword ? (
        <Modal
          className={`${
            changePassword ? "popUpModal details-modal-opened" : "popUpModal"
          }`}
          keyboard={false}
          centered={true}
          scrollable={true}
          onHide={() => handleCloseAllModals()}
          show={changePassword}
          size="lg"
        >
          <ChangePassword
            handleCloseAllModals={handleCloseAllModals}
            handleCloseChangePassword={handleCloseChangePassword}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};
