import { useEffect, useState } from "react";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { AllowedApps } from "../../client/AuthedClient";
import { Loader } from "../../components/Loader/Loader";
import { AllowedApplicationsTable } from "../../components/AllowedApplications";

type State =
  | {
      kind: "loading";
    }
  | {
      kind: "loaded";
      processes: Array<AllowedApps>;
    }
  | { kind: "errored" };

export const AllowedApplications = () => {
  const authedClient = useAuthedClient();

  const [state, setState] = useState<State>({ kind: "loading" });

  function handleResponseReceived(response: Array<AllowedApps>) {
    setState({ kind: "loaded", processes: response });
  }

  function handleErrored() {
    setState({ kind: "errored" });
  }

  function callGetAllowedApps() {
    authedClient
      .getAllowedApps()
      .then((res) => {
        handleResponseReceived(res);
      })
      .catch(() => handleErrored());
  }

  function callAllowDisAllow(id: number) {
    authedClient.allowDisallowApp(id).then(() => {
      callGetAllowedApps();
    });
  }

  useEffect(() => {
    callGetAllowedApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.kind === "loading") {
    return (
      <div className="page-container">
        {/* <div className="page-title">
          <h2 className="me-auto">Configuration</h2>
          <h3 className="me-auto">
            <span>Configuration /</span> Allowed Applications
          </h3>
        </div> */}
        <Loader />
      </div>
    );
  } else if (state.kind === "loaded") {
    return (
      <div className="page-container">
        {/* <div className="page-title">
          <h2 className="me-auto">Configuration</h2>
          <h3 className="me-auto">
            <span>Configuration /</span> Allowed Applications
          </h3>
        </div> */}
        <AllowedApplicationsTable
          callAllowDisAllow={callAllowDisAllow}
          processes={state.processes}
        />
      </div>
    );
  } else {
    return <div>Something wrong happened. Please, contact the developers.</div>;
  }
};
