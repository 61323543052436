/* eslint-disable array-callback-return */
import { ChangeEvent, MutableRefObject, useRef, useState } from "react";
import { CloseButton, Dropdown, Modal } from "react-bootstrap";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
export const AllowedApplicationsModal = (props: {
  showAddModal: boolean;
  handleHideAddModal: () => void;
  disAllowedApps: Array<any>;
  callAllowDisAllow: (id: number) => void;
  haslicense: (id: number) => boolean;
}) => {
  const {
    showAddModal,
    handleHideAddModal,
    disAllowedApps,
    callAllowDisAllow,
    haslicense,
  } = props;
  const [appName, setAppName] = useState("Choose Application");
  const [appId, setAppId] = useState<any | number>(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [appVersion, setAppVersion] = useState("Choose Version");
  const [Versions, setVersions] = useState([]);
  const [picked, setPicked] = useState<any>({
    applicationNameState: true,
    applicationVersionState: true,
  });
  const [disabled, setDisabled] = useState(true);
  const searchInputEl = useRef<HTMLInputElement | null>(null);
  const handleSubmit = () => {
    if (appName !== "Choose Application" && appVersion !== "Choose Version") {
      setPicked({
        applicationNameState: true,
        applicationVersionState: true,
      });
      if (haslicense(appId)) {
        ToastFunction(
          `${appName} cant be disallowed since it has a license , delete the license first`
        );
      } else {
        if (appVersion === "All Versions") {
          Versions.map((el: any) => {
            callAllowDisAllow(el.id);
            ToastSuccessFunction(
              `Success Adding ${appName} ${el.applicationVersion}`
            );
            return 0;
          });
        } else {
          callAllowDisAllow(appId);
          ToastSuccessFunction(`Success Adding ${appName}`);
        }
      }
      handleHideAddModal();
    } else {
      if (appName !== "Choose Application") {
        setPicked({
          ...picked,
          applicationVersionState: false,
        });
      } else if (appVersion !== "Choose Version") {
        setPicked({
          ...picked,
          applicationNameState: false,
        });
      } else {
        setPicked({
          applicationNameState: false,
          applicationVersionState: false,
        });
      }
    }
  };
  function focus(
    nextShow: boolean,
    el: MutableRefObject<HTMLInputElement | null>
  ) {
    if (nextShow) {
      setTimeout(() => el.current?.focus());
    }
  }
  const arrOfNames: any = [];
  disAllowedApps.map((el: any) => {
    arrOfNames.push(el.applicationName);
    return 0;
  });
  const set = new Set(arrOfNames);
  const uniquearr = Array.from(set);
  const filteredApplications = uniquearr
    .filter((filterdElement: any) => {
      if (
        filterdElement.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return filterdElement;
      } else {
        return 0;
      }
    })
    .map((el: any, index: number) => (
      <Dropdown.Item
        href="#"
        key={index}
        eventKey={el}
        className={`${
          el === appName
            ? "page-header-dropdown page-header-dropdown-light-text-active"
            : "page-header-dropdown"
        }`}
      >
        {el}
        {/* {filteredApplications?.length === 0 ? "noData" : el} */}
      </Dropdown.Item>
    ));
  function filterVersion(appname: string) {
    let temparr: any = [];
    temparr = disAllowedApps.filter((el) => {
      return el.applicationName === appname;
    });
    setVersions(temparr);
  }
  const versionsOptions =
    // Versions.filter(
    //   (el: any) => el.applicationVersion !== null
    // )
    Versions.filter((el: any) => {
      if (el.applicationVersion !== null) {
        if (el.applicationVersion.includes(searchTerm.toLocaleLowerCase())) {
          return el;
        } else {
          return 0;
        }
      }
    }).map((el: any, index) => {
      return (
        <Dropdown.Item
          href="#"
          key={index}
          eventKey={el.applicationVersion}
          onClick={() => {
            setAppId(el.id);
          }}
          className={`${
            el.applicationVersion === appVersion
              ? "page-header-dropdown page-header-dropdown-light-text-active"
              : "page-header-dropdown"
          }`}
        >
          {el.applicationVersion}
        </Dropdown.Item>
      );
    });
  return (
    <Modal
      className={`${
        showAddModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleHideAddModal}
      show={showAddModal}
      size="lg"
    >
      <form onSubmit={(e) => e.preventDefault()} className="modal-content">
        <Modal.Header>
          <Modal.Title>Add Application</Modal.Title>
          <CloseButton onClick={handleHideAddModal} />
        </Modal.Header>
        <Modal.Body className="overflow-visible width-max-content">
          <div className="row g-3">
            <div className="col-lg-6">
              <label className="form-label">
                Application name <span className="error-span">*</span>
              </label>
              <Dropdown
                onSelect={(eventKey: any) => {
                  setPicked({
                    ...picked,
                    applicationNameState: true,
                  });
                  setAppName(eventKey);
                  filterVersion(eventKey);
                  setAppVersion("Choose Version");
                  setDisabled(false);
                }}
                onToggle={(nextShow: any) => {
                  focus(nextShow, searchInputEl);
                  if (searchTerm.length !== 0 && searchTerm !== "") {
                    setSearchTerm("");
                  }
                }}
              >
                <Dropdown.Toggle variant="light">{appName}</Dropdown.Toggle>
                <Dropdown.Menu className="user-filter-dropdown-menu">
                  <div>
                    <input
                      ref={searchInputEl}
                      type="search"
                      placeholder="Search"
                      className="form-control navbar-drop-down-search"
                      value={searchTerm}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearchTerm(e.target.value)
                      }
                    />
                  </div>
                  {/* <Dropdown.Item
                    eventKey="Choose Application"
                    className={`${
                      appName === "Choose Application"
                        ? "page-header-dropdown page-header-dropdown-light-text-active"
                        : "page-header-dropdown"
                    }`}
                  >
                  </Dropdown.Item> */}
                  {filteredApplications.length === 0 ? (
                    <span className="Span-No-Data">No Data Found </span>
                  ) : (
                    filteredApplications
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {!picked.applicationNameState && (
                <span className="error-span">This is Required Field</span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                Application Version <span className="error-span">*</span>
              </label>
              <Dropdown
                onSelect={(eventKey: any) => {
                  setPicked({
                    ...picked,
                    applicationVersionState: true,
                  });
                  setAppVersion(eventKey);
                }}
                onToggle={(nextShow: any) => {
                  focus(nextShow, searchInputEl);
                  if (searchTerm.length !== 0 && searchTerm !== "") {
                    setSearchTerm("");
                  }
                }}
              >
                <Dropdown.Toggle variant="light" disabled={disabled}>
                  {appVersion}
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-filter-dropdown-menu">
                  <div>
                    <input
                      ref={searchInputEl}
                      type="search"
                      placeholder="Search"
                      value={searchTerm}
                      className="form-control navbar-drop-down-search"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearchTerm(e.target.value)
                      }
                    />
                  </div>
                  {appName !== "Choose Application" && (
                    <Dropdown.Item
                      eventKey="All Versions"
                      className={`${
                        appVersion === "All Versions"
                          ? "page-header-dropdown page-header-dropdown-light-text-active"
                          : "page-header-dropdown"
                      }`}
                    >
                      All Versions
                    </Dropdown.Item>
                  )}
                  {versionsOptions.length === 0 ? (
                    <span className="Span-No-Data">No Data Found</span>
                  ) : (
                    versionsOptions
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {!picked.applicationVersionState && (
                <span className="error-span">This is Required Field</span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            onClick={handleHideAddModal}
            type="button"
            className="btn btn-secondary"
          >
            Cancel
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
