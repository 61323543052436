import AuthedClient, {
  GetApplicationsUsageResponse,
} from "../client/AuthedClient";
import { ApplicationsUsageTable } from "../components/ApplicationsUsage";
import { ApplicationUsagePieChart } from "../components/ApplicationsUsage/ApplicationUsagePieChart";
import { Page } from "../components/Page";
import { useAuthedClient } from "../context/AuthedClientContext";
import { Filters } from "../context/FiltersContext";
import "../css/Application-UsageStyles.css";
import "../css/ChartContainerStyles.css";
// import { ApplicationUsageTopApp } from "../components/ApplicationsUsage/ApplicationUsageTopAppBox";
export const ApplicationsUsage = () => {
  const authedClient = useAuthedClient();
  return (
    <Page
      fetchData={(filters) => fetchData(authedClient, filters)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Applications Monitoring",
        subTitle: "Applications Usage",
      }}
    />
  );
};
async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<GetApplicationsUsageResponse> {
  const { startDate, endDate, group, user } = filters;
  return await authedClient.getApplicationsUsage(
    startDate,
    endDate,
    group,
    user
  );
}
function shouldShowNoData(data: GetApplicationsUsageResponse): boolean {
  return data.length === 0;
}
function body(data: GetApplicationsUsageResponse) {
  return (
    <div className="white-box m-0">
      <div className="row">
        <div className="application-usage-container">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <ApplicationUsagePieChart
                  data={data}
                  title={"Applications Summary"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="application-usage-container">
            <ApplicationsUsageTable data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
