import styled from "styled-components";
import { Duplicate } from "@styled-icons/boxicons-regular/Duplicate";

// Make the styling dynamic by accessing props
const Duplicateicon = styled(Duplicate)<{
  iconsize?: string;
  defaultcolor?: string;
  hovercolor?: string;
  focuscolor?: string;
}>`
  width: ${({ iconsize }) => iconsize || "24px"};
  height: ${({ iconsize }) => iconsize || "24px"};
  color: ${({ defaultcolor }) => defaultcolor || "#6a6a6a"};
  cursor: pointer;

  &:hover {
    color: ${({ hovercolor }) => hovercolor || "#36429e"};
  }

  &:focus,
  &:active {
    color: ${({ focuscolor }) => focuscolor || "#4453c5"};
  }
`;

function DuplicateIcon(props: {
  handlDuplicate?: () => void;
  iconsize?: string;
  defaultcolor?: string;
  hovercolor?: string;
  focuscolor?: string;
}) {
  return (
    <Duplicateicon
      onClick={props.handlDuplicate}
      iconsize={props.iconsize}
      defaultcolor={props.defaultcolor}
      hovercolor={props.hovercolor}
      focuscolor={props.focuscolor}
    />
  );
}

export default DuplicateIcon;
