import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import styled from "styled-components";

// Make the styling dynamic by accessing props
const Deleteicon = styled(Delete)<{
  iconsize?: string;
  defaultcolor?: string;
  hovercolor?: string;
  focuscolor?: string;
}>`
 width: ${({ iconsize }) => iconsize || "24px"};
  height: ${({ iconsize }) => iconsize || "24px"};
  color: ${({ defaultcolor }) => defaultcolor || "#6a6a6a"};
  cursor: pointer;

  &:hover {
    color: ${({ hovercolor }) => hovercolor || "#36429e"};
  }

  &:focus,
  &:active {
    color: ${({ focuscolor }) => focuscolor || "#4453c5"};
  }
`;

function DeleteIcon(props: {
  handleDelete?: () => void;
  iconsize?: string;
  defaultcolor?: string;
  hovercolor?: string;
  focuscolor?: string;
}) {
  return (
    <Deleteicon
      onClick={props.handleDelete}
      iconsize={props.iconsize}
      defaultcolor={props.defaultcolor}
      hovercolor={props.hovercolor}
      focuscolor={props.focuscolor}
    />
  );
}

export default DeleteIcon;
