import { TimeUtilizationResponseProvider } from "./TimeUtilizationResponseProvider";
import "../../css/timeUtilizationStyles.css";
import { Page } from "../Page";
import ChartsContainer from "./MainComponentsContainer/ChartsContainer";
import { useAuthedClient } from "../../context/AuthedClientContext";
import AuthedClient, {
  GetVisitedSitesResponse,
} from "../../client/AuthedClient";
import { Filters } from "../../context/FiltersContext";
import { handleAccordionData } from "./TimeUtilizationHelpers";
export function TimeUtilization() {
  const authedClient = useAuthedClient();

  return (
    <TimeUtilizationResponseProvider>
      <Page<GetVisitedSitesResponse>
        fetchData={(filters) => fetchData(authedClient, filters)}
        shouldShowNoData={shouldShowNoData}
        body={body}
        title={{
          mainTitle: "Dashboard",
          subTitle: "Visited Sites",
        }}
      />
    </TimeUtilizationResponseProvider>
  );
}

async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<any> {
  const { startDate, endDate, user } = filters;

  return await authedClient.getTimeUtilData2(user ?? 0, startDate, endDate);
}

function shouldShowNoData(data: any): boolean {
  const noData: boolean = handleAccordionData(data).length === 0
  return noData
}
function body(data: any) {
  return <ChartsContainer data={data} />;
}
