import http from "./httpService";

const url = "/ApplicationsCompliance/GetDevicesComplianceInstances";
http.setAuthorizationToken();

function getDevicesComplianceInstancesUrl(
  applicationId,
  isAllowed,
  currentGroup,
  currentUser
) {
  if (currentGroup !== 0 && currentUser === undefined) {
    return `${url}/${applicationId}?isAllowed=${isAllowed}&groupId=${currentGroup}`;
  }
  if (currentGroup === 0 && currentUser !== undefined) {
    return `${url}/${applicationId}?isAllowed=${isAllowed}&computerId=${currentUser}`;
  }
  if (currentGroup !== 0 && currentUser !== undefined) {
    return `${url}/${applicationId}?isAllowed=${isAllowed}&groupId=${currentGroup}&computerId=${currentUser}`;
  }

  return `${url}/${applicationId}?isAllowed=${isAllowed}`;
}

export function getDevicesComplianceInstances(
  applicationId,
  isAllowed,
  currentGroup,
  currentUser
) {
  return http.get(
    getDevicesComplianceInstancesUrl(
      applicationId,
      isAllowed,
      currentGroup,
      currentUser
    )
  );
}
