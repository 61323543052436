import { Modal } from "react-bootstrap";
import {
  GetModalDevicesTabDetailsProcess,
  GetProcessesPerformanceDetailsProcess,
} from "../../client/AuthedClient";
import { Table } from "../Table";

export const ApplicationPerformanceDevicesModal = (
  props: {
    details: Array<GetProcessesPerformanceDetailsProcess>;
    devicesDetails: Array<GetModalDevicesTabDetailsProcess>;
  },
  tabId?: string | undefined
) => {
  const { details: failedDevices, devicesDetails } = props;
  const columns = [
    { name: "Device Name", attr: "computerName" },
    { name: "Failure Count", attr: "failureCount" },
  ];

  const rowStrings = {
    key: "computerName",
    items: devicesDetails.map((detail) => [detail.computerName]),
  };
  const rows = (array: Array<any>) => {
    return array.map((detail, index) => (
      <tr key={index}>
        <td>{detail.computerName}</td>
        <td>{detail.failureCount}</td>
      </tr>
    ));
  };
  return (
    <Modal.Body>
      {devicesDetails.length > 0 ? (
        <Table
          pageTitle={"Applications Performance Devices"}
          rows={rows}
          rowsData={devicesDetails.map((el) => ({
            computerName: el.computerName,
            failureCount: failedDevices.filter(
              (dev) => dev.computerName === el.computerName
            ).length,
          }))}
          columns={columns}
          rowsStrings={rowStrings}
          excelData={devicesDetails.map((el) => ({
            computerName: el.computerName,
            failureCount: failedDevices.filter(
              (dev) => dev.computerName === el.computerName
            ).length,
          }))}
          tabId={tabId}
        />
      ) : (
        <div className="flex-grow-1 text-center">
          <h4>No Devices Data</h4>
        </div>
      )}
    </Modal.Body>
  );
};
