import http from "../httpService";

const url = "/UrlLookup/UpdateURL";
http.setAuthorizationToken();

type Props = {
  id: number;
  url: string;
  labelId: number | null;
};
export function updateMaliciousUrl(data: Props) {
  return http.put(url, data);
}
