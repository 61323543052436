import { useEffect, useState } from "react";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
import { NoData } from "../NoData";
import { Table } from "../Table";
import { AllowedApplicationsModal } from "./AllowedApplicationsModal";
import { DeleteIcon } from "../Icons";

export const AllowedApplicationsTable = (props: {
  processes: Array<any>;
  callAllowDisAllow: (id: number) => void;
}) => {
  const { processes, callAllowDisAllow } = props;
  const authedClient = useAuthedClient();

  const allowedApps = processes.filter((filter) => {
    if (filter.isAllowed) {
      return filter;
    } else {
      return null;
    }
  });

  const disAllowedApps = processes.filter((filter) => {
    if (!filter.isAllowed) {
      return filter;
    } else {
      return null;
    }
  });

  const [appslicense, setAppsLicense] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => setShowAddModal(false);
  const handleAddNew = () => {
    handleShowAddModal();
  };

  useEffect(() => {
    authedClient.getApplicationLicense().then((res: any) => {
      setAppsLicense(res);
    });
  }, [authedClient]);

  function haslicense(id: number) {
    let haslicense = false;

    appslicense.map((el: any) => {
      if (el.applicationId === id) {
        haslicense = true;
      }
      return 0;
    });

    return haslicense;
  }

  const columns = [
    { name: "Application Name", attr: "applicationName" },
    {
      name: "Application Version",
      attr: "applicationVersion",
      style: window.innerWidth > 750 ? { width: "90px" } : {},
    },
    {
      name: "Delete",
      attr: "isAllowed",
      style: window.innerWidth > 750 ? { width: "40px" } : { width: "100px" },
    },
  ];


  const rows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr key={index}>
        <td>{process.applicationName}</td>
        <td>
          {process.applicationVersion === null
            ? "-"
            : process.applicationVersion}
        </td>
        <td>
          <DeleteIcon
            handleDelete={() => {
              if (haslicense(process.recordId)) {
                ToastFunction(
                  `${process.applicationName} cant be Deleted since it has a license , delete the license first`
                );
              } else {
                callAllowDisAllow(process.recordId);
                ToastSuccessFunction(
                  `Success in Deleting the application ${process.applicationName}`
                );
              }
            }}
          />
        </td>
      </tr>
    ));
  };

  const rowStrings = {
    key: "applicationName",
    items: allowedApps.map((process) => [process.applicationName]),
  };
  const rowsData: any = [];
  const excelData: any = [];

  allowedApps.map((el) => {
    const excelObj = {
      applicationName: el.applicationName,
      applicationVersion:
        el.applicationVersion === null ? "-" : el.applicationVersion,
      // numberOfComputers: el.numberOfComputers,
    };

    const obj = {
      recordId: el.id,
      applicationName: el.applicationName,
      applicationVersion:
        el.applicationVersion === null ? "-" : el.applicationVersion,
      numberOfComputers: el.numberOfComputers,
      isAllowed: el.isAllowed,
    };

    rowsData.push(obj);
    excelData.push(excelObj);
    return 0;
  });

  return (
    <div className="white-box m-0">
      <div style={{ textAlign: "right", marginBottom: "5px" }}>
        <div onClick={handleAddNew} className="btn btn-sm btn-success">
          <i className="icon-plus-circle" /> Add New
        </div>
      </div>
      {allowedApps.length <= 0 ? (
        <NoData />
      ) : (
        <Table
          excelData={excelData}
          pageTitle={"Allowed Applications"}
          rows={rows}
          rowsData={rowsData}
          columns={columns}
          rowsStrings={rowStrings}
        />
      )}
      {showAddModal ? (
        <AllowedApplicationsModal
          showAddModal={showAddModal}
          handleHideAddModal={handleHideAddModal}
          disAllowedApps={disAllowedApps}
          callAllowDisAllow={callAllowDisAllow}
          haslicense={haslicense}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
