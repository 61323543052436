/* eslint-disable array-callback-return */
import ReactApexChart from "react-apexcharts";
import "../../css/ToolTip&LegendStyles.css";
import colorArray from "../common/Colors";
export const ApplicationUsagePieChart = (props: any) => {
  const { data, title } = props;
  let totalAvgUsage: any = 0;
  let yAxisData: number[] = [];
  let labels: string[] = [];
  let otherFound = false;
  let otherTotalAvgUsage: any = 0;
  let otherString = "others";
  
  (function () {
    for (var i = 0; i < data.length; i++) {
      totalAvgUsage += data[i].durationInSeconds;
    }
    data?.forEach((item: any) => {
      const itemYAxis = (item.durationInSeconds / totalAvgUsage) * 100;
      if (itemYAxis < 5) {
        otherTotalAvgUsage += itemYAxis;
      } else {
        yAxisData.push(itemYAxis);
        labels.push(item.processName);
      }
    });
    if (!otherFound) {
      labels.push(otherString);
      otherFound = true;
    }
    yAxisData.push(otherTotalAvgUsage);
  })();
  function renderAppName(name: string) {
    if (name.length > 40) {
      const index = name.indexOf(":");
      if (index > 0) return name.slice(0, index);
      else return name;
    } else return name;
  }
  return (
    <div className="ChartMainConatiner">
      <div className="titlesContainer ChartHeading">
        <h3>{title}</h3>
      </div>
      <div className="Chart-paragraph">
        <p className="mb-0">Application Usage Percentage</p>
      </div>
      <div className="ChartVectorConatiner">
        <div className="application-usage-pie-chart-Container">
          <div>
            <ReactApexChart
              width={"330"}
              series={yAxisData}
              type="pie"
              options={{
                colors: colorArray,
                legend: {
                  show: false,
                  position: window.innerWidth <= 768 ? "top" : "right",
                  offsetY: window.innerWidth <= 768 ? 0 : 40,
                  itemMargin: {
                    horizontal: 0,
                    vertical: 5,
                  },
                },
                tooltip: {
                  custom: function ({ series, seriesIndex }) {
                    if (labels[seriesIndex] === "others") {
                      const HTML: any = `<div class="custom-tooltip">
                    <div class="custom-tooltip-main-child ">
                    <ul class="tooltip-class-ul">${data
                      .filter((app: any) => {
                        const itemYAxis =
                          (app.durationInSeconds / totalAvgUsage) * 100;
                        if (itemYAxis < 5) {
                          return app;
                        }
                      })
                      .map((app: any, index: number) => {
                        const item =
                          (app.durationInSeconds / totalAvgUsage) * 100;
                        switch (true) {
                          case index < 10:
                            const truncateName =
                              app.processName.length > 25
                                ? app.processName.slice(0, 25) + "..."
                                : app.processName;
                            return `<li class="tooltip-class-li" key={${index}}>•
                          ${renderAppName(truncateName)} ${
                              app.durationInSeconds === 0 ? 0 : item.toFixed(1)
                            }%</li>`;
                          case index === 11:
                            return `<li class="tooltip-class-li" key={${index}}>•  ...</li>`;
                        }
                      })}
                    </ul>
                    </div>
                    </div>`;
                      return HTML.replaceAll(",", "");
                    } else {
                      return `<div class="custom-tooltip">
                    <div class="custom-tooltip-main-child">
                    ${labels[seriesIndex]} ${series[seriesIndex].toFixed(1)}%
                    </div>
                    </div>`;
                    }
                  },
                },
              }}
            />
          </div>
          <div className="legend-container">
            <ul className="legend-list">
              {labels.map((siteName: string, index: number) => (
                <li key={index} className="legend-item  ">
                  <span
                    className="legend-bullet"
                    style={{
                      backgroundColor: colorArray[index],
                    }}
                  ></span>
                  <span className="legend-title">{siteName}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
