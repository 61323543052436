import { EditIcon, DuplicateIcon, DeleteIcon } from "../../Icons";
import { useRemoteActions } from "../../RemoteActions/RemoteActionsContext";
import { Action } from "../../../services/RemoteActionsService";

import styles from "./MoreContent.module.css";
import { useCallback, useEffect } from "react";

const MoreContent = (props: {
  action: Action;
  isShowHandler: (key: number) => void;
  key: number;
  index: number;
}) => {
  const { handleDeleteAction, handleCloneAction, handleEditAction } =
    useRemoteActions();
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const targetElement = e.target as HTMLElement;

      if (targetElement) {
        if (targetElement.tagName !== "LI" && targetElement.tagName !== "svg") {
          props.isShowHandler(props.index);
        } else if (targetElement.tagName === "LI") {
          setTimeout(() => props.isShowHandler(props.index), 200);
        }
      }
    },
    [props]
  );
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <ul className={styles["more-content"] + " slideIn show"}>
        <li onClick={() => handleEditAction(props.action)}>
          <span>
            <EditIcon
              handleEdit={() => console.log("Edit clicked")}
              iconsize="18px"
              defaultcolor="#6c757d"
              hovercolor="#6c757d"
              focuscolor="#6c757d"
            />
          </span>
          Edit
        </li>
        <li onClick={() => handleCloneAction(props.action)}>
          <span>
            <DuplicateIcon
              handlDuplicate={() => console.log("Edit clicked")}
              iconsize="18px"
              defaultcolor="#6c757d"
              hovercolor="#6c757d"
              focuscolor="#6c757d"
            />
          </span>
          Clone
        </li>
        <li onClick={() => handleDeleteAction(props.action)}>
          <span>
            <DeleteIcon
              handleDelete={() => console.log("Edit clicked")}
              iconsize="18px"
              defaultcolor="#6c757d"
              hovercolor="#6c757d"
              focuscolor="#6c757d"
            />
          </span>
          Delete
        </li>
      </ul>
    </>
  );
};

export default MoreContent;
