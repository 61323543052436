import { Tooltip } from "@mui/material";
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { Modal, CloseButton, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { ExclamationCircleFill } from "@styled-icons/bootstrap/ExclamationCircleFill";
import { AlertErrorType, AlertType } from "../../services/AlertService";
const ExclamationIcon = styled(ExclamationCircleFill)``;
interface ConfigureAlertsModalProps {
  alert: any;
  errors: AlertErrorType;
  setErrors: any;
  handleChange: (e: any) => void;
  editMode: boolean;
  applications: string[];
  criterias: string[];
  showAlertModal: boolean;
  onAddAlertClicked: () => void;
  handleCloseAlertModal: (modifiedAlert: AlertType | null) => void;
}

const ConfigureAlertsModal = ({
  alert,
  errors,
  setErrors,
  handleChange,
  editMode,
  applications,
  criterias,
  showAlertModal,
  onAddAlertClicked,
  handleCloseAlertModal,
}: ConfigureAlertsModalProps) => {
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [recievedAlet, setRecievedAlert] = useState<null | AlertType>(null)
  const searchInputEl = useRef<HTMLInputElement | null>(null);
  const emailRegex =
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

  const tooltipContent =
    "Characters, symbols, negative numbers, zero are not allowed";

  const filteredApplications = applications
    .filter(
      (app: string, index: number, self: any) =>
        index === self.findIndex((obj: any) => obj === app)
    )
    .filter((app: string) =>
      app.toLocaleLowerCase().includes(nameSearchTerm.toLocaleLowerCase())
    )
    .map((applicaton: string, index: number) => (
      <Dropdown.Item
        href="#"
        key={index}
        eventKey={applicaton}
        className={`${applicaton === alert["applicationName"]
          ? "page-header-dropdown page-header-dropdown-light-text-active"
          : "page-header-dropdown"
          }`}
      >
        {applicaton}
      </Dropdown.Item>
    ));


  useEffect(() => {
    if (editMode) setRecievedAlert({ ...alert })
  }, [editMode,alert])

  function focus(
    nextShow: boolean,
    el: MutableRefObject<HTMLInputElement | null>
  ) {
    if (nextShow) {
      setTimeout(() => el.current?.focus());
    }
  }

  const validateEmails = (emails: string) => {
    const emailArray = emails.split(",").map((email) => email.trim());
    for (let email of emailArray) {
      if (!emailRegex.test(email)) {
        return false;
      }
    }
    return true;
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleChange(e);

    if (validateEmails(value)) {
      setErrors((prevErrors: any) => ({ ...prevErrors, emailRecipients: "" }));
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        emailRecipients:
          value === ""
            ? "Please fill out this field"
            : "Invalid email address format",
      }));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!errors.emailRecipients) {
      onAddAlertClicked();
    }
  };

  function acceptanceCriteria(): boolean {
    return (
      !alert.criteria ||
      !alert.applicationName ||
      alert.emailRecipients.split(",").length === 0 ||
      !!errors.emailRecipients
    );
  }

  return (
    <Modal
      className={`${showAlertModal ? "popUpModal details-modal-opened" : "popUpModal"
        }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={() => handleCloseAlertModal(recievedAlet)}
      show={showAlertModal}
      size="lg"
    >
      <form onSubmit={handleSubmit} className="modal-content">
        <Modal.Header>
          <Modal.Title>{editMode ? "Edit Alert" : "Add New Alert"}</Modal.Title>
          <CloseButton onClick={() => handleCloseAlertModal(recievedAlet)} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-4" style={{ flexDirection: "column" }}>
            <div>
              <label className="AssignRoleModalLable">
                Alerting Criteria<span className="error-span">*</span>
              </label>
              <Dropdown
                style={{
                  width: "100%",
                  pointerEvents: editMode ? "none" : "auto",
                }}
                onSelect={(eventKey: any) => {
                  handleChange({
                    currentTarget: {
                      name: "criteria",
                      value: eventKey,
                    },
                  });
                }}
              >
                <Dropdown.Toggle
                  variant="light"
                  className="fullWidth configureAlertModalDropdown"
                >
                  {alert.criteria || "Choose Criteria"}{" "}
                  {/* Show selected value or placeholder */}
                </Dropdown.Toggle>

                <Dropdown.Menu className="user-filter-dropdown-menu fullWidth">
                  {criterias.length === 0 ? (
                    <span className="Span-No-Data">No Data Found</span>
                  ) : (
                    criterias.map((criteria, index) => (
                      <Dropdown.Item
                        href="#"
                        key={index}
                        eventKey={criteria}
                        className={`${criteria === alert["criteria"]
                          ? "page-header-dropdown page-header-dropdown-light-text-active"
                          : "page-header-dropdown"
                          }`}
                      >
                        {criteria}
                      </Dropdown.Item>
                    ))
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <label className="AssignRoleModalLable">
                Application Name <span className="error-span">*</span>
              </label>
              <Dropdown
                style={{ width: "100%" }}
                onSelect={(eventKey: any) => {
                  handleChange({
                    currentTarget: {
                      name: "applicationName",
                      value: eventKey,
                    },
                  });
                }}
                onToggle={(nextShow: any) => {
                  focus(nextShow, searchInputEl);
                  setNameSearchTerm("");
                }}
              >
                <Dropdown.Toggle
                  variant="light"
                  className="fullWidth configureAlertModalDropdown"
                >
                  {alert.applicationName
                    ? alert.applicationName
                    : "Choose Application"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-filter-dropdown-menu fullWidth">
                  <div>
                    <input
                      ref={searchInputEl}
                      type="search"
                      placeholder="Search"
                      value={nameSearchTerm}
                      className="form-control navbar-drop-down-search"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNameSearchTerm(e.target.value)
                      }
                    />
                  </div>
                  {filteredApplications.length === 0 ? (
                    <span className="Span-No-Data">No Data Found</span>
                  ) : (
                    filteredApplications
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <div className="ConfigureAlertsModalLableContainer">
                <label className="ConfigureAlertsModalLable">
                  Threshold<span className="error-span">*</span>
                </label>
                <Tooltip title={tooltipContent} placement="top" arrow>
                  <ExclamationIcon className="ExclamationIcon" />
                </Tooltip>
              </div>
              <input
                type="number"
                min="1"
                name="failureThreshold"
                value={
                  alert.failureThreshold !== 0 ? alert.failureThreshold : ""
                }
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) handleChange(e);
                }}
                required
                className="AlertModalNumber"
              />
              <div className="Note">
                The number of failures that the alert will be trigger after that
                number
              </div>
            </div>
            <div>
              <div className="ConfigureAlertsModalLableContainer">
                <label className="ConfigureAlertsModalLable">
                  Monitoring period (hours)<span className="error-span">*</span>
                </label>
                <Tooltip title={tooltipContent} placement="top" arrow>
                  <ExclamationIcon className="ExclamationIcon" />
                </Tooltip>
              </div>
              <input
                type="number"
                min="1"
                name="monitoringPeriodInHours"
                value={
                  alert.monitoringPeriodInHours !== 0
                    ? alert.monitoringPeriodInHours
                    : ""
                }
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) handleChange(e);
                }}
                required
                className="AlertModalNumber"
              />
              <div className="Note">
                The period which the service will trigger the alert
              </div>
            </div>
            <div>
              <label className="AssignRoleModalLable">
                Recipients Emails<span className="error-span">*</span>
              </label>
              <input
                type="text"
                name="emailRecipients"
                value={alert.emailRecipients}
                onChange={handleEmailChange}
                required
                className="AlertModalNumber"
                style={{ height: "64px" }}
              />
              <div className="Note">Separate each email by comma</div>
              {errors.emailRecipients && (
                <div className="text-danger">{errors.emailRecipients}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "0.625rem 1.5rem" }}>
          <div className="AlertModalFotterContainer">
            <div>
              {errors["applicationName"] && (
                <div className="text-danger">{errors["applicationName"]}</div>
              )}
            </div>
            <div className="AlertModalFotterbtns">
              <button
                onClick={() => handleCloseAlertModal(recievedAlet)}
                type="button"
                className="AssignRoleModalCanclebtn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="AssignRoleModalSubmitbtn"
                disabled={acceptanceCriteria()}
              >
                {editMode ? "Edit" : "Add"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ConfigureAlertsModal;
