import { useEffect, useState } from "react";
import AssignRoleTable from "./AssignRoleTable";
import AssignRoleModal from "./AssignRoleModal";
import DeleteRolesModal from "./DeleteRolesModal";
import { AssignRoles, GetRoles, GetUsersRoles } from "../../services/Roles";
import { NoData } from "../NoData";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
import "./AssignRole.css";

const AssignRoleBody = ({ data }: any) => {
  type UserType = {
    id: string;
    username: string;
    roles: string[];
  };

  const InitialUser: UserType = {
    id: "",
    username: "",
    roles: [],
  };

  const [users, setUsers] = useState(data);
  const [user, setUser] = useState<UserType>(InitialUser);
  const [allRoles, setAllRoles] = useState([]);
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false);
  const [showDeleteRolesModal, setShowDeleteRolesModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const updateView = async () => {
    try {
      const data = await GetUsersRoles();
      setUsers(data);
    } catch (error) {
      ToastFunction("Failed to update the view");
    }
  };

  const onSubmetForm = async () => {
    try {
      if (user.id && user.roles.length > 0) {
        await AssignRoles(user);
        handleCloseAssignRoleModal();
        ToastSuccessFunction("Roles assigned to user successfully");
      } else {
        ToastFunction("User and role selection are required.");
      }
    } catch (error) {
      ToastFunction("Failed to assign role");
    }
  };

  const onEditUserRoles = (user: UserType) => {
    setUser(user);
    setEditMode(true);
    setShowAssignRoleModal(true);
  };

  const handleCloseAssignRoleModal = () => {
    setUser(InitialUser);
    setShowAssignRoleModal(false);
    setEditMode(false);
    updateView();
  };

  const onDeleteUserRoles = (user: UserType) => {
    setUser(user);
    setShowDeleteRolesModal(true);
  };

  const handleDelete = async () => {
    try {
      await AssignRoles({ id: user.id, roles: [] });
      handleCloseDeleteRolesModal();
      ToastSuccessFunction("User roles has been removed successfully");
    } catch (error) {
      ToastFunction("Failed to delete roles");
    }
  };

  const handleCloseDeleteRolesModal = () => {
    setUser(InitialUser);
    setShowDeleteRolesModal(false);
    updateView();
  };

  useEffect(() => {
    const CallGetRoles = async () => {
      try {
        const roles = await GetRoles();
        setAllRoles(roles);
      } catch (error) {
        ToastFunction("Failed to fetch roles");
      }
    };
    CallGetRoles();
  }, [users]);

  return (
    <>
      <div style={{ textAlign: "right", marginBottom: "5px" }}>
        <div
          className="btn btn-sm btn-success"
          onClick={() => setShowAssignRoleModal(true)}
        >
          <i className="icon-plus-circle" /> Assign Role
        </div>
      </div>
      {users.length > 0 ? (
        <>
          <AssignRoleTable
            users={users}
            onEditUserRoles={onEditUserRoles}
            onDeleteUserRoles={onDeleteUserRoles}
          />
          <DeleteRolesModal
            user={user}
            showDeleteRolesModal={showDeleteRolesModal}
            handleCloseDeleteRolesModal={handleCloseDeleteRolesModal}
            handleDelete={handleDelete}
          />
        </>
      ) : (
        <NoData />
      )}
      {showAssignRoleModal && (
        <AssignRoleModal
          user={user}
          setUser={setUser}
          editMode={editMode}
          allRoles={allRoles}
          showAssignRoleModal={showAssignRoleModal}
          onSubmetForm={onSubmetForm}
          handleCloseAssignRoleModal={handleCloseAssignRoleModal}
        />
      )}
    </>
  );
};

export default AssignRoleBody;
