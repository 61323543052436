import  {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
} from "react";
const SideBarContextContainer = createContext<any>(undefined);
export const useSideBarContext = () => useContext(SideBarContextContainer);
function SideBarContext(props: PropsWithChildren<{}>) {
  const [isClose, setIsClose] = useState(false);
  const { children } = props;
  return (
    <SideBarContextContainer.Provider value={{ setIsClose, isClose }}>
      {children}
    </SideBarContextContainer.Provider>
  );
}

export default SideBarContext;
