import ReactApexChart from "react-apexcharts";
import { GetItPerformanceResponse } from "../../client/AuthedClient";
import { roundNumber } from "../../utils/Mathimatics";

export const itSummaryCard = (
  data: GetItPerformanceResponse,
  averageFieldName: "averageCPU" | "averageDiskInMb" | "averageMemoryInMb",
  yAxisTitle: string
) => {
  // const colors = ["#67b7dc", "#6794dc", "#6771dc", "#8067dc", "#c767dc"];
  const truncate = (str: string, limit: number) => {
    return str?.length >= limit ? str.slice(0, limit) + "..." : str;
  };

  const yData = data
    .sort((a, b) => {
      let fa = a[averageFieldName],
        fb = b[averageFieldName];

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    })
    .slice(0, 5)
    .map((process) => {
      return process[averageFieldName];
    });

  const xData = data
    .sort((a, b) => {
      let fa = a[averageFieldName],
        fb = b[averageFieldName];

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    })
    .slice(0, 5)
    .map((process) => truncate(process.processName, 17));

  return (
    <div>
      <h3>Top Applications</h3>
      <p className="it-performance-chart-y-axis-title">{yAxisTitle}</p>
      <div>
        <ReactApexChart
          options={{
            chart: {
              toolbar: {
                show: false,
              },
              type: "bar",
              height: 350,
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                horizontal: false,
                columnWidth: "20%",
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: xData,
            },
            yaxis: {
              tickAmount: 5,
              labels: {
                formatter: (value: any) => {
                  if (value === 0) return value;
                  else if (value >= 1) return value.toFixed(0);
                  else return roundNumber(value, 6);
                },
              },
            },
            tooltip: {
              y: {
                formatter: (value: any) => {
                  let formatedNumber = value;
                  if (value === 0) formatedNumber = value;
                  else if (value >= 1) formatedNumber = value.toFixed(0);
                  else formatedNumber = roundNumber(value, 6);
                  if (averageFieldName === "averageCPU") formatedNumber += "%";
                  return formatedNumber;
                },
              },
            },
            colors: ["#4049b5"],
          }}
          series={[
            {
              name: "",
              data: yData,
            },
          ]}
          type="bar"
          height={380}
        />
      </div>
    </div>
  );
};
