import { Table, width35pxStyle, width80pxStyle } from "../Table";
import { AlertType } from "../../services/AlertService";
import { EditIcon, DeleteIcon } from "../Icons";

const ConfigureAlertsTable = ({
  alerts,
  onSwitchClicked,
  onEditAlert,
  onDeleteAlert,
}: any) => {
  const columns = [
    {
      name: "Application Name",
      attr: "applicationName",
      style: width80pxStyle,
    },
    {
      name: "Alerting Criteria",
      attr: "criteria",
      style: width80pxStyle,
    },
    { name: "Threshold", attr: "failureThreshold", style: width35pxStyle },
    {
      name: "Monitoring Period (Hours)",
      attr: "monitoringPeriodInHours",
      style: width35pxStyle,
    },
    {
      name: "No of Recipients",
      attr: "emailRecipients",
      style: width35pxStyle,
    },
    { name: "Active", attr: "", style: width35pxStyle },
    { name: "Edit", attr: "", style: width35pxStyle },
    { name: "Delete", attr: "", style: width35pxStyle },
  ];

  const rows = (array: any) => {
    return array.map((alert: AlertType, i: number) => (
      <tr key={i}>
        <td>{alert.applicationName}</td>
        <td>Failure</td>
        <td>{alert.failureThreshold}</td>
        <td>{alert.monitoringPeriodInHours}</td>
        <td>{alert.emailRecipients.split(",").length}</td>
        <td>
          <label className="AlertTableSwitch">
            <input type="checkbox" defaultChecked={alert.isConfActive} />
            <span
              className="AlertTableSlider round"
              onClick={() => onSwitchClicked(alert)}
            ></span>
          </label>
        </td>
        <td>
          <EditIcon
            handleEdit={() => {
              onEditAlert(alert);
            }}
          />
        </td>
        <td>
          <DeleteIcon
            handleDelete={() => {
              onDeleteAlert(alert);
            }}
          />
        </td>
      </tr>
    ));
  };
  const excelData: any = [];
  const rowsData: any = [];

  alerts?.forEach((alert: AlertType) => {
    let rowObj = {
      id: alert.id,
      applicationName: alert.applicationName,
      failureThreshold: alert.failureThreshold,
      monitoringPeriodInHours: alert.monitoringPeriodInHours,
      emailRecipients: alert.emailRecipients,
      isConfActive: alert.isConfActive,
    };
    let excelObj = {
      applicationName: alert.applicationName,
      criteria: "Failure",
      failureThreshold: alert.failureThreshold,
      monitoringPeriodInHours: alert.monitoringPeriodInHours,
      emailRecipients: alert.emailRecipients.split(",").length,
    };
    rowsData.push(rowObj);
    excelData.push(excelObj);
  });

  const rowStrings = {
    key: "applicationName",
    items: alerts.map((alert: any) => [alert.applicationName]),
  };

  return (
    <Table
      pageTitle="Configure Alerts"
      rows={rows}
      rowsData={rowsData}
      columns={columns}
      rowsStrings={rowStrings}
      excelData={excelData}
    />
  );
};

export default ConfigureAlertsTable;
