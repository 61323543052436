import { roundNumber } from "../../utils/Mathimatics";
import { Table } from "../Table";
import { Modal, CloseButton } from "react-bootstrap";

export const ApplicationsUsageModal = (props: {
  showModal: any;
  setShowModal: any;
  appName: string;
  details: any;
}) => {
  const { showModal, setShowModal, appName, details } = props;

  const rowStrings = {
    key: "computerName",
    items: details.map((applicationUsage: any) => [
      applicationUsage.computerName,
    ]),
  };
  const getTotalDurationInSeconds = () => {
    let count = 0;
    details.forEach((appUser: any) => {
      count = count + appUser.durationInSeconds;
    });
    return count;
  };

  const colWidth =
    window.innerWidth <= 1024 ? { width: "150px" } : { width: "50px" };

  const tableColumns = [
    { name: "Device Name", attr: "computerName" },
    { name: "Device Usage %", attr: "percentageCalculation", style: colWidth },
  ];

  const rows = (array: Array<any>) => {
    return array.map((application, index) => (
      <tr key={index}>
        <td>{application.computerName}</td>
        <td>{application.percentageCalculation}%</td>
      </tr>
    ));
  };

  const rowsArray = details.map((app: any) => {
    let usage = (app.durationInSeconds / getTotalDurationInSeconds()) * 100;
    usage =
      usage === 100
        ? usage
        : usage < 0.005
        ? roundNumber(usage, 3)
        : roundNumber(usage, 2);

    return {
      computerName: app.computerName,
      percentageCalculation: usage,
    };
  });

  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>{appName}</Modal.Title>
        <CloseButton onClick={() => setShowModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Table
          pageTitle={"Application Usage Modal"}
          rows={rows}
          rowsData={rowsArray}
          excelData={rowsArray}
          columns={tableColumns}
          rowsStrings={rowStrings}
        />
      </Modal.Body>
    </Modal>
  );
};
