import { CloseButton, Modal } from "react-bootstrap";
import { LineChart } from "../Charts";
import { TrendDateFilter } from "./TrendDateFilter";

export const TrendModal = (props: {
  data?: any;
  title: string;
  fixedamount?: number;
  getTrendLineDetails?: any;
  showTrendModal: boolean;
  handleCloseTrendModal: () => void;
  currentAppName: string;
  date: any;
  setDate: any;
  toDate: any;
  setToDate: any;
}) => {
  const {
    data,
    title,
    showTrendModal,
    handleCloseTrendModal,
    currentAppName,
    getTrendLineDetails,
    fixedamount,
    date,
    setDate,
    toDate,
    setToDate,
  } = props;

  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      show={showTrendModal}
      onHide={handleCloseTrendModal}
      size="lg"
      className="web-availability-modal"
    >
      <Modal.Header>
        <Modal.Title>{currentAppName}</Modal.Title>
        <CloseButton onClick={handleCloseTrendModal} />
      </Modal.Header>
      <Modal.Body>
        <TrendDateFilter
          currentAppName={currentAppName}
          getTrendLineDetails={getTrendLineDetails}
          date={date}
          setDate={setDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        <div
          className="m-2"
          style={{
            color: "green",
          }}
        >
          *Avarage value for the last 30 Days
        </div>
        <LineChart
          // max={100}
          data={data}
          title={currentAppName}
          xAxisTitle={`Time in ${data.labelFormat}s`}
          yAxisTitle={title}
          yAxisTickAmount={5}
          fixed={fixedamount}
        />
      </Modal.Body>
    </Modal>
  );
};
