import { CloseButton, Modal } from "react-bootstrap";
import { NoData } from "./../NoData";
import { Table } from "../Table";
import { DeleteIcon, EditIcon } from "../Icons";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";
export const ApplicationLicenseDetailsModal = (props: {
  applicationDetails: any;
  showDetailsModal: boolean;
  setShowDetailsModal: any;
  handleEdit: (process: any) => void;
  handleDelete: (
    recordId: number,
    applicationName: string,
    applicationVersion: string
  ) => void;
}) => {
  const {
    applicationDetails,
    showDetailsModal,
    setShowDetailsModal,
    handleEdit,
    handleDelete,
  } = props;
  const columns = [
    {
      name: "Number Of Licenses",
      attr: "numberOfLicenses",
      style: { width: "170px" },
    },
    {
      name: "Application Version",
      attr: "applicationVersion",
      style: { width: "165px" },
    },
    {
      name: "Status",
      attr: "status",
      style: { width: "85px" },
    },
    {
      name: "license Start Date",
      attr: "licenseStartDate",
      style: { width: "155px" },
    },
    {
      name: "license Expiration Date",
      attr: "licenseExpirationDate",
      style: { width: "190px" },
    },
    { name: "Edit", attr: "", style: { width: "60px" } },
    { name: "Delete", attr: "", style: { width: "75px" } },
  ];
  const rows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr key={index}>
        <td>{process.numberOfLicenses}</td>
        <td>{process.applicationVersion ?? "-"}</td>
        <td>{process.status}</td>
        <td>
          {ConverToLocalAndFormat(process.licenseStartDate, "dd/MM/yyyy")}
        </td>
        <td>
          {process.licenseExpirationDate
            ? ConverToLocalAndFormat(
                process.licenseExpirationDate,
                "dd/MM/yyyy"
              )
            : "-"}
        </td>
        <td>
          <EditIcon handleEdit={() => handleEdit(process)} />
        </td>
        <td>
          <DeleteIcon
            handleDelete={() =>
              handleDelete(
                process.recordId,
                applicationDetails.applicationName,
                applicationDetails.applicationVersion
              )
            }
          />
        </td>
      </tr>
    ));
  };
  const rowStrings = {
    key: "status",
    items: applicationDetails.details.map((process: any) => [process.status]),
  };

  const rowsData: any = [];
  const excelData: any = [];
  applicationDetails.details.forEach((el: any) => {
    // const licenseStartDate = moment(el.licenseStartDate);
    //const licenseStartDateFormat = licenseStartDate.format("D/M/YYYY");
    // const licenseExpiredDate = moment(el.licenseExpirationDate);
    // const licenseExpiredDateFormat = licenseExpiredDate.format("D/M/YYYY");
    let licenseStartDate, licenseExpirationDate;
    if (el.licenseStartDate) {
      const licenseStartDateArr = el.licenseStartDate?.split("T")[0].split("-");
      licenseStartDate = `${licenseStartDateArr[2]}/${licenseStartDateArr[1]}/${licenseStartDateArr[0]}`;
    }
    if (el.licenseExpirationDate) {
      const licenseExpirationDateArr = el.licenseExpirationDate
        ?.split("T")[0]
        .split("-");
      licenseExpirationDate = `${licenseExpirationDateArr[2]}/${licenseExpirationDateArr[1]}/${licenseExpirationDateArr[0]}`;
    }
    let excelObj = {
      numberOfLicenses: el.numberOfLicenses ?? "-",
      applicationVersion: el.applicationVersion ?? "-",
      status: el.status ?? "-",
      licenseStartDate: licenseStartDate ?? "-",
      licenseExpirationDate: licenseExpirationDate ?? "-",
    };
    let obj = {
      recordId: el.recordId,
      status: el.status,
      numberOfLicenses: el.numberOfLicenses,
      applicationVersion: el.applicationVersion,
      licenseStartDate: el.licenseStartDate,
      licenseExpirationDate: el.licenseExpirationDate,
    };
    excelData.push(excelObj);
    rowsData.push(obj);
  });
  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={() => setShowDetailsModal(false)}
      show={showDetailsModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>{applicationDetails.applicationName} </Modal.Title>
        <CloseButton onClick={() => setShowDetailsModal(false)} />
      </Modal.Header>
      <Modal.Body className="overflow-visible width-max-content app-license-drop-down">
        <div className="m-0">
          {applicationDetails.details.length === 0 ? (
            <NoData />
          ) : (
            <Table
              excelData={excelData}
              pageTitle={"Application License Detail"}
              rows={rows}
              rowsData={rowsData}
              columns={columns}
              rowsStrings={rowStrings}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
