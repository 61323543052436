function AgentAvilabilityChartContainerRightBox(props: any) {
  const { totalDevices, activeDevices, inActiveDevices } = props;
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="d-flex justify-content-between align-items-center flex-column agentAvilableeDataContainer">
        <div className="d-flex justify-content-center align-items-center flex-column flex-md-row">
          <div className="AgentAvailbilityDeviceContainer col-sm-12 col-md-4 d-flex justify-content-center align-items-center flex-column text-center">
            <div>
              <h3>{totalDevices}</h3>
              <p>Total devices</p>
            </div>
          </div>
          <div className="AgentAvailbilityDeviceContainer col-sm-12 col-md-4 d-flex justify-content-center align-items-center flex-column text-center">
            <div>
              <h3>{activeDevices}</h3>
              <p>Monitored Devices</p>
            </div>
          </div>
          <div className="AgentAvailbilityDeviceContainer col-sm-12 col-md-4 d-flex justify-content-center align-items-center flex-column text-center">
            <div>
              <h3>{inActiveDevices}</h3>
              <p>Non-Monitored Devices</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentAvilabilityChartContainerRightBox;
