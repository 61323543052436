import styled from "styled-components";
import { ClipboardSettings } from "@styled-icons/fluentui-system-filled/ClipboardSettings";
import { Users } from "@styled-icons/fa-solid/Users";
import ApplicationMonitorIcon from "../img/sideBarIcons/ApplicationMonitorIcon";
import DeviceMonitorIcon from "../img/sideBarIcons/DeviceMonitorIcon";
import WebMonitorIcon from "../img/sideBarIcons/WebMonitorIcon";
import EmployeesMonitorIcon from "../img/sideBarIcons/EmployeesMonitorIcon";
import ShieldCheckmarkIcon from "../components/Icons/SecurityIcon";

const ClipboardSettingsIcon = styled(ClipboardSettings)`
  width: 21px;
  height: 27px;
  color: #fff;
`;
const UsersIcon = styled(Users)`
  width: 21px;
  height: 27px;
  color: #fff;
`;
export const sidebarMenu = [
  {
    title: "Applications Monitoring",
    allowedPermissions: ["ViewMonitoringDashboards"],
    iconClassName: "icon-gauge-1",
    icon: <ApplicationMonitorIcon />,
    groupIndex: 0,
    links: [
      {
        groupIndex: 0,
        RouteName: "Application Performance",
        route: "/landing",
      },
      {
        groupIndex: 0,
        RouteName: "Application Compliance",
        route: "/app-compliance/applicationsCompliance",
        childRoutes: ["/app-compliance/devicesCompliance"],
      },
      {
        groupIndex: 0,
        RouteName: "License Monitoring",
        route: "/License-Monitoring",
      },
      {
        groupIndex: 0,
        RouteName: "Applications Usage",
        route: "/Applications-Usage",
      },
      {
        groupIndex: 0,
        RouteName: "Usage Report",
        route: "/Applications-Usage-Summary",
      },
    ],
  },
  {
    title: "Web Monitoring",
    allowedPermissions: ["ViewMonitoringDashboards"],

    iconClassName: "icon-gauge-1",
    icon: <WebMonitorIcon />,
    groupIndex: 2,
    links: [
      {
        groupIndex: 2,
        RouteName: "Web Availability",
        route: "/Web-Availability",
      },
      {
        groupIndex: 2,
        RouteName: "Visited Sites",
        route: "/Visited-Sites",
      },
    ],
  },
  {
    title: "Devices Monitoring",
    allowedPermissions: ["ViewMonitoringDashboards"],

    iconClassName: "icon-gauge-1",
    icon: <DeviceMonitorIcon />,
    groupIndex: 4,
    links: [
      {
        groupIndex: 4,
        RouteName: "IT Performance",
        route: "/it-performance/cpuPerformance",
        childRoutes: [
          "/it-performance/memoryPerformance",
          "/it-performance/diskPerformance",
        ],
      },
      {
        groupIndex: 4,
        RouteName: "Devices Compliance",
        route: "/Agent-Availability",
      },
      {
        groupIndex: 4,
        RouteName: "Devices List",
        route: "/devices-list",
        childRoutes: ["/device-view/Device-Information"],
      },
    ],
  },
  {
    title: "Security And threats Monitoring",
    allowedPermissions: ["ViewMonitoringDashboards"],
    iconClassName: "icon-users-outline",
    icon: <ShieldCheckmarkIcon />,
    groupIndex: 8,
    singleLink: false,
    links: [
      {
        groupIndex: 8,
        RouteName: "Malicious Activities",
        route: "/Malicious-Activities",
      },
    ],
  },
  {
    title: "Employees Productivity",
    allowedPermissions: ["ViewEmployeeProductivity"],
    iconClassName: "icon-gauge-1",
    icon: <EmployeesMonitorIcon />,
    groupIndex: 5,
    links: [
      {
        groupIndex: 5,
        RouteName: "Time Utilization",
        route: "/timeUtilization",
      },
    ],
  },
  {
    title: "Configuration",
    allowedPermissions: ["Configuration"],
    iconClassName: "icon-params",
    icon: <ClipboardSettingsIcon />,
    groupIndex: 1,
    links: [
      {
        groupIndex: 1,
        RouteName: "Application License",
        route: "/Application-License",
      },
      {
        groupIndex: 1,
        RouteName: "Allowed Applications",
        route: "/Allowed-Applications",
      },
      {
        groupIndex: 1,
        RouteName: "Installed Applications",
        route: "/Installed-Applications",
      },
      {
        groupIndex: 1,
        RouteName: "Web Applications",
        route: "/Web-Applications",
      },
      {
        groupIndex: 1,
        RouteName: "Domain Management",
        route: "/Domain-Management",
      },
      {
        groupIndex: 1,
        RouteName: "Alerts",
        route: "/Configure-Alerts",
      },
      {
        groupIndex: 1,
        RouteName: "Remote Actions",
        route: "/Remote-Actions",
      },
    ],
  },

  {
    title: "Users",
    allowedPermissions: ["AdminManagement"],
    iconClassName: "icon-users-outline",
    icon: <UsersIcon />,
    groupIndex: 3,
    links: [
      {
        groupIndex: 3,
        RouteName: "User Management",
        route: "/user-management",
      },
      {
        groupIndex: 3,
        RouteName: "Assign Role",
        route: "/Assign-Role",
      },
    ],
  },
];
