import http from "./httpService";
const url = "/FailureAlertConfiguration";

export type AlertType = {
  id: number;
  criteria: string;
  applicationName: string;
  failureThreshold: number;
  monitoringPeriodInHours: number;
  emailRecipients: string;
  percentageOfAffectedUsers: number;
  isConfActive: boolean;
};

export type AlertErrorType = {
  criteria?: string;
  applicationName?: string;
  failureThreshold?: number;
  monitoringPeriodInHours?: number;
  emailRecipients?: string;
  percentageOfAffectedUsers?: number;
  isConfActive?: boolean;
};

export async function GetAlerts() {
  return await http.get(`${url}/GetAllConfigurations`);
}

export async function GetAlert(id: number): Promise<AlertType> {
  return await http.get(`${url}/GetConfigurationById/${id}`);
}

export async function DeleteAlert(id: number) {
  return await http.delete(`${url}/DeleteConfiguration/${id}`);
}

export async function ActivateAlert(id: number) {
  return await http.put(`${url}/ActivateConf/${id}`);
}

export async function DeactivateAlert(id: number) {
  return await http.put(`${url}/DeactivateConf/${id}`);
}

export async function GetCriterias(): Promise<string[]> {
  return ["Failure"];
}

export async function GetApplications() {
  return await http.get(`${url}/GetDistinctProcessNames`);
}

export async function UpdateAlert(alert: AlertType) {
  return await http.put(`${url}/UpdateConfiguration`, alert);
}

export async function AddAlert(alert: AlertType): Promise<AlertType> {
  try {
    const newAlert: any = { ...alert };
    delete newAlert.id;
    const { data: res } = await http.post(
      `${url}/InsertConfiguration`,
      newAlert
    );
    newAlert.id = res.data;
    return newAlert;
  } catch (error) {
    console.error("Error: ", error);
    throw error;
  }
}
