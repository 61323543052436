import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { roundNumber } from "../../utils/Mathimatics";
export const WebResponseLineChart = (props: {
  data: any;
  title: string;
  yAxisTitle: string | undefined;
  xAxisTitle: string | undefined;
  avragevalues: any;
  yAxisTickAmount: number;
}) => {
  const { data, title, yAxisTitle, xAxisTitle, avragevalues } = props;
  const xAxisData: Array<number> = [];
  const yAxisData: Array<any> = [];
  data.data.map((el: any) => {
    xAxisData.push(el.xValue);
    yAxisData.push(el.yValue);
    return 0;
  });
  const firstItem = yAxisData[0];
  const newYDataArray = [...yAxisData.slice(1), firstItem];
  let maxFirstLine = Math.max(...yAxisData);
  let avragevaluesConverted = avragevalues.map((el: string) => {
    return Number(el);
  });
  useEffect(() => {}, [data, avragevaluesConverted]);
  return (
    <div className="col-xl-12">
      <div className=" chart-box">
        <ReactApexChart
          series={[
            {
              name: title,
              data: newYDataArray,
            },
            {
              name: "average Value",
              data: avragevaluesConverted,
              color: "#00FF00",
            },
          ]}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
                autoScaleYaxis: false,
              },
              type: "line",
            },
            title: {
              text: title,
              align: "center",
              margin: 10,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
            tooltip: {
              theme: "dark",
              x: {
                show: true,
                formatter: function (val, _): string {
                  switch (val) {
                    case 1:
                      return "1 am  - 2 am";
                    case 2:
                      return "2 am  - 3 am";
                    case 3:
                      return "3 am  - 4 am";
                    case 4:
                      return "4 am  - 5 am";
                    case 5:
                      return "5 am  - 6 am";
                    case 6:
                      return "6 am  - 7 am";
                    case 7:
                      return "7 am  - 8 am";
                    case 8:
                      return "8 am  - 9 am";
                    case 9:
                      return "9 am  - 10 am";
                    case 10:
                      return "10 am  - 11 am";
                    case 11:
                      return "11 am  - 12 pm";
                    case 12:
                      return "12 pm  - 1 pm";
                    case 13:
                      return "1 pm  - 2 pm";
                    case 14:
                      return "2pm  - 3 pm";
                    case 15:
                      return "3 pm  - 4 pm";
                    case 16:
                      return "4 pm  - 5 pm";
                    case 17:
                      return "5 pm  - 6 pm";
                    case 18:
                      return "6 pm  - 7 pm";
                    case 19:
                      return "7 pm  - 8 pm";
                    case 20:
                      return "8 pm  - 9 pm";
                    case 21:
                      return "9 pm  - 10 pm";
                    case 22:
                      return "10 pm   - 11 pm";
                    case 23:
                      return "11 pm  - 12 am";
                    case 24:
                      return "12 am - 1 am";
                    default:
                      return "";
                  }
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 2,
            },
            xaxis: {
              categories: xAxisData,
              tooltip: {
                enabled: false,
              },
              labels: {
                formatter: (value: any) => {
                  if (value % 1 === 0) {
                    return value;
                  } else {
                    return String(Number(value).toFixed(2));
                  }
                },
                style: {
                  colors: "#000",
                  fontSize: "12px",
                },
              },
              title: {
                text: xAxisTitle,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-xaxis-title",
                },
              },
            },
            yaxis: {
              tickAmount: 6,
              min: 0,
              max:
                avragevaluesConverted[0] >= maxFirstLine
                  ? avragevaluesConverted[0] * 2
                  : maxFirstLine,
              title: {
                text: yAxisTitle,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-xaxis-title",
                },
              },
              labels: {
                formatter: (value: any) => {
                  const roundedVal = roundNumber(value / 1000, 2);
                  if (value % 1 === 0) {
                    return value;
                  } else {
                    return roundedVal;
                  }
                },
                style: {
                  colors: "#000",
                  fontSize: "12px",
                },
              },
            },
            legend: {
              show: false,
            },
            grid: {
              strokeDashArray: 5,
              yaxis: {
                lines: {
                  show: true,
                },
              },
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            markers: {
              size: 2,
              colors: ["#4049b5", "#00FF00"],
              strokeColors: ["#4049b5"],
              strokeWidth: 0.1,
              strokeOpacity: 0.9,
              strokeDashArray: 0,
              fillOpacity: 1,
              discrete: [],
              shape: "circle",
              // radius: 2,
              offsetX: 0,
              offsetY: 0,
              showNullDataPoints: true,
            },
            fill: {
              type: "solid",
              colors: ["#fff", "#fff", "#fff"],
            },
            colors: ["#4049b5"],
          }}
          height={250}
        />
      </div>
    </div>
  );
};
