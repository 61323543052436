import { useRemoteActions } from "./RemoteActionsContext";
import ReusableModal from "../common/ReusableModal";

const FormModal = () => {
  const {
    action,
    categories,
    mode,
    showFormModal,
    handleCloseFormModal,
    handleChange,
    handleSubmitAction,
    isActionExist,
  } = useRemoteActions();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmitAction();
  };

  const acceptanceCriteria = (): boolean => {
    return (
      !(action.commandCategoryId >= 0) ||
      action.actionName === "" ||
      action.command === "" ||
      mode === "Edit" // To be removed
    );
  };

  const bodyContent = (
    <div className="d-flex gap-4" style={{ flexDirection: "column" }}>
      <div>
        <label className="AssignRoleModalLable">
          Category<span className="error-span">*</span>
        </label>
        <select
          disabled={mode === "Edit"} // To be removed
          name="commandCategoryId"
          aria-controls="dtBasicExample"
          className="AssignRoleModalSelect"
          style={{ appearance: "auto", cursor: "pointer" }}
          onChange={handleChange}
          value={action.commandCategoryId >= 0 ? action.commandCategoryId : ""}
        >
          <option className="text-secondary" disabled value="">
            Choose Category
          </option>
          {categories.map((category, i: number) => (
            <option key={i} value={category.id} style={{ cursor: "pointer" }}>
              {category.category}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className="ConfigureAlertsModalLable">
          Action Name<span className="error-span">*</span>
        </label>
        <input
          disabled={mode === "Edit"} // To be removed
          type="text"
          name="actionName"
          value={action.actionName}
          onChange={handleChange}
          required
          maxLength={60}
          className="ActionFormModaltext"
        />
      </div>
      <div>
        <label className="ConfigureAlertsModalLable">
          Description<span className="error-span">*</span>
        </label>
        <input
          disabled={mode === "Edit"} // To be removed
          type="text"
          name="description"
          value={action.description}
          onChange={handleChange}
          maxLength={2000}
          className="ActionFormModaltext"
        />
      </div>
      <div>
        <label className="ConfigureAlertsModalLable">
          Command<span className="error-span">*</span>
        </label>
        <textarea
          disabled={mode === "Edit"} // To be removed
          name="command"
          value={action.command}
          onChange={handleChange}
          required
          rows={10}
          className="ActionFormModaltextArea"
        ></textarea>
      </div>
    </div>
  );

  const footerContent = (
    <div className={`footer ${isActionExist && "justify-content-between"} `}>
      {isActionExist && (
        <span>*This name is already used.</span>
      )}

      <div className="ActionModalFooterBtns">
        <button
          onClick={handleCloseFormModal}
          type="button"
          className="AssignRoleModalCanclebtn"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="AssignRoleModalSubmitbtn"
          disabled={acceptanceCriteria() === true}
        >
          {mode === "Edit" ? "Edit" : mode === "Clone" ? "Save" : "Add"}
        </button>
      </div>
    </div>
  );

  return (
    <ReusableModal
      show={showFormModal}
      title={mode === "Edit" ? "Edit Action" : "Add New Remote Action"}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onClose={handleCloseFormModal}
      onSubmit={handleSubmit}
      modalClassName="popUpModal"
      size="lg"
    />
  );
};

export default FormModal;
