import { DeleteIcon, EditIcon } from "../Icons";
import { Table, width35pxStyle, width80pxStyle } from "../Table";

const AssignRoleTable = ({
  users,
  onEditUserRoles,
  onDeleteUserRoles,
}: any) => {
  const columns = [
    { name: "Username", attr: "username", style: width80pxStyle },
    { name: "Roles", attr: "roles", style: width80pxStyle },
    { name: "Edit", style: width35pxStyle, attr: "" },
    { name: "Delete", style: width35pxStyle, attr: "" },
  ];

  const rows = (array: any) => {
    return array.map((user: any, index: number) => (
      <tr key={index}>
        <td>{user.username}</td>
        <td>
          <div>
            {user.roles.length > 0
              ? user.roles.map((r: string, i: number) => (
                  <div key={i}>
                    {r} <br />
                  </div>
                ))
              : ""}
          </div>
        </td>
        <td>
          <EditIcon
            handleEdit={() => {
              onEditUserRoles(user);
            }}
          />
        </td>
        <td>
          <DeleteIcon
            handleDelete={() => {
              onDeleteUserRoles(user);
            }}
          />
        </td>
      </tr>
    ));
  };
  const excelData: any = [];
  const rowsData: any = [];
  let rowStrings;
  if (users) {
    users.forEach((user: any) => {
      let excelObj = {
        username: user.username,
        roles: user.roles,
      };
      let obj = {
        id: user.id,
        username: user.username,
        roles: user.roles,
      };
      rowsData.push(obj);
      excelData.push(excelObj);
    });
    rowStrings = {
      key: "username",
      items: users.map((user: any) => [user.username]),
    };
  }

  return (
    <>
      <Table
        pageTitle="Assign Role"
        rows={rows}
        rowsData={rowsData}
        columns={columns}
        rowsStrings={rowStrings}
        excelData={excelData}
      />
    </>
  );
};

export default AssignRoleTable;
