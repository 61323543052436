import http from "../httpService";

const url = "/UrlLookup/GetAllURLsWithPagination";
http.setAuthorizationToken();

export function GetAllURLsWithPagination(
  pageSize: number,
  pageNumber: number,
  searchUrl: string | undefined
): Promise<UrlWithPAginationResponse> {
  if (searchUrl)
    return http.get(
      `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchUrl=${searchUrl}`
    );

  return http.get(`${url}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
}

export type UrlWithPAginationResponse = {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  data: {
    id: number;
    url: string;
    labelId: number;
  }[];
};
