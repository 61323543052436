import { Modal, CloseButton } from "react-bootstrap";

interface ReusableModalProps {
  show: boolean;
  title: string;
  bodyContent: React.ReactNode;
  footerContent: React.ReactNode;
  onClose: () => void;
  onSubmit: (e: React.FormEvent) => void;
  modalClassName?: string;
  size?: "sm" | "lg" | "xl";
}

const ReusableModal = ({
  show,
  title,
  bodyContent,
  footerContent,
  onClose,
  onSubmit,
  modalClassName = "",
  size,
}: ReusableModalProps) => {
  return (
    <Modal
      className={`${modalClassName} ${show ? "details-modal-opened" : ""}`}
      centered={true}
      onHide={onClose}
      show={show}
      size={size}
    >
      <form onSubmit={onSubmit} className="modal-content">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <CloseButton onClick={onClose} />
        </Modal.Header>
        <Modal.Body>{bodyContent}</Modal.Body>
        <Modal.Footer>{footerContent}</Modal.Footer>
      </form>
    </Modal>
  );
};

export default ReusableModal;
