import { useEffect, useState } from "react";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
import { NoData } from "../NoData";
import { Table, width80pxStyle } from "../Table";
import { DeleteIcon, DetailsIcon } from "../Icons";
import '../../css/InstalledApplication.css'
export const InstalledApplicationsTable = (props: {
  handleDelete: (recordId: number, applicationName: string) => void;
  processes: any;
  callGetAppUsers: (id: number, name: string) => void;
  callAllowDisAllow: (id: number) => void;
  currentApps: any;
}) => {
  const {
    handleDelete,
    processes,
    callGetAppUsers,
    callAllowDisAllow,
    currentApps,
  } = props;

  const [appslicense, setAppsLicense] = useState([]);

  const authedClient = useAuthedClient();

  useEffect(() => {
    authedClient.getApplicationLicense().then((res: any) => {
      setAppsLicense(res);
    });
  }, [authedClient]);

  function haslicense(id: number) {
    let haslicense = false;
    appslicense.map((el: any) => {
      if (el.applicationId === id) {
        haslicense = true;
      }
      return 0;
    });
    return haslicense;
  }

  const colwidth =
    window.innerWidth <= 1372 ? { width: "150px" } : { width: "100px" };

  const columns = [
    { name: "Application Name", attr: "applicationName" },
    { name: "Version", attr: "applicationVersion", style: colwidth },
    {
      name: "Number of Devices",
      attr: "numberOfComputers",
      style: colwidth,
    },
    { name: "Allowed", attr: "", style: width80pxStyle },
    { name: "Devices", attr: "", style: width80pxStyle },
    { name: "Delete", attr: "", style: width80pxStyle },
  ];

  const rows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr key={index}>
        <td>{process.applicationName}</td>
        <td>
          {process.applicationVersion === null
            ? "-"
            : process.applicationVersion}
        </td>
        <td>{process.numberOfComputers}</td>
        <td>
          <label className="switch">
            <input type="checkbox" checked={process.isAllowed}  className="custom-control-input" readOnly />
            <span
              className="slider round custom-control-label"
              onClick={() => {
                if (haslicense(process.recordId)) {
                  ToastFunction(
                    `${process.applicationName} cant be disallowed since it has a license , delete the license first`
                  );
                } else {
                  callAllowDisAllow(process.recordId);
                  process.isAllowed
                    ? ToastSuccessFunction(
                        `Success Disallowed Application ${process.applicationName}`
                      )
                    : ToastSuccessFunction(
                        `Success Allowed Application ${process.applicationName}`
                      );
                }
              }}
            ></span>
          </label>
        </td>
        <td>
          <DetailsIcon
            handleOpenModal={() => {
              callGetAppUsers(process.recordId, process.applicationName);
            }}
          />
        </td>
        <td>
          <DeleteIcon
            handleDelete={() =>
              handleDelete(process.recordId, process.applicationName)
            }
          />
        </td>
      </tr>
    ));
  };

  const rowStrings = {
    key: "applicationName",
    items: processes.map((process: any) => [process.applicationName]),
  };
  const rowsData: any = [];
  const excelData: any = [];

  processes.map((el: any) => {
    const excelObj = {
      applicationName: el.applicationName,
      applicationVersion:
        el.applicationVersion === null ? "-" : el.applicationVersion,
      numberOfComputers: el.numberOfComputers,
    };

    const obj = {
      recordId: el.id,
      applicationName: el.applicationName,
      applicationVersion:
        el.applicationVersion === null ? "-" : el.applicationVersion,
      numberOfComputers: el.numberOfComputers,
      isAllowed: el.isAllowed,
    };
    rowsData.push(obj);
    excelData.push(excelObj);
    return 0;
  });

  return (
    <div className="white-box m-0">
      {processes.length <= 0 ? (
        <NoData />
      ) : (
        <Table
          currentApps={currentApps}
          excelData={excelData}
          pageTitle={"Installed Applications"}
          rows={rows}
          rowsData={rowsData}
          columns={columns}
          rowsStrings={rowStrings}
        />
      )}
      <br />
    </div>
  );
};
