/* tslint:disable */
/* eslint-disable */
/**
 * Interactive Dashboard API
 * Interactive Dashboard API for Worex Technology
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgentLoginDto
 */
export interface AgentLoginDto {
    /**
     * 
     * @type {string}
     * @memberof AgentLoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AgentLoginDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AgentLoginDto
     */
    'computerSerial': string;
}
/**
 * 
 * @export
 * @interface AgentRegisterDto
 */
export interface AgentRegisterDto {
    /**
     * 
     * @type {string}
     * @memberof AgentRegisterDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AgentRegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AgentRegisterDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AgentRegisterDto
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof AgentRegisterDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AgentRegisterDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface AllowedApplicationInsertDto
 */
export interface AllowedApplicationInsertDto {
    /**
     * 
     * @type {string}
     * @memberof AllowedApplicationInsertDto
     */
    'applicationName': string;
    /**
     * 
     * @type {string}
     * @memberof AllowedApplicationInsertDto
     */
    'applicationVersion'?: string | null;
}
/**
 * 
 * @export
 * @interface AllowedApplicationUpdateDto
 */
export interface AllowedApplicationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AllowedApplicationUpdateDto
     */
    'recordId': number;
    /**
     * 
     * @type {string}
     * @memberof AllowedApplicationUpdateDto
     */
    'applicationName': string;
    /**
     * 
     * @type {string}
     * @memberof AllowedApplicationUpdateDto
     */
    'applicationVersion'?: string | null;
}
/**
 * 
 * @export
 * @interface ApdDto
 */
export interface ApdDto {
    /**
     * 
     * @type {string}
     * @memberof ApdDto
     */
    'processName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApdDto
     */
    'averageActiveProcessMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApdDto
     */
    'averageActiveProcessCpu'?: number;
}
/**
 * 
 * @export
 * @interface ApdInsertDto
 */
export interface ApdInsertDto {
    /**
     * 
     * @type {string}
     * @memberof ApdInsertDto
     */
    'SampleTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApdInsertDto
     */
    'MainWindowTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApdInsertDto
     */
    'ProcessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApdInsertDto
     */
    'ProcessId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApdInsertDto
     */
    'WorkingSet'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApdInsertDto
     */
    'PagedMemorySize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApdInsertDto
     */
    'VirtualMemorySize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApdInsertDto
     */
    'ActiveProcessMemoryInMb'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApdInsertDto
     */
    'ActiveProcessCpuPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApdInsertDto
     */
    'ActiveProcessDiskInMb'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApdInsertDto
     */
    'TotalWindowRunningTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApdInsertDto
     */
    'IsProcessResponding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApdInsertDto
     */
    'IsProcessInTop10'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationLicenseInsertDto
 */
export interface ApplicationLicenseInsertDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationLicenseInsertDto
     */
    'applicationId': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationLicenseInsertDto
     */
    'noLicenses': number;
}
/**
 * 
 * @export
 * @interface ApplicationLicenseUpdateDto
 */
export interface ApplicationLicenseUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationLicenseUpdateDto
     */
    'recordId': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationLicenseUpdateDto
     */
    'noLicenses': number;
}
/**
 * 
 * @export
 * @interface AudUsageDto
 */
export interface AudUsageDto {
    /**
     * 
     * @type {string}
     * @memberof AudUsageDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudUsageDto
     */
    'applicationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AudUsageDto
     */
    'numberOfUsages'?: number;
}
/**
 * 
 * @export
 * @interface AulDto
 */
export interface AulDto {
    /**
     * 
     * @type {string}
     * @memberof AulDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AulDto
     */
    'appListYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof AulDto
     */
    'appListMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof AulDto
     */
    'appListDay'?: number;
    /**
     * 
     * @type {string}
     * @memberof AulDto
     */
    'applicationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AulDto
     */
    'applicationDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AulDto
     */
    'applicationCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AulDto
     */
    'applicationVersion'?: string | null;
}
/**
 * 
 * @export
 * @interface ComputerInsertDto
 */
export interface ComputerInsertDto {
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'ComputerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'MachineDomain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'MachineModel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'ComputerSerial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'ComputerOsName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'ComputerOsManufacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'ComputerOsVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerInsertDto
     */
    'ComputerOsArchitecture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComputerInsertDto
     */
    'InstalledRam'?: number;
}
/**
 * 
 * @export
 * @interface ComputerUpdateDto
 */
export interface ComputerUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ComputerUpdateDto
     */
    'computerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'computerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'machineDomain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'machineModel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'computerSerial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'computerOsName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'computerOsManufacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'computerOsVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputerUpdateDto
     */
    'computerOsArchitecture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComputerUpdateDto
     */
    'installedRam'?: number;
}
/**
 * 
 * @export
 * @interface Detail
 */
export interface Detail {
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    'StartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    'EndTime'?: string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MmuInsertDto
 */
export interface MmuInsertDto {
    /**
     * 
     * @type {string}
     * @memberof MmuInsertDto
     */
    'SampleTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof MmuInsertDto
     */
    'MachineMemoryUtilizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof MmuInsertDto
     */
    'MachineCpuUtilizationPercentage'?: number;
}
/**
 * 
 * @export
 * @interface MmuPercentageDto
 */
export interface MmuPercentageDto {
    /**
     * 
     * @type {string}
     * @memberof MmuPercentageDto
     */
    'sampleTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof MmuPercentageDto
     */
    'machineMemoryUtilizationPercentage'?: number;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'ticks'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'milliseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMilliseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalSeconds'?: number;
}
/**
 * 
 * @export
 * @interface WebAppAddDto
 */
export interface WebAppAddDto {
    /**
     * 
     * @type {string}
     * @memberof WebAppAddDto
     */
    'applicationName': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppAddDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppAddDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface WebAppResponseDto
 */
export interface WebAppResponseDto {
    /**
     * 
     * @type {number}
     * @memberof WebAppResponseDto
     */
    'computerId': number;
    /**
     * 
     * @type {number}
     * @memberof WebAppResponseDto
     */
    'webAppID': number;
    /**
     * 
     * @type {number}
     * @memberof WebAppResponseDto
     */
    'responseTime_MS': number;
    /**
     * 
     * @type {number}
     * @memberof WebAppResponseDto
     */
    'responseCode': number;
    /**
     * 
     * @type {string}
     * @memberof WebAppResponseDto
     */
    'timeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppResponseDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface WebAppUpdateDto
 */
export interface WebAppUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof WebAppUpdateDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WebAppUpdateDto
     */
    'applicationName': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppUpdateDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppUpdateDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface WindowTimeDto
 */
export interface WindowTimeDto {
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'SampleTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'WindowTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'Application'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'Product'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'ApplicationCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'ApplicationDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'ApplicationPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'ApplicationProductName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WindowTimeDto
     */
    'ApplicationVersion'?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof WindowTimeDto
     */
    'Time'?: TimeSpan;
    /**
     * 
     * @type {Array<Detail>}
     * @memberof WindowTimeDto
     */
    'Details'?: Array<Detail> | null;
}

/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Logging in a user to the system.
         * @param {AgentLoginDto} [agentLoginDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentLoginPost: async (agentLoginDto?: AgentLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Agent/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post active process details data from the agent.
         * @param {Array<ApdInsertDto>} [apdInsertDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentPostAPDPost: async (apdInsertDto?: Array<ApdInsertDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Agent/PostAPD`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apdInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post machine memory and cpu utilization data from the agent.
         * @param {Array<MmuInsertDto>} [mmuInsertDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentPostMMUPost: async (mmuInsertDto?: Array<MmuInsertDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Agent/PostMMU`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mmuInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post window time data from the agent.
         * @param {Array<WindowTimeDto>} [windowTimeDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentPostWindowTimePost: async (windowTimeDto?: Array<WindowTimeDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Agent/PostWindowTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(windowTimeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a normal user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentRegisterPost: async (agentRegisterDto?: AgentRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Agent/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Logging in a user to the system.
         * @param {AgentLoginDto} [agentLoginDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgentLoginPost(agentLoginDto?: AgentLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgentLoginPost(agentLoginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post active process details data from the agent.
         * @param {Array<ApdInsertDto>} [apdInsertDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgentPostAPDPost(apdInsertDto?: Array<ApdInsertDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgentPostAPDPost(apdInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post machine memory and cpu utilization data from the agent.
         * @param {Array<MmuInsertDto>} [mmuInsertDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgentPostMMUPost(mmuInsertDto?: Array<MmuInsertDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgentPostMMUPost(mmuInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post window time data from the agent.
         * @param {Array<WindowTimeDto>} [windowTimeDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgentPostWindowTimePost(windowTimeDto?: Array<WindowTimeDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgentPostWindowTimePost(windowTimeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a normal user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgentRegisterPost(agentRegisterDto?: AgentRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgentRegisterPost(agentRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentApiFp(configuration)
    return {
        /**
         * 
         * @summary Logging in a user to the system.
         * @param {AgentLoginDto} [agentLoginDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentLoginPost(agentLoginDto?: AgentLoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgentLoginPost(agentLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post active process details data from the agent.
         * @param {Array<ApdInsertDto>} [apdInsertDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentPostAPDPost(apdInsertDto?: Array<ApdInsertDto>, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgentPostAPDPost(apdInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post machine memory and cpu utilization data from the agent.
         * @param {Array<MmuInsertDto>} [mmuInsertDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentPostMMUPost(mmuInsertDto?: Array<MmuInsertDto>, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgentPostMMUPost(mmuInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post window time data from the agent.
         * @param {Array<WindowTimeDto>} [windowTimeDto] Data to post.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentPostWindowTimePost(windowTimeDto?: Array<WindowTimeDto>, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgentPostWindowTimePost(windowTimeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a normal user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgentRegisterPost(agentRegisterDto?: AgentRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgentRegisterPost(agentRegisterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
    /**
     * 
     * @summary Logging in a user to the system.
     * @param {AgentLoginDto} [agentLoginDto] The data of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public apiAgentLoginPost(agentLoginDto?: AgentLoginDto, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).apiAgentLoginPost(agentLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post active process details data from the agent.
     * @param {Array<ApdInsertDto>} [apdInsertDto] Data to post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public apiAgentPostAPDPost(apdInsertDto?: Array<ApdInsertDto>, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).apiAgentPostAPDPost(apdInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post machine memory and cpu utilization data from the agent.
     * @param {Array<MmuInsertDto>} [mmuInsertDto] Data to post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public apiAgentPostMMUPost(mmuInsertDto?: Array<MmuInsertDto>, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).apiAgentPostMMUPost(mmuInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post window time data from the agent.
     * @param {Array<WindowTimeDto>} [windowTimeDto] Data to post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public apiAgentPostWindowTimePost(windowTimeDto?: Array<WindowTimeDto>, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).apiAgentPostWindowTimePost(windowTimeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a normal user to the system.
     * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public apiAgentRegisterPost(agentRegisterDto?: AgentRegisterDto, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).apiAgentRegisterPost(agentRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllowedApplicationApi - axios parameter creator
 * @export
 */
export const AllowedApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationDeleteApplicationIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAllowedApplicationDeleteApplicationIdDelete', 'id', id)
            const localVarPath = `/api/AllowedApplication/DeleteApplication/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationGetAllowedApplicationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AllowedApplication/GetAllowedApplications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a Allowed Application to the system.
         * @param {AllowedApplicationInsertDto} [allowedApplicationInsertDto] The data of the Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationInsertApplicationPost: async (allowedApplicationInsertDto?: AllowedApplicationInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AllowedApplication/InsertApplication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allowedApplicationInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllowedApplicationUpdateDto} [allowedApplicationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationUpdateApplicationPut: async (allowedApplicationUpdateDto?: AllowedApplicationUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AllowedApplication/UpdateApplication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allowedApplicationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllowedApplicationApi - functional programming interface
 * @export
 */
export const AllowedApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllowedApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAllowedApplicationDeleteApplicationIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAllowedApplicationDeleteApplicationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAllowedApplicationGetAllowedApplicationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAllowedApplicationGetAllowedApplicationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a Allowed Application to the system.
         * @param {AllowedApplicationInsertDto} [allowedApplicationInsertDto] The data of the Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAllowedApplicationInsertApplicationPost(allowedApplicationInsertDto?: AllowedApplicationInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAllowedApplicationInsertApplicationPost(allowedApplicationInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllowedApplicationUpdateDto} [allowedApplicationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAllowedApplicationUpdateApplicationPut(allowedApplicationUpdateDto?: AllowedApplicationUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAllowedApplicationUpdateApplicationPut(allowedApplicationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllowedApplicationApi - factory interface
 * @export
 */
export const AllowedApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllowedApplicationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationDeleteApplicationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAllowedApplicationDeleteApplicationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationGetAllowedApplicationsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAllowedApplicationGetAllowedApplicationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a Allowed Application to the system.
         * @param {AllowedApplicationInsertDto} [allowedApplicationInsertDto] The data of the Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationInsertApplicationPost(allowedApplicationInsertDto?: AllowedApplicationInsertDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAllowedApplicationInsertApplicationPost(allowedApplicationInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllowedApplicationUpdateDto} [allowedApplicationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowedApplicationUpdateApplicationPut(allowedApplicationUpdateDto?: AllowedApplicationUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAllowedApplicationUpdateApplicationPut(allowedApplicationUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllowedApplicationApi - object-oriented interface
 * @export
 * @class AllowedApplicationApi
 * @extends {BaseAPI}
 */
export class AllowedApplicationApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowedApplicationApi
     */
    public apiAllowedApplicationDeleteApplicationIdDelete(id: number, options?: AxiosRequestConfig) {
        return AllowedApplicationApiFp(this.configuration).apiAllowedApplicationDeleteApplicationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowedApplicationApi
     */
    public apiAllowedApplicationGetAllowedApplicationsGet(options?: AxiosRequestConfig) {
        return AllowedApplicationApiFp(this.configuration).apiAllowedApplicationGetAllowedApplicationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a Allowed Application to the system.
     * @param {AllowedApplicationInsertDto} [allowedApplicationInsertDto] The data of the Application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowedApplicationApi
     */
    public apiAllowedApplicationInsertApplicationPost(allowedApplicationInsertDto?: AllowedApplicationInsertDto, options?: AxiosRequestConfig) {
        return AllowedApplicationApiFp(this.configuration).apiAllowedApplicationInsertApplicationPost(allowedApplicationInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllowedApplicationUpdateDto} [allowedApplicationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowedApplicationApi
     */
    public apiAllowedApplicationUpdateApplicationPut(allowedApplicationUpdateDto?: AllowedApplicationUpdateDto, options?: AxiosRequestConfig) {
        return AllowedApplicationApiFp(this.configuration).apiAllowedApplicationUpdateApplicationPut(allowedApplicationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationLicenseApi - axios parameter creator
 * @export
 */
export const ApplicationLicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseDeleteApplicationLicenseIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationLicenseDeleteApplicationLicenseIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationLicense/DeleteApplicationLicense/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationLicense/GetAllowedApplicationsWithoutLicense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet: async (applicationId: number, from?: string, to?: string, groupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet', 'applicationId', applicationId)
            const localVarPath = `/api/ApplicationLicense/GetApplicationLicenseDevices/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetApplicationsLicensesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationLicense/GetApplicationsLicenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetApplicationsLicensesUtilizationGet: async (from?: string, to?: string, groupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationLicense/GetApplicationsLicensesUtilization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a Allowed Application to the system.
         * @param {ApplicationLicenseInsertDto} [applicationLicenseInsertDto] The data of the Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseInsertApplicationLicensePost: async (applicationLicenseInsertDto?: ApplicationLicenseInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationLicense/InsertApplicationLicense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationLicenseInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationLicenseUpdateDto} [applicationLicenseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseUpdateApplicationLicensePut: async (applicationLicenseUpdateDto?: ApplicationLicenseUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationLicense/UpdateApplicationLicense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationLicenseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationLicenseApi - functional programming interface
 * @export
 */
export const ApplicationLicenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationLicenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseDeleteApplicationLicenseIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseDeleteApplicationLicenseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(applicationId: number, from?: string, to?: string, groupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(applicationId, from, to, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseGetApplicationsLicensesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseGetApplicationsLicensesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseGetApplicationsLicensesUtilizationGet(from?: string, to?: string, groupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseGetApplicationsLicensesUtilizationGet(from, to, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a Allowed Application to the system.
         * @param {ApplicationLicenseInsertDto} [applicationLicenseInsertDto] The data of the Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseInsertApplicationLicensePost(applicationLicenseInsertDto?: ApplicationLicenseInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseInsertApplicationLicensePost(applicationLicenseInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationLicenseUpdateDto} [applicationLicenseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLicenseUpdateApplicationLicensePut(applicationLicenseUpdateDto?: ApplicationLicenseUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLicenseUpdateApplicationLicensePut(applicationLicenseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationLicenseApi - factory interface
 * @export
 */
export const ApplicationLicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationLicenseApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseDeleteApplicationLicenseIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseDeleteApplicationLicenseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(applicationId: number, from?: string, to?: string, groupId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(applicationId, from, to, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetApplicationsLicensesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseGetApplicationsLicensesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseGetApplicationsLicensesUtilizationGet(from?: string, to?: string, groupId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseGetApplicationsLicensesUtilizationGet(from, to, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a Allowed Application to the system.
         * @param {ApplicationLicenseInsertDto} [applicationLicenseInsertDto] The data of the Application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseInsertApplicationLicensePost(applicationLicenseInsertDto?: ApplicationLicenseInsertDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseInsertApplicationLicensePost(applicationLicenseInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationLicenseUpdateDto} [applicationLicenseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLicenseUpdateApplicationLicensePut(applicationLicenseUpdateDto?: ApplicationLicenseUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationLicenseUpdateApplicationLicensePut(applicationLicenseUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationLicenseApi - object-oriented interface
 * @export
 * @class ApplicationLicenseApi
 * @extends {BaseAPI}
 */
export class ApplicationLicenseApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseDeleteApplicationLicenseIdDelete(id: number, options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseDeleteApplicationLicenseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet(options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationName 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(applicationId: number, from?: string, to?: string, groupId?: number, options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(applicationId, from, to, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseGetApplicationsLicensesGet(options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseGetApplicationsLicensesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseGetApplicationsLicensesUtilizationGet(from?: string, to?: string, groupId?: number, options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseGetApplicationsLicensesUtilizationGet(from, to, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a Allowed Application to the system.
     * @param {ApplicationLicenseInsertDto} [applicationLicenseInsertDto] The data of the Application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseInsertApplicationLicensePost(applicationLicenseInsertDto?: ApplicationLicenseInsertDto, options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseInsertApplicationLicensePost(applicationLicenseInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationLicenseUpdateDto} [applicationLicenseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationLicenseApi
     */
    public apiApplicationLicenseUpdateApplicationLicensePut(applicationLicenseUpdateDto?: ApplicationLicenseUpdateDto, options?: AxiosRequestConfig) {
        return ApplicationLicenseApiFp(this.configuration).apiApplicationLicenseUpdateApplicationLicensePut(applicationLicenseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationsComplianceApi - axios parameter creator
 * @export
 */
export const ApplicationsComplianceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationsCompliance/GetApplicationsAndDevicesCompliance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsComplianceGetNotCompliedApplicationsGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationsCompliance/GetNotCompliedApplications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet: async (applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationName' is not null or undefined
            assertParamExists('apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet', 'applicationName', applicationName)
            const localVarPath = `/api/ApplicationsCompliance/GetNotCompliedApplicationsInstances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (applicationName !== undefined) {
                localVarQueryParameter['applicationName'] = applicationName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsComplianceApi - functional programming interface
 * @export
 */
export const ApplicationsComplianceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsComplianceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationsComplianceGetNotCompliedApplicationsGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationsComplianceGetNotCompliedApplicationsGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet(applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet(applicationName, from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsComplianceApi - factory interface
 * @export
 */
export const ApplicationsComplianceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsComplianceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsComplianceGetNotCompliedApplicationsGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationsComplianceGetNotCompliedApplicationsGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet(applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet(applicationName, from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsComplianceApi - object-oriented interface
 * @export
 * @class ApplicationsComplianceApi
 * @extends {BaseAPI}
 */
export class ApplicationsComplianceApi extends BaseAPI {
    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsComplianceApi
     */
    public apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ApplicationsComplianceApiFp(this.configuration).apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsComplianceApi
     */
    public apiApplicationsComplianceGetNotCompliedApplicationsGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ApplicationsComplianceApiFp(this.configuration).apiApplicationsComplianceGetNotCompliedApplicationsGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationName 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsComplianceApi
     */
    public apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet(applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ApplicationsComplianceApiFp(this.configuration).apiApplicationsComplianceGetNotCompliedApplicationsInstancesGet(applicationName, from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticateApi - axios parameter creator
 * @export
 */
export const AuthenticateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Logging in a user to the system.
         * @param {LoginDto} [loginDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticateLoginPost: async (loginDto?: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authenticate/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers an admin user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticateRegisterUserPost: async (agentRegisterDto?: AgentRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authenticate/register-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a normal user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticateRegisterPost: async (agentRegisterDto?: AgentRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authenticate/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticateApi - functional programming interface
 * @export
 */
export const AuthenticateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Logging in a user to the system.
         * @param {LoginDto} [loginDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticateLoginPost(loginDto?: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticateLoginPost(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers an admin user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticateRegisterUserPost(agentRegisterDto?: AgentRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticateRegisterUserPost(agentRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a normal user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticateRegisterPost(agentRegisterDto?: AgentRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticateRegisterPost(agentRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticateApi - factory interface
 * @export
 */
export const AuthenticateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticateApiFp(configuration)
    return {
        /**
         * 
         * @summary Logging in a user to the system.
         * @param {LoginDto} [loginDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticateLoginPost(loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticateLoginPost(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers an admin user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticateRegisterUserPost(agentRegisterDto?: AgentRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticateRegisterUserPost(agentRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a normal user to the system.
         * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticateRegisterPost(agentRegisterDto?: AgentRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticateRegisterPost(agentRegisterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticateApi - object-oriented interface
 * @export
 * @class AuthenticateApi
 * @extends {BaseAPI}
 */
export class AuthenticateApi extends BaseAPI {
    /**
     * 
     * @summary Logging in a user to the system.
     * @param {LoginDto} [loginDto] The data of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticateApi
     */
    public apiAuthenticateLoginPost(loginDto?: LoginDto, options?: AxiosRequestConfig) {
        return AuthenticateApiFp(this.configuration).apiAuthenticateLoginPost(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers an admin user to the system.
     * @param {AgentRegisterDto} [agentRegisterDto] The data of the admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticateApi
     */
    public apiAuthenticateRegisterUserPost(agentRegisterDto?: AgentRegisterDto, options?: AxiosRequestConfig) {
        return AuthenticateApiFp(this.configuration).apiAuthenticateRegisterUserPost(agentRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a normal user to the system.
     * @param {AgentRegisterDto} [agentRegisterDto] The data of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticateApi
     */
    public apiAuthenticateRegisterPost(agentRegisterDto?: AgentRegisterDto, options?: AxiosRequestConfig) {
        return AuthenticateApiFp(this.configuration).apiAuthenticateRegisterPost(agentRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComputerApi - axios parameter creator
 * @export
 */
export const ComputerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerGetAllComputersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Computer/GetAllComputers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a computer to the system.
         * @param {ComputerInsertDto} [computerInsertDto] The data of the computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerInsertComputerPost: async (computerInsertDto?: ComputerInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Computer/InsertComputer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(computerInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if computer exists
         * @param {string} [computerSerial] The serial of computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerIsExistsGet: async (computerSerial?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Computer/IsExists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (computerSerial !== undefined) {
                localVarQueryParameter['computerSerial'] = computerSerial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a computer to the system.
         * @param {ComputerUpdateDto} [computerUpdateDto] The data of the computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerUpdateComputerPost: async (computerUpdateDto?: ComputerUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Computer/UpdateComputer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(computerUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComputerApi - functional programming interface
 * @export
 */
export const ComputerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComputerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiComputerGetAllComputersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiComputerGetAllComputersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a computer to the system.
         * @param {ComputerInsertDto} [computerInsertDto] The data of the computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiComputerInsertComputerPost(computerInsertDto?: ComputerInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiComputerInsertComputerPost(computerInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks if computer exists
         * @param {string} [computerSerial] The serial of computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiComputerIsExistsGet(computerSerial?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiComputerIsExistsGet(computerSerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a computer to the system.
         * @param {ComputerUpdateDto} [computerUpdateDto] The data of the computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiComputerUpdateComputerPost(computerUpdateDto?: ComputerUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiComputerUpdateComputerPost(computerUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComputerApi - factory interface
 * @export
 */
export const ComputerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComputerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerGetAllComputersGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiComputerGetAllComputersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a computer to the system.
         * @param {ComputerInsertDto} [computerInsertDto] The data of the computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerInsertComputerPost(computerInsertDto?: ComputerInsertDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiComputerInsertComputerPost(computerInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks if computer exists
         * @param {string} [computerSerial] The serial of computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerIsExistsGet(computerSerial?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiComputerIsExistsGet(computerSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a computer to the system.
         * @param {ComputerUpdateDto} [computerUpdateDto] The data of the computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComputerUpdateComputerPost(computerUpdateDto?: ComputerUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiComputerUpdateComputerPost(computerUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComputerApi - object-oriented interface
 * @export
 * @class ComputerApi
 * @extends {BaseAPI}
 */
export class ComputerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputerApi
     */
    public apiComputerGetAllComputersGet(options?: AxiosRequestConfig) {
        return ComputerApiFp(this.configuration).apiComputerGetAllComputersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a computer to the system.
     * @param {ComputerInsertDto} [computerInsertDto] The data of the computer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputerApi
     */
    public apiComputerInsertComputerPost(computerInsertDto?: ComputerInsertDto, options?: AxiosRequestConfig) {
        return ComputerApiFp(this.configuration).apiComputerInsertComputerPost(computerInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks if computer exists
     * @param {string} [computerSerial] The serial of computer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputerApi
     */
    public apiComputerIsExistsGet(computerSerial?: string, options?: AxiosRequestConfig) {
        return ComputerApiFp(this.configuration).apiComputerIsExistsGet(computerSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a computer to the system.
     * @param {ComputerUpdateDto} [computerUpdateDto] The data of the computer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputerApi
     */
    public apiComputerUpdateComputerPost(computerUpdateDto?: ComputerUpdateDto, options?: AxiosRequestConfig) {
        return ComputerApiFp(this.configuration).apiComputerUpdateComputerPost(computerUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GraphsApi - axios parameter creator
 * @export
 */
export const GraphsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} computerId 
         * @param {string} [day] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdAppUsageComputerIdGet: async (computerId: number, day?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'computerId' is not null or undefined
            assertParamExists('apiGraphsGetApdAppUsageComputerIdGet', 'computerId', computerId)
            const localVarPath = `/api/Graphs/GetApdAppUsage/{computerId}`
                .replace(`{${"computerId"}}`, encodeURIComponent(String(computerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (day !== undefined) {
                localVarQueryParameter['day'] = (day as any instanceof Date) ?
                    (day as any).toISOString() :
                    day;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdApplicationUsagePercentageGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetApdApplicationUsagePercentage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets active process details average usage from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdAverageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetApdAverage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets machine and cpu utilization for processes of user from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdMachineCpuUtilGet: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetApdMachineCpuUtil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdTabsUsageSummaryGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetApdTabsUsageSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets top used tabs for user from the system.
         * @param {string} [username] The username to filter on it.
         * @param {string} [from] from filter on it.
         * @param {string} [to] to filter on it.
         * @param {number} [groupId] The groupId to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdTopTabsGet: async (username?: string, from?: string, to?: string, groupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetApdTopTabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets application process details for top utilized applications from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdTopUtilGet: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetApdTopUtil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets application usage details from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetAudUsageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetAudUsage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets applications list from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetAulGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetAul`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Machine Memory Utilization Percentage from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetMmuPercentageGet: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Graphs/GetMmuPercentage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetProcessUsageDetailsGet: async (applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationName' is not null or undefined
            assertParamExists('apiGraphsGetProcessUsageDetailsGet', 'applicationName', applicationName)
            const localVarPath = `/api/Graphs/GetProcessUsageDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (applicationName !== undefined) {
                localVarQueryParameter['applicationName'] = applicationName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetVisitedSiteDetailsGet: async (siteName: string, from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('apiGraphsGetVisitedSiteDetailsGet', 'siteName', siteName)
            const localVarPath = `/api/Graphs/GetVisitedSiteDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (siteName !== undefined) {
                localVarQueryParameter['siteName'] = siteName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GraphsApi - functional programming interface
 * @export
 */
export const GraphsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GraphsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} computerId 
         * @param {string} [day] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdAppUsageComputerIdGet(computerId: number, day?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdAppUsageComputerIdGet(computerId, day, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdApplicationUsagePercentageGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdApplicationUsagePercentageGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets active process details average usage from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdAverageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApdDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdAverageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets machine and cpu utilization for processes of user from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdMachineCpuUtilGet(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdMachineCpuUtilGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdTabsUsageSummaryGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdTabsUsageSummaryGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets top used tabs for user from the system.
         * @param {string} [username] The username to filter on it.
         * @param {string} [from] from filter on it.
         * @param {string} [to] to filter on it.
         * @param {number} [groupId] The groupId to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdTopTabsGet(username?: string, from?: string, to?: string, groupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdTopTabsGet(username, from, to, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets application process details for top utilized applications from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetApdTopUtilGet(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetApdTopUtilGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets application usage details from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetAudUsageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudUsageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetAudUsageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets applications list from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetAulGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AulDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetAulGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets Machine Memory Utilization Percentage from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetMmuPercentageGet(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MmuPercentageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetMmuPercentageGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetProcessUsageDetailsGet(applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetProcessUsageDetailsGet(applicationName, from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} siteName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGraphsGetVisitedSiteDetailsGet(siteName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGraphsGetVisitedSiteDetailsGet(siteName, from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GraphsApi - factory interface
 * @export
 */
export const GraphsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GraphsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} computerId 
         * @param {string} [day] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdAppUsageComputerIdGet(computerId: number, day?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetApdAppUsageComputerIdGet(computerId, day, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdApplicationUsagePercentageGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetApdApplicationUsagePercentageGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets active process details average usage from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdAverageGet(options?: any): AxiosPromise<Array<ApdDto>> {
            return localVarFp.apiGraphsGetApdAverageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets machine and cpu utilization for processes of user from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdMachineCpuUtilGet(username?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetApdMachineCpuUtilGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdTabsUsageSummaryGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetApdTabsUsageSummaryGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets top used tabs for user from the system.
         * @param {string} [username] The username to filter on it.
         * @param {string} [from] from filter on it.
         * @param {string} [to] to filter on it.
         * @param {number} [groupId] The groupId to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdTopTabsGet(username?: string, from?: string, to?: string, groupId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetApdTopTabsGet(username, from, to, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets application process details for top utilized applications from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetApdTopUtilGet(username?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetApdTopUtilGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets application usage details from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetAudUsageGet(options?: any): AxiosPromise<Array<AudUsageDto>> {
            return localVarFp.apiGraphsGetAudUsageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets applications list from the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetAulGet(options?: any): AxiosPromise<Array<AulDto>> {
            return localVarFp.apiGraphsGetAulGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Machine Memory Utilization Percentage from the system.
         * @param {string} [username] The username to filter on it.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetMmuPercentageGet(username?: string, options?: any): AxiosPromise<Array<MmuPercentageDto>> {
            return localVarFp.apiGraphsGetMmuPercentageGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetProcessUsageDetailsGet(applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetProcessUsageDetailsGet(applicationName, from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} siteName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGraphsGetVisitedSiteDetailsGet(siteName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGraphsGetVisitedSiteDetailsGet(siteName, from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GraphsApi - object-oriented interface
 * @export
 * @class GraphsApi
 * @extends {BaseAPI}
 */
export class GraphsApi extends BaseAPI {
    /**
     * 
     * @param {number} computerId 
     * @param {string} [day] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdAppUsageComputerIdGet(computerId: number, day?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdAppUsageComputerIdGet(computerId, day, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdApplicationUsagePercentageGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdApplicationUsagePercentageGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets active process details average usage from the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdAverageGet(options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdAverageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets machine and cpu utilization for processes of user from the system.
     * @param {string} [username] The username to filter on it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdMachineCpuUtilGet(username?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdMachineCpuUtilGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdTabsUsageSummaryGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdTabsUsageSummaryGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets top used tabs for user from the system.
     * @param {string} [username] The username to filter on it.
     * @param {string} [from] from filter on it.
     * @param {string} [to] to filter on it.
     * @param {number} [groupId] The groupId to filter on it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdTopTabsGet(username?: string, from?: string, to?: string, groupId?: number, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdTopTabsGet(username, from, to, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets application process details for top utilized applications from the system.
     * @param {string} [username] The username to filter on it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetApdTopUtilGet(username?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetApdTopUtilGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets application usage details from the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetAudUsageGet(options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetAudUsageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets applications list from the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetAulGet(options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetAulGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Machine Memory Utilization Percentage from the system.
     * @param {string} [username] The username to filter on it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetMmuPercentageGet(username?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetMmuPercentageGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationName 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetProcessUsageDetailsGet(applicationName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetProcessUsageDetailsGet(applicationName, from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} siteName 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public apiGraphsGetVisitedSiteDetailsGet(siteName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).apiGraphsGetVisitedSiteDetailsGet(siteName, from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetAllGroupsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetAllGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetAllGroupsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetAllGroupsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetAllGroupsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetAllGroupsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetAllGroupsGet(options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiGroupGetAllGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProcessesPerformanceApi - axios parameter creator
 * @export
 */
export const ProcessesPerformanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} processName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessDevicesListGet: async (processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processName' is not null or undefined
            assertParamExists('apiProcessesPerformanceGetProcessDevicesListGet', 'processName', processName)
            const localVarPath = `/api/ProcessesPerformance/GetProcessDevicesList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (processName !== undefined) {
                localVarQueryParameter['ProcessName'] = processName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} processName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessFailureRecordsGet: async (processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processName' is not null or undefined
            assertParamExists('apiProcessesPerformanceGetProcessFailureRecordsGet', 'processName', processName)
            const localVarPath = `/api/ProcessesPerformance/GetProcessFailureRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (processName !== undefined) {
                localVarQueryParameter['ProcessName'] = processName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProcessesPerformance/GetProcessesCPUMemoryDiskPerformanceSummarized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessesPerformanceGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProcessesPerformance/GetProcessesPerformanceSummarized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessesPerformanceApi - functional programming interface
 * @export
 */
export const ProcessesPerformanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessesPerformanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} processName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProcessesPerformanceGetProcessDevicesListGet(processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProcessesPerformanceGetProcessDevicesListGet(processName, from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} processName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProcessesPerformanceGetProcessFailureRecordsGet(processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProcessesPerformanceGetProcessFailureRecordsGet(processName, from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProcessesPerformanceGetProcessesPerformanceGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProcessesPerformanceGetProcessesPerformanceGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessesPerformanceApi - factory interface
 * @export
 */
export const ProcessesPerformanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessesPerformanceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} processName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessDevicesListGet(processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProcessesPerformanceGetProcessDevicesListGet(processName, from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} processName 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessFailureRecordsGet(processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProcessesPerformanceGetProcessFailureRecordsGet(processName, from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProcessesPerformanceGetProcessesPerformanceGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProcessesPerformanceGetProcessesPerformanceGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessesPerformanceApi - object-oriented interface
 * @export
 * @class ProcessesPerformanceApi
 * @extends {BaseAPI}
 */
export class ProcessesPerformanceApi extends BaseAPI {
    /**
     * 
     * @param {string} processName 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessesPerformanceApi
     */
    public apiProcessesPerformanceGetProcessDevicesListGet(processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ProcessesPerformanceApiFp(this.configuration).apiProcessesPerformanceGetProcessDevicesListGet(processName, from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} processName 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessesPerformanceApi
     */
    public apiProcessesPerformanceGetProcessFailureRecordsGet(processName: string, from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ProcessesPerformanceApiFp(this.configuration).apiProcessesPerformanceGetProcessFailureRecordsGet(processName, from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessesPerformanceApi
     */
    public apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ProcessesPerformanceApiFp(this.configuration).apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessesPerformanceApi
     */
    public apiProcessesPerformanceGetProcessesPerformanceGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return ProcessesPerformanceApiFp(this.configuration).apiProcessesPerformanceGetProcessesPerformanceGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUsersList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersSummaryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUsersSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUsersListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUsersListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUsersSummaryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUsersSummaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUsersListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersSummaryGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUsersSummaryGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUsersListGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUsersListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUsersSummaryGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUsersSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebAppMonitoringApi - axios parameter creator
 * @export
 */
export const WebAppMonitoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringDeleteWebApplicationIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWebAppMonitoringDeleteWebApplicationIdDelete', 'id', id)
            const localVarPath = `/api/WebAppMonitoring/DeleteWebApplication/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringGetAllWebAppsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WebAppMonitoring/GetAllWebApps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WebAppMonitoring/GetDeviceWebAppsForMonitoring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringGetWebAppsAvailabilityGet: async (from?: string, to?: string, groupId?: number, computerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WebAppMonitoring/GetWebAppsAvailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WebAppAddDto} [webAppAddDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringInsertWebAppPost: async (webAppAddDto?: WebAppAddDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WebAppMonitoring/InsertWebApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webAppAddDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WebAppResponseDto} [webAppResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringInsertWebAppResponsePost: async (webAppResponseDto?: WebAppResponseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WebAppMonitoring/InsertWebAppResponse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webAppResponseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WebAppUpdateDto} [webAppUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringUpdateWebAppPut: async (webAppUpdateDto?: WebAppUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WebAppMonitoring/UpdateWebApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webAppUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebAppMonitoringApi - functional programming interface
 * @export
 */
export const WebAppMonitoringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebAppMonitoringApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringDeleteWebApplicationIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringDeleteWebApplicationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringGetAllWebAppsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringGetAllWebAppsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringGetWebAppsAvailabilityGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringGetWebAppsAvailabilityGet(from, to, groupId, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WebAppAddDto} [webAppAddDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringInsertWebAppPost(webAppAddDto?: WebAppAddDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringInsertWebAppPost(webAppAddDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WebAppResponseDto} [webAppResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringInsertWebAppResponsePost(webAppResponseDto?: WebAppResponseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringInsertWebAppResponsePost(webAppResponseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WebAppUpdateDto} [webAppUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebAppMonitoringUpdateWebAppPut(webAppUpdateDto?: WebAppUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebAppMonitoringUpdateWebAppPut(webAppUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebAppMonitoringApi - factory interface
 * @export
 */
export const WebAppMonitoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebAppMonitoringApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringDeleteWebApplicationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringDeleteWebApplicationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringGetAllWebAppsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringGetAllWebAppsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [groupId] 
         * @param {number} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringGetWebAppsAvailabilityGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringGetWebAppsAvailabilityGet(from, to, groupId, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebAppAddDto} [webAppAddDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringInsertWebAppPost(webAppAddDto?: WebAppAddDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringInsertWebAppPost(webAppAddDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebAppResponseDto} [webAppResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringInsertWebAppResponsePost(webAppResponseDto?: WebAppResponseDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringInsertWebAppResponsePost(webAppResponseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebAppUpdateDto} [webAppUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebAppMonitoringUpdateWebAppPut(webAppUpdateDto?: WebAppUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiWebAppMonitoringUpdateWebAppPut(webAppUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebAppMonitoringApi - object-oriented interface
 * @export
 * @class WebAppMonitoringApi
 * @extends {BaseAPI}
 */
export class WebAppMonitoringApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringDeleteWebApplicationIdDelete(id: number, options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringDeleteWebApplicationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringGetAllWebAppsGet(options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringGetAllWebAppsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet(options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringGetDeviceWebAppsForMonitoringGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [groupId] 
     * @param {number} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringGetWebAppsAvailabilityGet(from?: string, to?: string, groupId?: number, computerId?: number, options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringGetWebAppsAvailabilityGet(from, to, groupId, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebAppAddDto} [webAppAddDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringInsertWebAppPost(webAppAddDto?: WebAppAddDto, options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringInsertWebAppPost(webAppAddDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebAppResponseDto} [webAppResponseDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringInsertWebAppResponsePost(webAppResponseDto?: WebAppResponseDto, options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringInsertWebAppResponsePost(webAppResponseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebAppUpdateDto} [webAppUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppMonitoringApi
     */
    public apiWebAppMonitoringUpdateWebAppPut(webAppUpdateDto?: WebAppUpdateDto, options?: AxiosRequestConfig) {
        return WebAppMonitoringApiFp(this.configuration).apiWebAppMonitoringUpdateWebAppPut(webAppUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


