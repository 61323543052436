import { DateTime } from "luxon";

/**
 * Converts a UTC date to the user's local time zone.
 * @param inputDate The input date in UTC. It can be a DateTime object or a string.
 * @returns The converted local date as a Luxon DateTime object.
 */
export function ConvertUTCDateTimeToLocal(inputDate: Date | string): DateTime {
  try {
    const dateUTC =
      typeof inputDate === "string"
        ? DateTime.fromISO(inputDate, { zone: "utc" })
        : DateTime.fromJSDate(inputDate, { zone: "utc" });
    return dateUTC.toLocal();
  } catch (error) {
    console.error("Error converting UTC to local date:", error);
    throw new Error("Failed to convert UTC to local date");
  }
}

/**
 * Converts a local date to the UTC time zone and returns a formatted string.
 * @param inputDate The input local date. It can be a DateTime object or a string.
 * @returns The converted and formatted UTC date-time string.
 */
export function ConvertLocalDateTimeToUTC(inputDate: Date | string): string {
  try {
    const localDate =
      typeof inputDate === "string"
        ? DateTime.fromISO(inputDate)
        : DateTime.fromJSDate(inputDate);
    return localDate.toUTC().toISO();
  } catch (error) {
    console.error("Error converting local to UTC:", error);
    throw new Error("Failed to convert local to UTC");
  }
}

/**
 * Converts the input date to a UTC DateTime object with the start of the day.
 * @param dateTimeInput The input date which can be a Luxon DateTime object, a JavaScript Date object, or a string.
 * @returns The converted UTC DateTime object with the start of the day.
 * @throws Will throw an error if the input type is invalid.
 */
export function ConvertToUTC(
  dateTimeInput: DateTime | Date | string
): DateTime {
  try {
    let localDateTime: DateTime;

    if (dateTimeInput instanceof DateTime) {
      localDateTime = dateTimeInput;
    } else if (dateTimeInput instanceof Date) {
      localDateTime = DateTime.fromJSDate(dateTimeInput);
    } else if (typeof dateTimeInput === "string") {
      localDateTime = DateTime.fromISO(dateTimeInput);
    } else {
      throw new Error("Invalid input type");
    }

    // Go to the start of the day in local time zone
    // Then Convert to UTC
    return localDateTime.startOf("day").toUTC();
  } catch (error) {
    console.error("Error converting to UTC with start of day:", error);
    throw new Error("Failed to convert to UTC with start of day");
  }
}
