import Image from "./WebMonitoring.svg";
function WebMonitorIcon() {
  const style = {
    width: "21px",
    height: "27px",
  };
  return <img src={Image} alt="" style={style} />;
}

export default WebMonitorIcon;
