import http from "./httpService";

const url = "/ApplicationLicense";
http.setAuthorizationToken();

export function InsertApplicationLicense(
  applicationId,
  noLicenses,
  licenseStartDate,
  licenseExpirationDate
) {
  return http.post(`${url}/InsertApplicationLicense?applicationId`, {
    applicationId: applicationId,
    noLicenses: noLicenses,
    licenseStartDate: licenseStartDate,
    licenseExpirationDate: licenseExpirationDate,
  });
}

export function UpdateApplicationLicense(
  recordId,
  noLicenses,
  licenseStartDate,
  licenseExpirationDate
) {
  return http.put(`${url}/UpdateApplicationLicense`, {
    recordId: recordId,
    noLicenses: noLicenses,
    licenseStartDate: licenseStartDate,
    licenseExpirationDate: licenseExpirationDate,
  });
}

export function DeleteApplicationLicense(id) {
  return http.delete(url + `/DeleteApplicationLicense/${id}`);
}

export function GetApplicationsLicenses() {
  return http.get(url + `/GetApplicationsLicenses`);
}

export function GetApplicationsLicensesUtilization() {
  return http.get(url + `/GetApplicationsLicensesUtilization`);
}

export function GetApplicationLicenseDevices(ApplicationLicenseId) {
  return http.get(
    url + `/GetApplicationLicenseDevices/${ApplicationLicenseId}`
  );
}

export function GetAllowedApplicationsWithoutLicense() {
  return http.get(url + `/GetAllowedApplicationsWithoutLicense`);
}
