import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Action,
  GetActions,
  UpdateAction,
  AddAction,
  DeleteAction,
} from "../../services/RemoteActionsService";
import { Category, GetCategories } from "../../services/CommandCategoryService";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";

// Define the shape of the context
interface RemoteActionsContextProps {
  action: Action;
  actions: Action[];
  categories: Category[];
  mode: "Clone" | "Edit" | "";
  showFormModal: boolean;
  showDeleteModal: boolean;
  showDiscardModal: boolean;
  showConfirmModal: boolean;
  fetchActionsDataAndSetState: () => Promise<void>;
  setActionsState: (actions: Action[]) => void;
  handleEditAction: (action: Action) => void;
  handleDeleteAction: (action: Action) => void;
  handleCloneAction: (action: Action) => void;
  handleAddNewAction: () => void;
  deleteButtonHandler: () => void;
  handleCloseFormModal: () => void;
  handleCloseDiscardModal: () => void;
  handleCloseConfirmModal: () => void;
  handleCloseDeleteModal: () => void;
  CloseAndReset: () => void;
  handleChange: (data: any) => void;
  handleSubmitAction: () => void;
  isActionExist: boolean;
}

// Create the context
const RemoteActionsContext = createContext<
  RemoteActionsContextProps | undefined
>(undefined);

// Custom hook to use the context
export const useRemoteActions = () => {
  const context = useContext(RemoteActionsContext);
  if (!context) {
    throw new Error(
      "useRemoteActions must be used within a RemoteActionsProvider"
    );
  }
  return context;
};

// Context provider component
export const RemoteActionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const InitialAction: Action = {
    id: 0,
    commandCategoryId: -1,
    actionName: "",
    description: "",
    command: "",
  };

  const [actions, setActions] = useState<Action[]>([]);
  const [action, setAction] = useState<Action>(InitialAction);
  const [categories, setCategories] = useState<Category[]>([]);
  const [mode, setMode] = useState<"Clone" | "Edit" | "">("");
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
  const [isActionExist, setIsActionExist] = useState<boolean>(false);

  // Fetch actions and categories and set state
  const fetchActionsDataAndSetState = async () => {
    try {
      const [actionsResponse, categoriesResponse] = await Promise.all([
        GetActions(),
        GetCategories(),
      ]);
      setActions(actionsResponse.data.data);
      setCategories(categoriesResponse.data.data);
    } catch (error) {
      ToastFunction("Failed to fetch data");
    }
  };

  const setActionsState = useCallback((IntialActions: Action[]) => {
    setActions(IntialActions);
  }, []);

  const handleAddNewAction = () => setShowFormModal(true);
  const handleCloseDiscardModal = () => setShowDiscardModal(false);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setAction(InitialAction);
  };

  const CloseAndReset = () => {
    setShowFormModal(false);
    setIsActionExist(false);
    setShowConfirmModal(false);
    setShowDeleteModal(false);
    setShowDiscardModal(false);
    setMode("");
    setAction(InitialAction);
  };

  const isFormModified = (): boolean =>
    JSON.stringify(action) !== JSON.stringify(InitialAction);

  const handleCloseFormModal = () => {
    if (isFormModified()) setShowDiscardModal(true);
    else CloseAndReset();
  };

  const handleEditAction = (selectedAction: Action) => {
    setAction(selectedAction);
    setMode("Edit");
    setShowFormModal(true);
  };

  const handleDeleteAction = (selectedAction: Action) => {
    setAction(selectedAction);
    setShowDeleteModal(true);
  };

  const deleteButtonHandler = async () => {
    const originalActions = [...actions];
    const newActions = actions.filter((a) => a.id !== action.id);
    setActions(newActions);
    handleCloseDeleteModal();
    try {
      await DeleteAction(action.id);
      ToastSuccessFunction("The action has been deleted successfully.");
    } catch (error) {
      ToastFunction("Action has not been deleted.");
      setActions(originalActions);
    }
  };

  const handleCloneAction = (selectedAction: Action) => {
    setAction(selectedAction);
    setMode("Clone");
    setShowFormModal(true);
  };

  const handleChange = ({
    currentTarget: input,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setAction((prevState) => ({
      ...prevState,
      [input.name]: input.value,
    }));
  };

  const handleSubmitAction = async () => {
    // Validate action existence
    const actionExists = actions.some(
      (obj) => obj.actionName === action.actionName
    );
    if (!actionExists) {
      if (mode === "Edit") {
        await callEditAction();
      } else {
        await callAddAction();
      }
      CloseAndReset();
    } else {
      setIsActionExist(true);
    }
  };

  const callEditAction = async () => {
    try {
      await UpdateAction(action);
      ToastSuccessFunction("Action edited successfully");
      await fetchActionsDataAndSetState();
    } catch (error: any) {
      ToastFunction(error.message);
    }
  };

  const callAddAction = async () => {
    try {
      await AddAction(action);
      ToastSuccessFunction("Action added successfully");
      await fetchActionsDataAndSetState();
    } catch (error: any) {
      ToastFunction(error.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data: categoriesData } = await GetCategories();
      setCategories(categoriesData.data);
    } catch (error) {
      ToastFunction("Failed to fetch categories");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <RemoteActionsContext.Provider
      value={{
        action,
        actions,
        categories,
        mode,
        showFormModal,
        showDeleteModal,
        showDiscardModal,
        showConfirmModal,
        isActionExist,
        fetchActionsDataAndSetState,
        setActionsState,
        handleEditAction,
        handleDeleteAction,
        handleCloneAction,
        handleAddNewAction,
        deleteButtonHandler,
        handleCloseFormModal,
        handleCloseConfirmModal,
        handleCloseDiscardModal,
        handleCloseDeleteModal,
        CloseAndReset,
        handleChange,
        handleSubmitAction,
      }}
    >
      {children}
    </RemoteActionsContext.Provider>
  );
};
