/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import { useSortableData } from "../hooks/useSortedTable";
import { TableSearch } from "./Table Component/TableSearch";
import { useUID } from "react-uid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NoData } from "./NoData";
import "../font/AmiriFont";
import styled from "styled-components";
import { Sort } from "@styled-icons/boxicons-regular/Sort";
import DescriptionTooltip from "./common/DescriptionTooltip/descriptionTooltip";
import "./Table Component/TableStyle.css";
import Tooltip from "@mui/material/Tooltip";
import { TablePaginationWithFetch } from "./Table Component/TablePaginationWithFetch";
import {
  Pagination,
  SearchLogic,
  NumberOfRowsLogic,
} from "./DomainManagement/DomainTable";
import { UrlWithPAginationResponse } from "../services/configMaliciuosUrl/URLsWithPagination";
import { MaliciousTypes } from "../staticData/securityMonitoring";
import { GetAllURLs } from "../services/configMaliciuosUrl/GetAllURLs";
export const width62pxStyle = { width: "62px" };
export const width35pxStyle = { width: "35px" };
export const width80pxStyle = { width: "80px" };
export const width100pxStyle = { width: "100px" };
export const width150pxStyle = { width: "150px" };
export const width250pxStyle = { width: "250px" };

export type TableProps = {
  searchValue?: string;
  data: UrlWithPAginationResponse;
  pageTitle: string;
  columns: Array<{
    name: string;
    style?: CSSProperties;
    attr: string;
    tooltib?: string;
  }>;
  rowsData: Array<any>;
  rows?: (currentPageRowsData: Array<any>) => Array<ReactNode>;
  getCells?: (row: any) => Array<ReactNode>;
  rowsStrings?: {
    key: string | Array<string>;
    items: Array<Array<string>> | undefined;
  };
  // excelData: any;
  //getExcelData; () => Promise<any>;
  currentApps?: any;
  intialPage?: number | undefined | null;
  setintialPage?: Dispatch<SetStateAction<null>>;
  tabId?: string;
  paginationLogic: Pagination;
  searchLogic: SearchLogic;
  numberOfRowsLogic: NumberOfRowsLogic;
};

const SortDescIcon = styled(Sort)`
  width: 30px;
  height: 21px;
  color: #b9b9b9;
`;
export function TableWithFetchOnPagination(props: TableProps) {
  const {
    searchValue,
    data,
    columns,
    rowsStrings,
    rowsData,
    pageTitle,
    // excelData,
    // getExcelData,
    currentApps,
    intialPage,
    tabId,
    paginationLogic,
    searchLogic,
    numberOfRowsLogic,
  } = props;
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);

  const setNumberOfRows = (nomOfRows: number) => {
    paginationLogic.setPageNumber(1);
    setTableRowsPerPage(nomOfRows);
    numberOfRowsLogic.setRowsSize(nomOfRows);
  };
  // Making them in one object to change both of them atomically
  const [currentPageAndSearchTerm, setCurrentPageAndSearchTerm] = useState({
    currentPage: intialPage,
    searchTerm: "",
  });
  const [excelData, setExcelData] = useState<
    {
      id: number;
      url: string;
      labelId: number;
    }[]
  >([]);
  const [excelContent, setExcelContent] = useState([]);

  const uid = useUID();
  const headers: Array<string> = [];
  const arrOfData: Array<Array<any>> = [];

  const getExcelData = useCallback(async () => {
    const data = await GetAllURLs();
    const excelData: any = [];
    try {
      data.data.data.forEach((el) => {
        const excelObj = {
          url: el.url,
          labelId: MaliciousTypes[el.labelId],
        };
        excelData.push(excelObj);
      });
      setExcelData(excelData);
      setExcelContent(excelData);

      return excelData;
    } catch (error) {
      console.log(error);
    }
    return [];
  }, []);
  const toPdf = async () => {
    const data = await getExcelData();
    const pdf: any = new jsPDF("p");
    pdf.setFont("Amiri-Regular");
    pdf.autoTable({
      html: `#pdf-${uid}`,
      headStyles: { font: "Amiri-Regular" },
      bodyStyles: { font: "Amiri-Regular" },
    });
    pdf.save(`${pageTitle}-report.pdf`);

    await data.forEach((el: any) => {
      let singleRow: Array<any> = Object.values(el);
      arrOfData.push(singleRow);
    });
  };

  columns.forEach((el) => {
    if (el.attr !== "") {
      headers.push(el.name);
    }
  });
  const toExcel = async () => {
    try {
      const data = await getExcelData();
      const excelRows = data.map((el: any) => {
        return Object.values(el);
      });

      const excelContent: any = [headers, ...excelRows];
      await setExcelContent(excelContent);
    } catch (error) {}
  };

  useEffect(() => {
    setCurrentPageAndSearchTerm({
      currentPage: 1,
      searchTerm: "",
    });
  }, [tabId, intialPage, currentApps, pageTitle, paginationLogic]);

  const currentPage: any = currentPageAndSearchTerm.currentPage;
  const searchTerm = currentPageAndSearchTerm.searchTerm;
  const setCurrentPage = (newCurrentPage: number) =>
    setCurrentPageAndSearchTerm({
      currentPage: newCurrentPage,
      searchTerm,
    });
  const setSearchTerm = (newSearchTerm: string) => {
    setCurrentPageAndSearchTerm({ currentPage: 1, searchTerm: newSearchTerm });
    paginationLogic.setPageNumber(1);
    //_.debounce(() => {
    searchLogic.setSearchValue(newSearchTerm);
    // }, 0);
  };

  const { key }: any = rowsStrings;
  const searchedData = rowsData.filter((row) => {
    if (searchTerm === "") {
      return true;
    } else {
      if (Array.isArray(key)) {
        return key.some((k: string) =>
          row[k]
            ?.toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase().trim())
        );
      } else if (typeof key === "string") {
        return row[key]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trim());
      } else {
        return false; // Invalid key format
      }
    }
  });
  const { items: sortedSearchedRowsData, requestSort } =
    useSortableData(searchedData);

  const indexOfLastPost = currentPage * tableRowsPerPage;
  const indexOfFirstPost = indexOfLastPost - tableRowsPerPage;
  const currentPageRowsData = sortedSearchedRowsData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const haveNoSortingHeaders = [
    "Details",
    "Trend",
    "Disallow",
    "Allowed",
    "Delete",
    "Edit",
    "Reset Password",
    "Application Version",
    "Version",
    "Devices",
  ];
  const tableHeadings = columns.map((column, index) => (
    <th
      key={index}
      className={`th-sm ${column.attr}`}
      style={column.style}
      onClick={() => column.attr !== "" && requestSort(column.attr)}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {column.tooltib !== undefined ? (
          <Tooltip title={column.tooltib} placement="top" arrow>
            <div>{column.name}</div>
          </Tooltip>
        ) : (
          column.name
        )}
        {column.name === "Avg Response Time (sec)" && (
          <DescriptionTooltip
            description={
              "Response time refers to the amount of time application server takes to return the results of a request to the user."
            }
          />
        )}
        {!haveNoSortingHeaders.includes(column.name) && <SortDescIcon />}
      </div>
    </th>
  ));

  const pdfTableHeadings = columns
    .filter((el) => {
      return el.attr !== "";
    })
    .map((column, index) => (
      <th
        key={index}
        style={column.style}
        onClick={column.attr === "" ? () => {} : () => requestSort(column.attr)}
      >
        {column.name}
      </th>
    ));
  return (
    <>
      <div className="table-container">
        <TableSearch
          toPdf={toPdf}
          toExcel={toExcel}
          pageTitle={pageTitle}
          excelContent={excelContent}
          arrayLength={sortedSearchedRowsData.length}
          setTableRowsPerPage={setNumberOfRows}
          tableRowsPerPage={tableRowsPerPage}
          setSearchTerm={setSearchTerm}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          searchValue={searchValue}
          compParent ='paginatedTable'
        />
        {currentPageRowsData.length === 0 || rowsData.length === 0 ? (
          <NoData />
        ) : (
          <>
            <div className="my-tabel-styling">
              <BootstrapTable>
                <thead>
                  <tr>{tableHeadings}</tr>
                </thead>

                <tbody>
                  {props.getCells !== undefined &&
                    currentPageRowsData.map((row, index) => (
                      <tr key={index}>
                        {props.getCells &&
                          props
                            .getCells(row)
                            .map((cell, index) => <td key={index}>{cell}</td>)}
                      </tr>
                    ))}
                  {props.rows !== undefined && props.rows(currentPageRowsData)}
                </tbody>
              </BootstrapTable>
              <BootstrapTable
                responsive
                striped
                id={`pdf-${uid}`}
                style={{ display: "none" }}
                className={`${
                  rowsData.length === 0 || currentPageRowsData.length === 0
                    ? "overflow-hidden"
                    : "overflow-auto"
                }`}
              >
                <thead>
                  <tr>{pdfTableHeadings}</tr>
                </thead>
                <tbody>
                  {props.rows !== undefined &&
                    props.rows(
                      excelData.map((i: { url: string; labelId: number }) => ({
                        url: i.url,
                        labelId: i.labelId,
                      }))
                    )}
                </tbody>
              </BootstrapTable>
            </div>
          </>
        )}
      </div>
      {currentPageRowsData.length === 0 || rowsData.length === 0 ? (
        ""
      ) : (
        <TablePaginationWithFetch
          totalItems={data.totalItems}
          pageSize={data.pageSize}
          numberOfPages={data.totalPages}
          totalRowsCount={sortedSearchedRowsData.length}
          currentPage={data.pageNumber}
          paginationLogic={paginationLogic}
        />
      )}
    </>
  );
}
