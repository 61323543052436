import http from "./httpService";

const url = "/AgentReports/GetAgentDeviceAvailabilityDetails";
http.setAuthorizationToken();

function agentDevicesAvailabilityDetailsUrl(fromDate, toDate, computerId) {
  return `${url}?from=${fromDate}&to=${toDate}&computerId=${computerId}`;
}

export function getAgentDevicesAvailabilityDetails(
  fromDate,
  toDate,
  computerId
) {
  return http.get(
    agentDevicesAvailabilityDetailsUrl(fromDate, toDate, computerId)
  );
}
