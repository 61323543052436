/* eslint-disable array-callback-return */
import { useMemo, useState } from "react";
type Item = { [key: string]: any };
type SortConfig = {
  key: string;
  direction: "ascending" | "descending";
};
const useSortableData = (items: Item[], config: SortConfig | null = null) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(config);
  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = parseValue(a[sortConfig.key]);
        const bValue = parseValue(b[sortConfig.key]);
        const direction = sortConfig.direction === "ascending" ? 1 : -1;
        return direction * (aValue < bValue ? -1 : 1);
      });
    } else if (sortableItems[0]?.hasOwnProperty("averageCPU")) {
      sortableItems?.sort((aVal, bVal): any => {
        if (
          aVal?.hasOwnProperty("averageCPU") &&
          bVal?.hasOwnProperty("averageCPU")
        ) {
          return parseValue(aVal["averageCPU"]) < parseValue(bVal["averageCPU"])
            ? 1
            : -1;
        }
      });
    } else if (sortableItems[0]?.hasOwnProperty("averageMemoryPercentage")) {
      sortableItems?.sort((aVal, bVal): any => {
        if (
          aVal?.hasOwnProperty("averageMemoryInMb") &&
          bVal?.hasOwnProperty("averageMemoryInMb")
        ) {
          return parseValue(aVal["averageMemoryInMb"]) <
            parseValue(bVal["averageMemoryInMb"])
            ? 1
            : -1;
        }
      });
    }
    return sortableItems;
  }, [items, sortConfig]);
  const requestSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  return { items: sortedItems, requestSort, sortConfig };
};
const parseValue = (value: any) => {
  if (typeof value === "string") {
    // Remove any special characters before the string
    value = value.replace(/[^\w\s:/]/gi, ""); // Allow colon for time and slash for dates
    const dateRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/; // Adjusted regex for DD/MM/YYYY HH:MM:SS
    if (dateRegex.test(value)) {
      const [day, month, year, hour, minute, second] =
        value.match(/\d+/g)?.map(Number) || [];
      return new Date(year, month - 1, day, hour, minute, second);
    } else {
      return value.toLowerCase();
    }
  } else if (typeof value === "number") {
    return value;
  }
  return value;
};

export { useSortableData };
