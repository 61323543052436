import { CloseButton, Modal } from "react-bootstrap";
import { getMaliciousActivitiesURLDetails } from "../../services/MaliciousActivities/MaliciousActivitiesURLDetails";
import { useContext, useEffect, useState } from "react";
import { Table } from "../Table";
import moment from "moment";
import { Filters, FiltersContext } from "../../context/FiltersContext";
import { Loader } from "../Loader/Loader";

type Props = {
  domain: string;
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
  modalTitle: string;
};

type urlInfo = {
  computerId: number;
  computerName: string;
  createdAt: string;
};
export const MaliciousUrlDetailsModal = ({
  domain,
  visibility,
  setVisibility,
  modalTitle,
}: Props) => {
  const [details, setDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const filters = useContext(FiltersContext);
  const { startDate, endDate, group, user } = filters as Filters;
  console.log(user, "user id from details");
  useEffect(() => {
    getMaliciousActivitiesURLDetails(
      domain,
      startDate,
      endDate,
      user,
      group
    ).then((res) => {
      setDetails(res.data.data);
      setLoading(false);
    });
  }, [domain, startDate, endDate, user, group]);
  const columns = [
    { name: "Device Name", attr: "computerName" },
    { name: "URL Detected Time", attr: "createdAt" },
  ];

  const rowStrings = {
    key: "computerName",
    items: details.map((detail: any) => [detail.computerName]),
  };

  let excelData: any = [];
  details.forEach((i: any) => {
    const excelObject = {
      deviceName: i.computerName,
      createdAt: moment(i.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    };
    excelData.push(excelObject);
  });
  const rows = (array: urlInfo[]) => {
    return array.map((details, index) => {
      return (
        <tr key={index}>
          <td>{details.computerName}</td>
          <td>{moment(details.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
        </tr>
      );
    });
  };

  const handelCloseModal = () => {
    setVisibility(false);
    setLoading(true);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Modal
          className={`${
            visibility ? "popUpModal details-modal-opened" : "popUpModal"
          }`}
          keyboard={false}
          centered={true}
          scrollable={true}
          onHide={() => handelCloseModal()}
          show={visibility}
          size="lg"
        >
          <div className="modal-content">
            <Modal.Header>
              <Modal.Title>{modalTitle}</Modal.Title>
              <CloseButton onClick={() => handelCloseModal()} />
            </Modal.Header>
            <Modal.Body>
              <Table
                pageTitle={"Malicious Activities Details"}
                rows={rows}
                rowsData={details}
                columns={columns}
                rowsStrings={rowStrings}
                excelData={excelData}
              />
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};
