import { CloseButton, Modal } from "react-bootstrap";
import { NoData } from "../NoData";
import { Table } from "../Table";

export const InstalledApplicationsModal = (props: {
  appUsers: any;
  showModal: boolean;
  handleCloseAddModal: () => void;
  currentAppName: string;
}) => {
  const { appUsers, showModal, handleCloseAddModal, currentAppName } = props;

  const columns = [{ name: "Device Name", attr: "computerName" }];
  const rowStrings = {
    key: "computerName",
    items: appUsers.map((detail: any) => [detail.computerName]),
  };
  const rows = (array: Array<any>) => {
    return array.map((site, index) => (
      <tr key={index}>
        <td>{site.computerName}</td>
      </tr>
    ));
  };

  return (
    <Modal
      className={`${
        showModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={() => handleCloseAddModal()}
      show={showModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>{currentAppName}</Modal.Title>
        <CloseButton onClick={handleCloseAddModal} />
      </Modal.Header>

      <Modal.Body>
        {appUsers.length > 0 ? (
          <Table
            pageTitle={"Installed Application Detail"}
            rows={rows}
            rowsData={appUsers.map((el: any) => ({
              computerName: el.computerName,
            }))}
            excelData={appUsers.map((el: any) => ({
              computerName: el.computerName,
            }))}
            columns={columns}
            rowsStrings={rowStrings}
          />
        ) : (
          <NoData />
        )}
      </Modal.Body>
    </Modal>
  );
};
