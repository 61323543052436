import { useState } from "react";
import { GetApplicationsUsageResponse } from "../../client/AuthedClient";
import { Table } from "../Table";
import { getApplicationUsage } from "./../../services/ApplicationsUsageService";
import { useFilters } from "../../context/FiltersContext";
import { ToastFunction } from "../../utils/ToastFunction";
import { ApplicationsUsageModal } from "./ApplicationsUsageModal";
import { DetailsIcon } from "../Icons";
export const ApplicationsUsageTable = (props: {
  data: GetApplicationsUsageResponse;
}) => {
  const { data } = props;
  const filters = useFilters();
  const { user, startDate, endDate } = filters;
  const [details, setDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleModalData = async (appName: string) => {
    const encodePlusChar = (appName: string) => {
      if (appName.includes("+")) {
        return appName.replace(/\+/g, "%2B");
      } else {
        return appName;
      }
    };
    const encodedAppName = encodePlusChar(appName);
    try {
      const res = await getApplicationUsage(startDate, endDate, encodedAppName);
      const data = res.data.data;
      if (data.length > 0) {
        setShowModal(true);
        setDetails(data);
      } else ToastFunction("No Data Available.");
    } catch (error) {
      ToastFunction("No Data Available.");
    }
  };
  const tableColumns: any = [
    {
      name: "Application Name",
      attr: "processName",
    },
    {
      name: "Usage %",
      attr: "percentageCalculation",
    },
    {
      name: "Number of Devices",
      attr: "computersCount",
    },
    { name: "Details", attr: "", style: { width: "80px" } },
  ];
  user && delete tableColumns[2];

  const rows = (array: Array<any>) => {
    return array.map((application, index) => {
      return (
        <tr key={index}>
          <td>{application.processName}</td>
          <td>{application.percentageCalculation}</td>
          {!user && <td>{application.computersCount}</td>}
          <td>
            <DetailsIcon
              handleOpenModal={() => {
                handleModalData(application.processName);
                setModalTitle(application.processName);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  const rowStrings = {
    key: "processName",
    items: data.map((processName: any) => [processName.processName]),
  };
  const rowDurationInSeconds = data.map(
    (processName: any) => processName.durationInSeconds
  );
  const totalDurationInSeconds = rowDurationInSeconds.reduce(
    (prev, current) => prev + current
  );
  const rowsArray = data.map((el: any) => {
    const calc = (el.durationInSeconds / totalDurationInSeconds) * 100;
    return {
      processName: el.processName,
      percentageCalculation: calc < 0.005 ? calc?.toFixed(3) : calc?.toFixed(2),
      computersCount: el.computersCount,
    };
  });
  return (
    <>
      <Table
        pageTitle={"Application Usage"}
        rows={rows}
        rowsData={rowsArray}
        excelData={rowsArray}
        columns={tableColumns}
        rowsStrings={rowStrings}
      />
      {showModal ? (
        <ApplicationsUsageModal
          showModal={showModal}
          setShowModal={setShowModal}
          appName={modalTitle}
          details={details}
        />
      ) : (
        ""
      )}
    </>
  );
};
