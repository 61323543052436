import { IsShow, MappedAction } from "../../services/RemoteActionsService";
import { MoreIcon } from "../Icons";
import MoreContent from "../common/MoreContent/MoreContent";

type props = {
  action: MappedAction;
  index: number;
  isShowHandler: (key: number) => void;
  isShow: IsShow;
};
const RemoteActionTableRows = (props: props) => {
  return (
    <tr key={props.index}>
      <td>{props.action.actionName}</td>
      <td>{props.action.description}</td>
      <td>{props.action.categoryName}</td>
      <td style={{ position: "relative", zIndex: 9 }}>
        <MoreIcon handleshow={() => props.isShowHandler(props.index)} />
        {props.isShow?.key === props.index ? (
          <MoreContent
            action={{
              id: props.action.id,
              commandCategoryId: props.action.commandCategoryId,
              actionName: props.action.actionName,
              description: props.action.description,
              command: props.action.command,
            }}
            isShowHandler={(key) => props.isShowHandler(key)}
            key={props.index}
            index={props.index}
          />
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

export default RemoteActionTableRows;
