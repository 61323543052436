import { Modal } from "react-bootstrap";
import clock from "./clock.svg";

const ConfigureAlertsConfirmModal = ({
  showConfirmModal,
  monitoringPeriodInHours,
  onBackToConfigurationClicked,
  onSubmetForm,
  handelAfterConfirm,
}: any) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handelAfterConfirm();
    onSubmetForm();
  };

  return (
    <Modal
      className={`${
        showConfirmModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={onBackToConfigurationClicked}
      show={showConfirmModal}
      // size="lg"
    >
      <form onSubmit={handleSubmit} className="modal-content">
        <Modal.Header>
          <Modal.Title>Alert confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="AlertsConfirmModalBody">
            <div>
              <img src={clock} alt="clock" />
            </div>
            <div className="AlertsConfirmModalText">
              The Alert Will Take Effect After {monitoringPeriodInHours} Hours
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "0.625rem 1.5rem" }}>
          <div className="AssignRoleModalFooter">
            <button
              onClick={onBackToConfigurationClicked}
              type="button"
              className="AssignRoleModalCanclebtn"
              data-bs-dismiss="modal"
            >
              Back To Configuration
            </button>
            <button type="submit" className="AssignRoleModalSubmitbtn">
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ConfigureAlertsConfirmModal;
