import {
  GetModalDevicesTabDetailsProcess,
  GetProcessesPerformanceDetailsProcess,
  GetProcessesPerformanceResponseProcess,
} from "../../client/AuthedClient";
import { roundNumber } from "../../utils/Mathimatics";
import { Table } from "../Table";
import { ApplicationPerformanceModal } from "./";
import { useItemFilterContext } from "../../context/ItemFilterContext";
import { DetailsIcon } from "../Icons";
import { ConvertSecondsToHHMMSS } from "../../utils/TimeUtility";
import StateIcon from "../Icons/StateIcon";

export const ApplicationPerformanceTable = (props: {
  processes: Array<GetProcessesPerformanceResponseProcess>;
  showModal: boolean;
  onHide: () => void;
  modalTitle: string;
  details: Array<GetProcessesPerformanceDetailsProcess>;
  devicesDetails: Array<GetModalDevicesTabDetailsProcess>;
  onDetailsClick: (processName: string) => void;
}) => {
  const {
    processes,
    showModal,
    onHide,
    modalTitle,
    details,
    devicesDetails,
    onDetailsClick,
  } = props;
  const { filterByItem } = useItemFilterContext();
  const newArray = processes.filter((filter) => {
    if ((filter.totalDurationInSeconds / 60).toFixed(2) === "0.00") {
      return 0;
    } else {
      return filter;
    }
  });
  const rowStrings = {
    key: "processName",
    items: newArray.map((name) => [name.processName]),
  };
  const tableColumns = filterByItem
    ? [
        {
          name: "Application Name",
          attr: "processName",
          // style: { width: "auto", "min-width": "160px" },
        },
        {
          name: "Duration",
          attr: "Duration",
          style: { width: "160px" },
        },
        {
          name: "Availability %",
          attr: "availability",
          style: { width: "120px" },
        },
        {
          name: "Failure Count",
          attr: "failCount",
          style: { width: "150px" },
        },
        { name: "Details", attr: "", style: { width: "80px" } },
      ]
    : [
        {
          name: "Application Name",
          attr: "processName",
          // style: { width: "auto", "min-width": "160px" },
        },
        {
          name: "Duration",
          attr: "Duration",
          style: { width: "160px" },
        },
        {
          name: "Availability %",
          attr: "availability",
          style: { width: "300px" },
        },
        {
          name: "Failure Count",
          attr: "failCount",
          style: { width: "150px" },
        },
        {
          name: "Number of Devices",
          attr: "computersCount",
          style: { width: "160px" },
        },
        { name: "Details", attr: "", style: { width: "80px" } },
      ];
  const rows = (array: Array<any>) => {
    return array.map((process, index) => {
      return (
        <tr key={index}>
          <td>{process.processName}</td>
          <td>{process.durationInHourse}</td>
          <td>
            <div className="d-flex">
              <div className="">{process.availability}%</div>
              <div
                className={`d-flex state-info ${
                  process.availability < 90 ? "low" : ""
                }`}
              >
                <div>
                  {process.availability < 90 && <StateIcon color="#F04438" />}
                </div>
                <div>{process.availability < 90 && "Low"}</div>
              </div>
            </div>
          </td>
          <td>{process.failCount}</td>
          {!filterByItem && <td>{process.computersCount}</td>}
          <td>
            <DetailsIcon
              handleOpenModal={() => onDetailsClick(array[index].processName)}
            />
          </td>
        </tr>
      );
    });
  };
  const rowsData: any = [];
  newArray.forEach((el) => {
    rowsData.push({
      processName: el.processName,
      durationInHourse: ConvertSecondsToHHMMSS(el.totalDurationInSeconds),
      availability: roundNumber((1 - el.failureCount / el.totalCount) * 100, 2),
      failCount: el.failureCount,
      computersCount: el.computersCount,
    });
  });
  const excelData = newArray.map((el) => {
    if (filterByItem) {
      return {
        processName: el.processName,
        durationInHourse: ConvertSecondsToHHMMSS(el.totalDurationInSeconds),
        availability: ((1 - el.failureCount / el.totalCount) * 100).toFixed(2),
        failCount: el.failureCount,
      };
    } else {
      return {
        processName: el.processName,
        durationInHourse: ConvertSecondsToHHMMSS(el.totalDurationInSeconds),
        availability: ((1 - el.failureCount / el.totalCount) * 100).toFixed(2),
        failCount: el.failureCount,
        computersCount: el.computersCount,
        // failPercentage: calculatePercentage(el),
      };
    }
  });
  return (
    <>
      <Table
        pageTitle={"Applications Performance"}
        rows={rows}
        rowsData={rowsData}
        excelData={excelData}
        columns={tableColumns}
        rowsStrings={rowStrings}
      />
      {showModal ? (
        <ApplicationPerformanceModal
          devicesDetails={devicesDetails}
          showModal={showModal}
          onHide={onHide}
          modalTitle={modalTitle}
          details={details}
        />
      ) : (
        <></>
      )}
    </>
  );
};
