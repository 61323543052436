import styles from "./RemoteAction.module.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Action,
  ActionHistory,
  ExecuteAction,
  MappedActionHistory,
  GetActionsHistory,
} from "../../../../services/RemoteActionPageService";
import { useEffect, useState } from "react";
import { Table, width80pxStyle } from "../../../Table";
import { DetailsIcon } from "../../../Icons";
import { useParams } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import { LoaderSpin } from '../../../Icons'
type ShowModal = {
  data: string;
  status: boolean;
};
type RemoteActionsProps = {
  actionsData: Action[];
  actionsHistoryData: ActionHistory[];
};
export default function RemoteActions({
  actionsData,
  actionsHistoryData,
}: RemoteActionsProps) {
  const { id } = useParams<{ id: string }>();
  const [actionId, setActionId] = useState<string>("");
  const [result, setResult] = useState<null | {
    message: string;
    isSuccess: boolean;
  }>(null);
  const [showModal, setShowModal] = useState<ShowModal | null>(null);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0)



  useEffect(() => {
    GetActionsHistory(id!);
  }, [result, id]);

  // mapping actionName with id
  const mappedActionsHistoryData = actionsHistoryData.map((actionHistory) => {
    const index = actionsData.findIndex(
      (action) => action.id === actionHistory.remoteActionId
    );
    return {
      ...actionHistory,
      actionName: actionsData[index].actionName,
    };
  });

  // HANDEL SELECT REMOTE ACTION
  const handleChange = (event: SelectChangeEvent) => {
    setResult(null);
    setActionId(event.target.value);
  };

  // HANDEL CLOSE MODAL
  const HandelCloseModal = () => {
    setShowModal(null);
  };

  // EXCUTION FUN
  const excuteHandler = async () => {

    setBtnLoading(true);
    const { data } = await ExecuteAction(actionId, id!);
    setResult(data);
    setBtnLoading(false);

  };

  // Get Commands of selected Remote Action
  let commands = actionsData.filter(
    (action) => action.id === Number(actionId)
  )[0]?.command;

  //table columns
  const columns = [

    { name: "Action Name", attr: "actionName" },
    { name: "Time", attr: "time" },
    { name: "Status", attr: "result" },
    { name: "Result", attr: "", style: width80pxStyle },
  ];

  // table rows
  const rows = (array: Array<MappedActionHistory>) => {
    return array.map((ActionHistory, index) => {
      // convert date formula
      const date = new Date(ActionHistory.executionDate);
      return (
        <tr key={index}>
          <td>{ActionHistory.actionName}</td>
          <td>{date.toLocaleString()}</td>
          <td
            style={{
              color: ActionHistory.isSuccess ? "green" : "red",
            }}
          >
            {ActionHistory.isSuccess ? "Successed" : "Failed"}
          </td>

          <td>
            <DetailsIcon
              handleOpenModal={() =>
                setShowModal({
                  data: ActionHistory.result,
                  status: ActionHistory.isSuccess,
                })
              }
            />
          </td>
        </tr>
      );
    });
  };

  // rowString
  const rowStrings = {
    key: "actionName",
    items: mappedActionsHistoryData.map((action) => [action.actionName]),
  };
  // execl data
  const excelData = mappedActionsHistoryData.map(({ actionName, executionDate, isSuccess }) => ({
    actionName,
    executionDate: new Date(executionDate).toLocaleString(),
    status: isSuccess ? 'Succeeded' : 'Failed'
  }));
  const pageTabs = ['Result ', 'Action History']

  // HANDEL TAB CHANGING 
  const tabChangeHandler = (index: number) => {
    setActivePage(index)
  }


  return (
    <>
      <div
        className={`${styles["remote-action"]} m-2 border border-1 border-gray p-4 rounded remote-action `}
      >
        <div>
          {/* select input part  */}
          <p className={styles["select-title"]}>Select Action</p>
          <div className="d-flex w-50 gap-4 ">
            <Box sx={{ minWidth: 267 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Action Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={actionId?.toString()}
                  label="Action Name"
                  onChange={handleChange}
                >
                  {actionsData.map((action) => (
                    <MenuItem key={action.id} value={action.id}>
                      {action.actionName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center"
              disabled={actionId === '' || result !== null}
              onClick={excuteHandler}
              style={{ width: '120px' }}
            >
              {btnLoading ? <LoaderSpin /> : ""}
              Execute
            </button>
          </div>
          <p className={styles["commands"]}>{commands}</p>
        </div>
      </div>
      <div className="tabs ms-1">
        <div className="customTab">
          {/* TABS */}
          <ul className="nav nav-tabs ps-0">
            {pageTabs.map((item, index) => <li className="nav-item" key={index} onClick={() => tabChangeHandler(index)} >
              <span
                className={index === activePage ? "nav-link active" : "nav-link"}>
                {item}
              </span>
            </li>)}

          </ul>
          {
            activePage === 0 ? <>
              {/* results  */}
              <div
                className={`${styles["text-container"]}  ${result?.isSuccess ? styles["success"] : styles["error"]
                  }`}
              >
                <span className={styles["state"]}>
                  {result !== null && result?.isSuccess
                    ? "Action has been sent to the agent.."
                    : result !== null && !result?.isSuccess
                      ? "Something Went Wrong.."
                      : ""}
                </span>
                <br />
                {result?.message}
              </div>
            </> :

              <Table
                excelData={excelData}
                pageTitle={"Remote Actions History"}
                rows={rows}
                rowsData={mappedActionsHistoryData}
                columns={columns}
                rowsStrings={rowStrings}
              />

          }
          <Modal show={!!showModal} onHide={HandelCloseModal} size="lg" centered>
            <Modal.Header>
              <Modal.Title className="fw-bold">Result</Modal.Title>
              <CloseButton onClick={HandelCloseModal} />
            </Modal.Header>
            <Modal.Body className={styles['modal-body']}>
              <p
                className="p-4 pt-0"
                style={{ color: showModal?.status ? "green" : "red" }}
              >
                {showModal?.data.length! !== 2
                  ? <pre>{showModal?.data}</pre>
                  : "There is no Result"}
              </p>
            </Modal.Body>
          </Modal>

        </div>
      </div>
    </>
  );
}
