import http from "../httpService";

const url = "/UrlLookup/GetAllURLs";
http.setAuthorizationToken();

export function GetAllURLs(): Promise<AllUrlsResponse> {
  return http.get(`${url}`);
}

export type AllUrlsResponse = {
  data: {
    data: {
      id: number;
      url: string;
      labelId: number;
    }[];
  };
};
