import { DateTime } from "luxon";
import http from "../httpService";

const url = "UrlDashboard/GetDistinctComputerIdCountByMatchedLabels";
http.setAuthorizationToken();

export function getDistinctComputerIdCountByMatchedLabel(
  fromDate: DateTime,
  toDate: DateTime,
  computerId: number | undefined
) {
  return http.get(
    `${url}?from=${fromDate.toISODate()}&to=${toDate.toISODate()}${
      computerId !== undefined ? `&computerId=${computerId}` : ""
    }`
  );
}
