import Tooltip from "@mui/material/Tooltip";
import "./descriptionTooltip.css";

const DescriptionTooltip = (props: { description: string }) => {
  return (
    <Tooltip title={props.description} placement="top" arrow>
      <div className="descriptionBox">?</div>
    </Tooltip>
  );
};

export default DescriptionTooltip;
