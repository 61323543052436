import { Filters } from "../context/FiltersContext";
import AuthedClient, {
  GetApplicationsAndDevicesComplianceResponse,
} from "../client/AuthedClient";
import { Outlet } from "react-router-dom";
import "../css/ApplicationComplianceStyles.css";
import "../css/ChartContainerStyles.css";
type item = {
  title: string;
  path: string;
};
export const items: Array<item> = [
  {
    title: "Applications",
    path: "/app-compliance/applicationsCompliance",
  },
  {
    title: "Devices",
    path: "/app-compliance/devicesCompliance",
  },
];
export async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<GetApplicationsAndDevicesComplianceResponse> {
  const { group, user } = filters;
  return await authedClient.getApplicationsAndDevicesCompliance(group, user);
}
export function ApplicationsCompliance() {
  return <Outlet />;
}
