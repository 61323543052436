import { width80pxStyle } from "../Table";
import { DeleteIcon, EditIcon } from "../Icons";
import "../../css/InstalledApplication.css";
import { DeleteModal } from "../DataEntry/DeleteModal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DomainModal from "./DomainModal";
import { MaliciousTypes } from "../../staticData/securityMonitoring";
import { TableWithFetchOnPagination } from "../TableWithFetchOnPagination";
import { updateMaliciousUrl } from "../../services/configMaliciuosUrl/UpdateMaliciousUrl";
import { deleteMaliciousUrl } from "../../services/configMaliciuosUrl/DeleteMaliciousURL";

import { ToastSuccessFunction, ToastFunction } from "../../utils/ToastFunction";
import { GetAllURLsWithPagination } from "../../services/configMaliciuosUrl/URLsWithPagination";
export type Pagination = {
  setRowsSize: Dispatch<SetStateAction<number>>;
  setPageNumber: Dispatch<SetStateAction<number>>;
};
export type SearchLogic = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};
export type NumberOfRowsLogic = {
  rowsSize: number;
  setRowsSize: Dispatch<SetStateAction<number>>;
};

export type UpdateTableData = {
  updateTableData: boolean;
  setUpdateTableData: Dispatch<SetStateAction<boolean>>;
};
export const DomainsTable = (props: {
  tableData: any;
  paginationLogic: Pagination;
  searchLogic: SearchLogic;
  numberOfRowsLogic: NumberOfRowsLogic;
}) => {
  const { tableData, paginationLogic, searchLogic, numberOfRowsLogic } = props;
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [domainId, setDomainId] = useState<number | null>(null);
  const [currentURl, setCurrentURL] = useState<string | null>(null);
  const [data, setData] = useState(tableData);
  const UpdateData = async () => {
    const data = await GetAllURLsWithPagination(10, 1, "");
    setData(data);
    console.log("data, Updated");
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const colwidth =
    window.innerWidth <= 1372 ? { width: "150px" } : { width: "100px" };

  const columns = [
    { name: "Domain/URL", attr: "url" },
    { name: "Type", attr: "labelId", style: colwidth },

    { name: "Edit", attr: "", style: width80pxStyle },
    { name: "Delete", attr: "", style: width80pxStyle },
  ];
  const DeleteURL = async (domainId: number | null) => {
    try {
      if (domainId) await deleteMaliciousUrl(domainId);
      ToastSuccessFunction("URL Deleted Successfully");
      await UpdateData();

      setShowDeleteModal(false);
    } catch (error) {
      ToastFunction("Deletion Failed,Please Try Again Later");
    }
  };

  const editURLData = () => {
    if (domainId) {
      const url = data.data.data.find((i: any) => i.id === domainId);
      return url;
    }
  };
  const rows = (array: Array<any>) => {
    return array.map((domain, index) => (
      <tr key={index}>
        <td>{domain.url}</td>

        <td>
          {" "}
          <span
            className="rounded-circle text-primary p-2 px-3 font-weight-bold h5"
            style={{ backgroundColor: "#ECEEF9" }}
          >
            {domain.labelId}
          </span>
          
        </td>

        <td>
          <EditIcon
            handleEdit={() => {
              setCurrentURL(domain.url);
              setDomainId(domain.id);
              setEditModalVisibility(true);
            }}
          />
        </td>
        <td>
          <DeleteIcon
            handleDelete={() => {
              setCurrentURL(domain.url);
              setDomainId(domain.id);
              setShowDeleteModal(true);
            }}
          />
        </td>
      </tr>
    ));
  };
  const rowStrings = {
    key: "url",
    items: data.data.data.map((url: any) => [url.url]),
  };
  const rowsData: any = [];

  data.data.data.map((el: any) => {
    const obj = {
      id: el.id,
      url: el.url,
      labelId: MaliciousTypes[el.labelId],
    };
    rowsData.push(obj);
    return 0;
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteButton = (
    <div>
      <button
        onClick={() => {
          DeleteURL(domainId);
        }}
        className="delete-btn"
      >
        Delete
      </button>
    </div>
  );

  return (
    <div className="white-box m-0">
      <TableWithFetchOnPagination
        data={data.data}
        //    getExcelData={getExcelData}
        pageTitle={"Domain Management"}
        rows={rows}
        rowsData={rowsData}
        columns={columns}
        rowsStrings={rowStrings}
        paginationLogic={paginationLogic}
        searchLogic={searchLogic}
        numberOfRowsLogic={numberOfRowsLogic}
        searchValue= {searchLogic.searchValue}

      />

      <br />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={() => setShowDeleteModal(false)}
        currentAppName={currentURl}
        deleteButton={deleteButton}
      />
      {editModalVisibility && (
        <DomainModal
          title="Update Domain/URL"
          visibility={editModalVisibility}
          urlValue={currentURl}
          setVisibility={setEditModalVisibility}
          callBackFunction={updateMaliciousUrl}
          data={editURLData()}
          updateTable={UpdateData}
        />
      )}
    </div>
  );
};
