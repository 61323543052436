import { Page } from "../../components/Page";
import RemoteActionsBody from "../../components/RemoteActions/RemoteActionsBody";
import { RemoteActionsProvider } from "../../components/RemoteActions/RemoteActionsContext";
import { Action, GetActions } from "../../services/RemoteActionsService";

const RemoteActions = () => {
  return (
    <RemoteActionsProvider>
      <Page
        fetchData={fetchData}
        shouldShowNoData={() => false}
        body={(data) => <RemoteActionsBody data={data} />}
        title={{
          mainTitle: "Configuration",
          subTitle: "Remote Actions",
        }}
      />
    </RemoteActionsProvider>
  );
};
const fetchData = async (): Promise<Action[]> => {
  const { data: res } = await GetActions();
  return res.data;
};

export default RemoteActions;
