import { useState } from "react";
import {
  GetItPerformanceResponse,
  GetProcessesItPerformanceResponseProcess,
} from "../../client/AuthedClient";
import { Table } from "../Table";
import { itSummaryCard } from "./";
import { LineChart } from "../Charts";
import { TrendModal } from "./TrendModal";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { DateTime } from "luxon";
import { useFilters } from "../../context/FiltersContext";
import React from "react";
import { DetailsIcon } from "../Icons";
import { roundNumber } from "../../utils/Mathimatics";
export const DiskPerformanceTab = (props: {
  data: GetItPerformanceResponse;
  lineData: any;
  // intialPage: number | undefined | null;
}) => {
  const { data, lineData } = props;
  const filters = useFilters();
  const { startDate, endDate, user } = filters;
  const authedClient = useAuthedClient();
  const [date, setDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [lineDetails, setLineDetails] = useState([]);
  const [showTrendModal, setShowTrendModal] = useState(false);
  const [currentAppName, setCurrentAppName] = useState("");
  const handleCloseTrendModal = () => {
    setDate(startDate);
    setToDate(endDate);
    setShowTrendModal(false);
  };
  const handleShowTrendModal = (appName: string) => {
    setCurrentAppName(appName);
    setShowTrendModal(true);
  };
  async function getTrendLineDetails(
    start: DateTime | any,
    end: DateTime | any,
    id: string
  ) {
    await authedClient
      .getDetailedLineChartData(start, end, id)
      .then((res: any) => {
        let objdata: any = [];
        let avgData: any = [];
        if (res) {
          res.data.data.map((el: any) => {
            objdata = [
              ...objdata,
              { xValue: el.xAxisValue, yValue: el.averageDiskInMb },
            ];
            avgData.push(res.data.averageDiskInMbForLastPeriod);
            return 0;
          });
          let obj: any = {
            labelFormat: res.data.timeLabel,
            data: objdata,
            avg: avgData,
          };
          setLineDetails(obj);
        }
      });
  }
  const columns = [
    { name: "Application Name", attr: "processName" },
    {
      name: "Avg Usage (MB/s)",
      attr: "averageDiskInMb",
    },
    { name: "Max Usage (MB/s)", attr: "maxDiskInMb" },
    { name: "Number of devices", attr: "computersCount" },
    { name: "Trend", attr: "", style: { width: "70px" } },
  ];
  user && delete columns[3];

  const rowStrings = {
    key: "processName",
    items: data.map((application) => [application.processName]),
  };
  const deskData: { TimeLabel: string; data: Array<{}> } = {
    TimeLabel: lineData.data.timeLabel,
    data: [],
  };
  lineData.data.data.length !== 0 &&
    lineData.data.data.map((el: any) => {
      let sampleDiskObj = {
        xValue: el.xAxisValue,
        yValue: el.averageDiskInMb,
      };
      deskData.data.push(sampleDiskObj);
      return 0;
    });
  const rows = (array: Array<GetProcessesItPerformanceResponseProcess>) => {
    return array.map((application, index) => (
      <tr key={index}>
        <td>{application.processName}</td>
        <td>{application.averageDiskInMb}</td>
        <td>{application.maxDiskInMb}</td>
        {!user && <td>{application.computersCount}</td>}
        <td>
          <DetailsIcon
            handleOpenModal={() => {
              getTrendLineDetails(
                startDate,
                endDate,
                application.processName
              ).then(() => {
                handleShowTrendModal(application.processName);
              });
            }}
          />
        </td>
      </tr>
    ));
  };

  const rowsData: any = [];
  const excelData: any = [];

  data.forEach((el) => {
    const obj = {
      processName: el.processName,
      averageDiskInMb: roundNumber(el.averageDiskInMb, 8),
      maxDiskInMb: roundNumber(el.maxDiskInMb, 8),
      computersCount: el.computersCount,
    };
    rowsData.push(obj);
    excelData.push(obj);
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="it-performance-chart-box it-performance-container">
            {itSummaryCard(data, "averageDiskInMb", "Avg Usage (MB/s)")}
          </div>
        </div>
        <div className="col-xl-12">
          <div className="it-performance-chart-box it-performance-container">
            <LineChart
              max={undefined}
              data={deskData}
              title="Avg Usage (MB/s)"
              xAxisTitle={`Time in ${lineData.data.timeLabel}s`}
              yAxisTitle=""
              yAxisTickAmount={5}
              fixed={5}
            />
          </div>
        </div>
        <div className="col-xl-12">
          <div className="it-performance-container">
            <Table
              pageTitle={"Disk Performance"}
              rows={rows}
              // intialPage={intialPage}
              rowsData={rowsData}
              excelData={excelData}
              columns={columns}
              rowsStrings={rowStrings}
            />
          </div>
        </div>
      </div>

      {showTrendModal ? (
        <TrendModal
          getTrendLineDetails={getTrendLineDetails}
          data={lineDetails}
          title="Avg Usage %"
          showTrendModal={showTrendModal}
          handleCloseTrendModal={handleCloseTrendModal}
          currentAppName={currentAppName}
          date={date}
          setDate={setDate}
          toDate={toDate}
          setToDate={setToDate}
          fixedamount={4}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
