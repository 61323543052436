import { CloseButton, Modal } from "react-bootstrap";
import { ToastSuccessFunction } from "../../utils/ToastFunction";
import isEmail from "validator/lib/isEmail";
import { useState } from "react";
import isMobilePhone from "validator/lib/isMobilePhone";

export const EditUserModal = (props: {
  showEditModal: boolean;
  handleCloseEditModal: () => void;
  currentUser: string;
  currentTitle: string;
  currentFullName: string;
  currentPhoneNumber: string;
  currentEmail: string;
  setCurrentTitle: (title: string) => void;
  setCurrentFullName: (fullName: string) => void;
  setCurrentPhoneNumber: (phoneNumber: string) => void;
  setCurrentEmail: (email: string) => void;
  userId: string;
  updateUserAdmin: (
    id: string,
    fullName: string,
    title: string,
    phoneNumber: string,
    email: string
  ) => void;
}) => {
  const {
    showEditModal,
    handleCloseEditModal,
    currentUser,
    currentTitle,
    currentFullName,
    currentPhoneNumber,
    currentEmail,
    setCurrentTitle,
    setCurrentFullName,
    setCurrentPhoneNumber,
    setCurrentEmail,
    userId,
    updateUserAdmin,
  } = props;
  const [titleError, setTitleError] = useState({ show: false, msg: "" });
  const [nameError, setNameError] = useState({ show: false, msg: "" });
  const [emailError, setEmailError] = useState({ show: false, msg: "" });
  const [phoneError, setPhoneError] = useState({ show: false, msg: "" });
  const handleSubmit = (
    userName: string,
    id: string,
    fullName: string,
    title: string,
    phoneNumber: string,
    email: string
  ) => {
    if (
      isEmail(email) &&
      (phoneNumber === "" || isMobilePhone(phoneNumber, "ar-EG")) &&
      currentTitle !== "" &&
      currentFullName !== ""
    ) {
      updateUserAdmin(id, fullName, title, phoneNumber, email);
      setEmailError({ show: false, msg: "" });
      setPhoneError({ show: false, msg: "" });
      setTitleError({ show: false, msg: "" });
      setNameError({ show: false, msg: "" });
      handleCloseEditModal();
      ToastSuccessFunction(`Success Editing ${userName}'s Information.`);
    } else {
      if (email === "") {
        setEmailError({ show: true, msg: "Required" });
      } else if (!isEmail(email)) {
        setEmailError({ show: true, msg: "Invalid Email Format" });
      }
      if (currentTitle === "") {
        setTitleError({ show: true, msg: "Required" });
      }
      if (currentFullName === "") {
        setNameError({ show: true, msg: "Required" });
      }

      if (!isMobilePhone(phoneNumber, "ar-EG") && phoneNumber !== "") {
        setPhoneError({ show: true, msg: "Invalid Phone Number" });
      }
    }
  };

  function handleClose() {
    setEmailError({ show: false, msg: "" });
    setPhoneError({ show: false, msg: "" });
    setTitleError({ show: false, msg: "" });
    setNameError({ show: false, msg: "" });
    handleCloseEditModal();
  }

  return (
    <Modal
      className={`${
        showEditModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleClose}
      show={showEditModal}
      size="lg"
    >
      <form onSubmit={(e) => e.preventDefault()} className="modal-content">
        <Modal.Header>
          <Modal.Title>Edit User {currentUser}</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>

        <Modal.Body>
          <div className="row g-3">
            <div className="col-lg-6">
              <label className="form-label">Username</label>
              <input
                type="text"
                className="form-control"
                value={currentUser}
                disabled
                minLength={10}
                maxLength={30}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                Email <span className="error-span">*</span>
              </label>
              <input
                type="email"
                className={`form-control ${
                  emailError.show ? "error-input" : ""
                }`}
                placeholder="Edit Email"
                onChange={(e) => {
                  setCurrentEmail(e.target.value);
                  setEmailError({ show: false, msg: "" });
                }}
                value={currentEmail}
              />
              {emailError.show && (
                <span className="error-span">{emailError.msg}</span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                Title <span className="error-span">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  titleError.show ? "error-input" : ""
                }`}
                placeholder="Edit Title"
                onChange={(e) => {
                  setCurrentTitle(e.target.value);
                  setTitleError({ show: false, msg: "" });
                }}
                value={currentTitle}
                minLength={5}
                maxLength={30}
              />
              {titleError.show && (
                <span className="error-span">{titleError.msg}</span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                Full Name <span className="error-span">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  nameError.show ? "error-input" : ""
                }`}
                placeholder="Edit Full Name"
                onChange={(e) => {
                  setCurrentFullName(e.target.value);
                  setNameError({ show: false, msg: "" });
                }}
                value={currentFullName}
                minLength={5}
                maxLength={30}
              />
              {nameError.show && (
                <span className="error-span">{nameError.msg}</span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">Phone Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Edit Phone Number"
                onChange={(e) => {
                  setCurrentPhoneNumber(e.target.value);
                  setPhoneError({ show: false, msg: "" });
                }}
                value={currentPhoneNumber}
                onKeyDown={(e) =>
                  (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
                }
              />
              {phoneError.show && (
                <span className="error-span">{phoneError.msg}</span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          
          <button
            onClick={() => {
              handleClose();
            }}
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() =>
              handleSubmit(
                currentUser,
                userId,
                currentFullName,
                currentTitle,
                currentPhoneNumber,
                currentEmail
              )
            }
          >
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
