import AgentAvilabilityChartContainerRightBox from "./AgentAvilabilityChartContainerRightBox";
import ChartContainer from "./ChartContainer";
export function RadialBarChart(props: {
  percentage: number;
  totalDevices: number;
  activeDevices: number;
  inActiveDevices: number;
}) {
  const { percentage, totalDevices, activeDevices, inActiveDevices } = props;
  const percent = Number(percentage.toFixed(2));
  return (
    <div className="globalSpaceBetweenContainers">
      <div className="ChartMainConatiner">
        <div className="ChartHeading">
          <h3>Monitored Devices</h3>
        </div>
        <div className="Chart-paragraph">
          <p className="mb-0">Agent Availability Percentage</p>
        </div>
        <div className="ChartVectorConatiner">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-md-start align-items-center justify-content-center">
                <ChartContainer percent={percent} />
              </div>
            </div>
            <div className="col-sm-12 col-lg-8 col-md-8">
              <AgentAvilabilityChartContainerRightBox
                totalDevices={totalDevices}
                activeDevices={activeDevices}
                inActiveDevices={inActiveDevices}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RadialBarChart;
