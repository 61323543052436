import { DateTime } from "luxon";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { useState } from "react";
import { ToastFunction } from "../../utils/ToastFunction";
import { ConvertToUTC } from "../../utils/Date-Time-Util/DateConverter";
export const TrendDateFilter = (props: {
  currentAppName: string;
  getTrendLineDetails?: any;
  date: any;
  setDate: any;
  toDate: any;
  setToDate: any;
}) => {
  const {
    currentAppName,
    getTrendLineDetails,
    date,
    setDate,
    toDate,
    setToDate,
  } = props;

  const [showFromPicker, setShowFromPicker] = useState(false);
  const [showToPicker, setShowToPicker] = useState(false);

  const handleDateChange = (date: any) => setDate(DateTime.fromJSDate(date));

  const handleToDateChange = (date: any) =>
    setToDate(DateTime.fromJSDate(date));
  const handleRequestDate = () => {
    const startDate = moment(new Date(date), "YYYY-MM-DD");
    const endDate = moment(new Date(toDate), "YYYY-MM-DD");
    if (startDate.isAfter(endDate)) {
      ToastFunction("Start date cannot be greater than end date.");
    } else {
      getTrendLineDetails(
        ConvertToUTC(startDate.format("YYYY-MM-DD")),
        ConvertToUTC(endDate.format("YYYY-MM-DD")),
        currentAppName
      );
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            InputProps={{ readOnly: true }}
            onClick={() => setShowFromPicker(true)}
            onClose={() => setShowFromPicker(false)}
            open={showFromPicker}
            autoOk
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd"
            margin="normal"
            id="date-picker-inline"
            label="From"
            value={date}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            InputProps={{ readOnly: true }}
            onClick={() => setShowToPicker(true)}
            onClose={() => setShowToPicker(false)}
            open={showToPicker}
            autoOk
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd"
            margin="normal"
            id="date-picker-inline"
            label="To"
            value={toDate}
            onChange={handleToDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div style={{ margin: "1rem auto 0rem" }}>
        <button
          style={{
            width: "100px",
            borderRadius: "0.5rem",
            padding: "0.5rem 1rem",
            backgroundColor: "#4049b5",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={handleRequestDate}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

// import { DateTime } from "luxon";
// import { useEffect, useRef, useState } from "react";
// import Datepicker, { ReactDatePicker } from "react-datepicker";

// export const TrendDateFilter = (props: {
//   currentAppName: string;
//   disabled: boolean;
//   date: DateTime | undefined;
//   setDate: any;
//   toDate: DateTime | undefined;
//   setToDate: any;
//   getTrendLineDetails?: any;
// }) => {
//   const {
//     currentAppName,
//     date,
//     setDate,
//     toDate,
//     setToDate,
//     getTrendLineDetails,
//   } = props;

//   const datePicker = useRef<ReactDatePicker | null>(null);
//   const datePickerTwo = useRef<ReactDatePicker | null>(null);

//   const { disabled } = props;

//   const [calender, setCalender] = useState(true);
//   const [calenderTwo, setCalenderTwo] = useState(true);

//   useEffect(() => {
//     if (calender) {
//       datePicker.current?.setOpen(false);
//     } else {
//       datePicker.current?.setOpen(true);
//     }
//   }, [calender]);

//   useEffect(() => {
//     if (calenderTwo) {
//       datePickerTwo.current?.setOpen(false);
//     } else {
//       datePickerTwo.current?.setOpen(true);
//     }
//   }, [calenderTwo]);

//   return (
//     <div className="page-title d-xl-flex trendmodalheader">
//       <div className="topFilter row g-2">
//         <button
//           className="col-auto time-date-picker trendDatePicker"
//           style={disabled ? { pointerEvents: "none" } : {}}
//         >
//           <i
//             className="icon-calendar-empty"
//             onClick={() => setCalender(!calender)}
//           />
//           <Datepicker
//             customInput={
//               <div>{`${date?.toJSDate().toLocaleDateString()}`}</div>
//             }
//             ref={datePicker}
//             selected={date?.toJSDate()}
//             onChange={(e: Date) => setDate(DateTime.fromJSDate(e))}
//             onChangeRaw={(e) => e.preventDefault()}
//             onInputClick={() => setCalender(!calender)}
//           />
//           <i
//             className="icon-down-open-mini"
//             onClick={() => setCalender(!calender)}
//           />
//         </button>
//         <button
//           className="col-auto time-date-picker trendDatePicker"
//           style={disabled ? { pointerEvents: "none" } : {}}
//         >
//           <i
//             className="icon-calendar-empty"
//             onClick={() => setCalenderTwo(!calenderTwo)}
//           />
//           <Datepicker
//             customInput={
//               <div>{`${toDate?.toJSDate().toLocaleDateString()}`}</div>
//             }
//             ref={datePickerTwo}
//             selected={toDate?.toJSDate()}
//             onChange={(e: Date) => setToDate(DateTime.fromJSDate(e))}
//             onChangeRaw={(e) => e.preventDefault()}
//             onInputClick={() => setCalenderTwo(!calenderTwo)}
//           />
//           <i
//             className="icon-down-open-mini"
//             onClick={() => setCalenderTwo(!calenderTwo)}
//           />
//         </button>
//         <button
//           className="trend-filter-apply-button"
//           onClick={() => {
//             getTrendLineDetails(date, toDate, currentAppName);
//           }}
//         >
//           Apply
//         </button>
//       </div>
//     </div>
//   );
// };
