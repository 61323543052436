import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { Button, CloseButton, Form, Modal } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useAuthedClient } from "../context/AuthedClientContext";
import { ToastFunction, ToastSuccessFunction } from "../utils/ToastFunction";
import {
  containLetters,
  controlTextLength,
  removeLetters,
} from "../utils/validation/formValidation";
type FeedBackFormProps = {
  feedBackModalVisibility: boolean;
  setfeedBackModalVisibility: Dispatch<SetStateAction<boolean>>;
};

export default function FeedBackForm(props: FeedBackFormProps) {
  const authedClient = useAuthedClient();

  const { feedBackModalVisibility, setfeedBackModalVisibility } = props;
  const [currentUser, setCurrentUser] = useState<{
    email: string;
    fullName: string;
  } | null>();

  const [subjectValidation, setSubjectValidation] = useState<boolean>(false);
  const [descriptionValidation, setDescriptionValidation] =
    useState<boolean>(false);
  const [typeValue, setTypeValue] = useState<string | null>(null);

  const formRef = useRef<null | HTMLFormElement>(null);
  const [subjectValue, setSubjectValue] = useState<string>("");
  const [descriptionValue, setDescriptionValue] = useState<string>("");

  const getAllUsersAdmins = useCallback(async () => {
    await authedClient
      .getCurrentProfileSummary()
      .then((response) => setCurrentUser(response.data));
  }, [authedClient]);

  useEffect(() => {
    getAllUsersAdmins();
  }, [getAllUsersAdmins]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubjectValue(() => removeLetters(subjectValue, "<>//"));
    const subjectContainLetters = containLetters(subjectValue, 3, 50);
    const subjectLengthValidation = controlTextLength(subjectValue, 3, 60);
    const descriptionLengthValidation = controlTextLength(
      descriptionValue,
      10,
      2000
    );
    try {
      const form = event.currentTarget;

      if (
        form.checkValidity() === false ||
        !(subjectContainLetters && subjectLengthValidation) ||
        !descriptionLengthValidation
      ) {
        event.stopPropagation();
        setSubjectValidation(
          !(subjectContainLetters && subjectLengthValidation)
        );
        setDescriptionValidation(!descriptionLengthValidation);
      } else {
        //this line to remove special characters that is miss-interpreted in the EmailJS service
        formRef.current!.subject.value = removeLetters(subjectValue, "<>//");

        emailjs
          .sendForm(
            //@ts-ignore
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            formRef.current,
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then(
            () => {
              handelCloseModal();
              ToastSuccessFunction("Feedback has been submitted successfully");
            },
            () => {
              ToastFunction("Feedback submission failed");
            }
          );
      }
    } catch (error) {
      ToastFunction("Feedback submission failed");
    }
  };

  const handelCloseModal = () => {
    setfeedBackModalVisibility(false);
    setSubjectValidation(false);
    setDescriptionValidation(false);
    setSubjectValue("");
    setTypeValue(null);
  };

  const handelCheckbox = (value: string) => {
    if (typeValue === value) setTypeValue(null);
    else setTypeValue(value);
  };
  return (
    <Modal show={feedBackModalVisibility} fullscreen={"sm-down"} size="lg">
      <Modal.Header>
        <Modal.Title className="fw-bold">Feedback Form</Modal.Title>
        <CloseButton onClick={handelCloseModal} />
      </Modal.Header>
      <p className="p-4 pt-0 ">
        We would love to hear your thoughts, suggestions, concerns or problems
        with anything that would help us improve!
      </p>
      <Modal.Body>
        <Form
          ref={formRef}
          noValidate
          className="p-4 pt-0"
          onSubmit={handleSubmit}
        >
          <div className="d-none">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                readOnly
                size="lg"
                type="text"
                name="user_name"
                value={currentUser?.fullName}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                readOnly
                type="email"
                name="email"
                value={currentUser?.email}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Feedback Type </Form.Label>
            <div className="d-flex gap-4">
              <Form.Check
                onChange={() => handelCheckbox(`Enhancements`)}
                isValid={false}
                type={"checkbox"}
                label={`Enhancements`}
                id={`feedBack-type`}
                name="type"
                value={`Type: Enhancements`}
                checked={`Enhancements` === typeValue}
              />
              <Form.Check
                onChange={() => handelCheckbox(`Problems`)}
                isValid={false}
                type={"checkbox"}
                label={`Problems`}
                id={`feedBack-type`}
                name="type"
                value={`Type: Problems`}
                checked={`Problems` === typeValue}
              />
              <Form.Check
                onChange={() => handelCheckbox(`Others`)}
                isValid={false}
                type={"checkbox"}
                label={`Others`}
                id={`feedBack-type`}
                name="type"
                value={`Type: Others`}
                checked={`Others` === typeValue}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Subject
              <span className="error-span">*</span>
            </Form.Label>
            <Form.Control
              isInvalid={subjectValidation}
              required
              type="text"
              value={subjectValue}
              onChange={(e) => {
                setSubjectValue(e.target.value);
                setSubjectValidation(false);
              }}
              placeholder="Subject"
              name="subject"
              min={"10"}
            />

            <Form.Control.Feedback type="invalid">
              {subjectValue.length === 0
                ? "Required"
                : "Subject must contain at least 3 alphabet characters and at max 50 alphabet characters long"}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Describe Your Feedback:
              <span className="error-span">*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setDescriptionValue(e.target.value);
                setDescriptionValidation(false);
              }}
              as="textarea"
              rows={6}
              placeholder="Description"
              name="message"
              isInvalid={descriptionValidation}
            />
            <Form.Control.Feedback type="invalid">
              {descriptionValue.length === 0
                ? "Required"
                : "Description must contain at least 10 characters and at max 2000 characters long"}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer className="mt-2">
            <Button type="submit" className="btn btn-primary">
              Submit
            </Button>
            <Button className="btn btn-secondary" onClick={handelCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
