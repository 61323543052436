import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Modal, CloseButton, Dropdown } from "react-bootstrap";
import { GetUsersWithoutRoles } from "../../services/Roles";
import styled from "styled-components";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";

const CloseIcon = styled(CloseOutline)``;

const AssignRoleModal = ({
  user,
  setUser,
  editMode,
  showAssignRoleModal,
  allRoles,
  handleCloseAssignRoleModal,
  onSubmetForm,
}: any) => {
  const [usersWithoutRoles, setUsersWithoutRoles] = useState([]);
  const searchInputEl = useRef<HTMLInputElement | null>(null);

  function focus(
    nextShow: boolean,
    el: MutableRefObject<HTMLInputElement | null>
  ) {
    if (nextShow) {
      setTimeout(() => el.current?.focus());
    }
  }

  const onChooseUser = (id: string) => {
    const targetUser: any = usersWithoutRoles.find(
      (user: any) => user.id === id
    );
    setUser((prevUser: any) => ({
      ...prevUser,
      id: targetUser.id,
      username: targetUser.username,
      roles: [], // Clear roles when selecting a new user
    }));
  };

  const onChooseRole = ({ target: { value } }: any) => {
    setUser((prevUser: any) => ({
      ...prevUser,
      roles: [...prevUser.roles, value],
    }));
  };

  useEffect(() => {
    const callGetUsersWithoutRoles = async () => {
      try {
        const users = await GetUsersWithoutRoles();
        setUsersWithoutRoles(users);
      } catch (error) {
        console.error("Failed to fetch users without roles:", error);
      }
    };
    callGetUsersWithoutRoles();
  }, []);

  const handleRemoveRole = (roleToRemove: string) => {
    setUser((prevUser: any) => ({
      ...prevUser,
      roles: prevUser.roles.filter((role: string) => role !== roleToRemove),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmetForm();
  };

  return (
    <Modal
      className={`${
        showAssignRoleModal ? "popUpModal details-modal-opened" : "popUpModal"
      }`}
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleCloseAssignRoleModal}
      show={showAssignRoleModal}
      size="lg"
    >
      <form onSubmit={handleSubmit} className="modal-content">
        <Modal.Header>
          <Modal.Title>Add Role</Modal.Title>
          <CloseButton onClick={handleCloseAssignRoleModal} />
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex gap-4" style={{ flexDirection: "column" }}>
            <div>
              <label className="AssignRoleModalLable">
                User<span className="error-span">*</span>
              </label>
              <Dropdown
                style={{ width: "100%" }}
                onSelect={(eventKey: any) => onChooseUser(eventKey)}
                onToggle={(nextShow: any) => {
                  focus(nextShow, searchInputEl);
                }}
              >
                <Dropdown.Toggle
                  variant="light"
                  className="fullWidth"
                  style={editMode ? { pointerEvents: "none" } : {}}
                >
                  {user.username ? user.username : "Choose a User"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-filter-dropdown-menu fullWidth">
                  {usersWithoutRoles.length > 0 ? (
                    usersWithoutRoles.map((option: any, index: number) => (
                      <Dropdown.Item
                        disabled={editMode}
                        href="#"
                        key={index}
                        eventKey={option.id}
                        className={`${
                          option.username === user["username"]
                            ? "page-header-dropdown page-header-dropdown-light-text-active"
                            : "page-header-dropdown"
                        }`}
                      >
                        {option.username}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <span className="Span-No-Data">No Data Found</span>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {/* <select
                name="id"
                aria-controls="dtBasicExample"
                className=" AssignRoleModalSelect"
                style={{ appearance: "auto", cursor: "pointer" }}
                onChange={onChooseUser}
                value={user.id}
              >
                <option value="" disabled>
                  Choose a User
                </option>
                {usersWithoutRoles.length > 0 &&
                  usersWithoutRoles.map((user: any) => (
                    <option
                      key={user.id}
                      value={user.id}
                      style={{ cursor: "pointer" }}
                    >
                      {user.username}
                    </option>
                  ))}
              </select> */}
            </div>
            <div>
              <label className="AssignRoleModalLable">
                Role <span className="error-span">*</span>
              </label>
              <select
                name="roles"
                aria-controls="dtBasicExample"
                className="AssignRoleModalSelect"
                style={{ appearance: "auto", cursor: "pointer" }}
                onChange={onChooseRole}
                value=""
                disabled={!user.id}
              >
                <option className="text-secondary" disabled value="">
                  Choose a Role
                </option>
                {allRoles
                  .filter((role: string) => !user.roles.includes(role))
                  .map((role: string) => (
                    <option
                      key={role}
                      value={role}
                      style={{ cursor: "pointer" }}
                    >
                      {role}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {user.roles.length > 0 && (
            <div className="roles-container">
              {user.roles.map((role: string) => (
                <div className="roleIcon" key={role}>
                  <div className="roleIconText">{role}</div>
                  <div onClick={() => handleRemoveRole(role)}>
                    <CloseIcon className="CloseIcon" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ padding: "0.625rem 1.5rem" }}>
          <div className="AssignRoleModalFooter">
            <button
              onClick={handleCloseAssignRoleModal}
              type="button"
              className="AssignRoleModalCanclebtn"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="AssignRoleModalSubmitbtn"
              disabled={!user.id || user.roles.length === 0}
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AssignRoleModal;
