import { MoreHorizontalOutline } from "@styled-icons/evaicons-outline/MoreHorizontalOutline";

import styled from "styled-components";
const Moreicon = styled(MoreHorizontalOutline)`
  width: 24px;
  height: 24px;
  
  cursor: pointer;
  &:hover {
    color: #4453c5;
;
  }
  &:focus,
  &:active {
    color: #4453c5;
  }
`;
function MoreIcon(props: { handleshow?: () => void }) {
  return <Moreicon onClick={props.handleshow} />;
}
export default MoreIcon;
