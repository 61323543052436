import { IsShow, MappedAction } from "../../services/RemoteActionsService";
import { Table, width35pxStyle } from "../Table";
import { useRemoteActions } from "./RemoteActionsContext";
import RemoteActionTableRows from "./RemoteActionTableRows";
import { useState } from "react";

const RemoteActionsTable = () => {
  const { actions, categories } = useRemoteActions();

  const columns = [
    { name: "Action Name", attr: "name", style: width35pxStyle },
    { name: "Description", attr: "description", style: width35pxStyle },
    { name: "Category", attr: "commandCategoryId", style: width35pxStyle },
    { name: "Actions", style: width35pxStyle, attr: "" },
  ];

  const [isShow, setIsShow] = useState<IsShow>(null);
  const isShowHandler = (key: number) => {
    if (isShow?.key === key) {
      setIsShow(null);
    } else {
      setIsShow({ key: key });
    }
  };

  const rows = (array: MappedAction[]) => {
    return array.map((action, index) => (
      <RemoteActionTableRows
        key={index}
        action={action}
        index={index}
        isShowHandler={(key) => isShowHandler(key)}
        isShow={isShow}
      />
    ));
  };
  const excelData: any = [];
  const rowStrings = {
    key: "actionName",
    items: actions.map((action) => [action.actionName]),
  };

  // mapping between ctegory id and category name  & fill excel data
  const mappedActions = actions.map((item) => {
    const category = categories.find(
      (cat) => cat.id === item.commandCategoryId
    );
    excelData.push({
      name: item.actionName,
      deescription: item.description,
      category: category ? category.category : "Unknown Category",
    });
    return {
      ...item,
      categoryName: category ? category.category : "Unknown Category",
    };
  });
  return (
    <>
      <Table
        pageTitle={"Remote Actions"}
        rows={rows}
        rowsData={mappedActions}
        columns={columns}
        rowsStrings={rowStrings}
        excelData={excelData}
      />
    </>
  );
};

export default RemoteActionsTable;
