import { useSideBarContext } from "../../context/SideBarContext";
import ReactApexChart from "react-apexcharts";

function ChartContainer({ percent }: { percent: number }) {
  const { isClose } = useSideBarContext();
  return (
    <ReactApexChart
      series={[percent]}
      options={{
        colors: ["#4453C5"],
        states: {
          hover: {
            filter: {
              type: "#3D4BB1",
              // value: 0.9,
            },
          },
        },
        chart: {
          height: "228px",
          width: "228px",
          type: "radialBar",
          offsetY: 6,
        },
        plotOptions: {
          radialBar: {
            track: {
              background: "#C5CAED",
            },
            hollow: {
              size: "65%",
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#474747",
                offsetY: 30,
              },
              value: {
                show: true,
                fontSize: "32px",
                fontFamily: "Poppins",
                fontWeight: 600,
                color: "#121212",
                offsetY: -15,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total"],
      }}
      type="radialBar"
      height={!isClose ? "250px" : "270px"}
      width={!isClose ? "250px" : "270px"}
    />
  );
}

export default ChartContainer;
