import "../InfoElement.css";

type Props = {
  title: string;
  description: unknown;
  details?: string;
};
export default function InfoElement({
  title,
  description,
  details = "",
}: Props) {
  return (
    <div className="d-flex infoContainer">
      <div className="infoTitle p-4">{title}</div>
      <div className="p-4 infoBody">
        {description}
        <span style={{ color: "#34B53A", paddingLeft: "1rem" }}>{details}</span>
      </div>
    </div>
  );
}
