import axios from "axios";
import jwt_decode from "jwt-decode";
import { ToastFunction } from "../utils/ToastFunction";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // Unexpected (network down, server down, DB down, bug)
    // - Log them   AND   Display a generic error message
    // Raven.captureException(error);

    // alert("An unexpected error occurred.");
    console.error(error);
    ToastFunction("An unexpected error occurred.");
  }
  return Promise.reject(error);
});

axios.interceptors.request.use(
  (config) => {
    const token = getAuthorizationToken();
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000; // current time in seconds

      if (decodedToken.exp < currentTime) {
        logout();
        return Promise.reject(new Error("Token expired"));
      }

      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function getAuthorizationToken() {
  const user = localStorage.getItem("user");
  const userobj = user !== null && JSON.parse(user);
  return userobj ? `Bearer ${userobj.token}` : undefined;
}

export function setAuthorizationToken() {
  const token = getAuthorizationToken();
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;

    const decodedToken = jwt_decode(token);
    const expirationTime = decodedToken.exp * 1000 - Date.now();

    if (expirationTime > 0) {
      setTimeout(() => {
        logout();
      }, expirationTime);
    } else {
      logout();
    }
  }
}

function logout() {
  localStorage.clear();
  window.location.href = "/login";
}

const methods = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  //   patch: axios.patch,
  delete: axios.delete,
  setAuthorizationToken,
};

export default methods;
