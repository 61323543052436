import { Dispatch, SetStateAction } from "react";

type Props = {
  titles: string[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
};
export default function Tabs(props: Props) {
  const { titles, activeTab, setActiveTab } = props;
  return (
    <div className="customTab ">
      <ul className="nav nav-tabs">
        {titles.map((title, index) => (
          <li key={index} className="nav-item">
            <button
              className={`${
                activeTab === title ? "nav-link active" : "nav-link"
              }`}
              onClick={() => setActiveTab(title)}
            >
              {title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
