import {
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

type ItemFilterContextValue = {
  filterByItem: boolean;
  setfilterByItem: Dispatch<SetStateAction<boolean>>;
};

const ItemFilterContext = createContext<ItemFilterContextValue>({
  filterByItem: false,
  setfilterByItem: () => {},
});

function ItemFilterContextContainer({ children }: PropsWithChildren<{}>) {
  const [filterByItem, setfilterByItem] = useState<boolean>(false);

  return (
    <ItemFilterContext.Provider value={{ filterByItem, setfilterByItem }}>
      {children}
    </ItemFilterContext.Provider>
  );
}
const useItemFilterContext = () => {
  const Itemcontext = useContext(ItemFilterContext);
  if (Itemcontext === undefined) {
    throw Error("there is problem with the item context");
  }
  return Itemcontext;
};
export { useItemFilterContext, ItemFilterContextContainer };
