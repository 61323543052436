import { useState } from "react";
import { GetProcessesPerformanceResponseProcess } from "../../client/AuthedClient";
import GreenArrow from "../Icons/GreenArrow";
import RedArrow from "../Icons/redArrow";
//import RedArrow from "../Icons/RedArrow";

import { ApplicationsPerformanceChartCard } from "./";
import styled from "styled-components";
import { CircleInfo } from "@styled-icons/fa-solid/CircleInfo";

const InfoIcon = styled(CircleInfo)`
  color: gray;
  width: 16px;
  margin-right: 5px;
`;
export const ApplicationPerformanceSummaryCard = (props: {
  topProcesses: Array<GetProcessesPerformanceResponseProcess>;
  lowProcesses: Array<GetProcessesPerformanceResponseProcess>;
  onDetailsClick: (processName: string) => void;
}) => {
  const { lowProcesses, topProcesses, onDetailsClick } = props;
  const [chartProcesses, setChartProcesses] =
    useState<GetProcessesPerformanceResponseProcess[]>(topProcesses);
  const [activeProcesses, setActiveProcesses] =
    useState<string>("topProcesses");

  const processesWidgets = chartProcesses.map((process, index) => {
    return (
      <div
        className="mouse-pointer col-sm-12 col-md-12 col-lg-4 col-xl-4 widget-box-container"
        onClick={() => onDetailsClick(chartProcesses[index].processName)}
        key={index}
      >
        <div className="widget-box">
          <div>
            <ApplicationsPerformanceChartCard
              processName={process.processName}
              computersCount={process.computersCount}
              failureCount={process.failureCount}
              failureComputersCount={process.failureComputersCount}
              totalDurationInSeconds={process.totalDurationInSeconds}
              percentage={process.successCount / process.totalCount}
            />
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="d-flex mt-5">
        <h2
          className="my-auto"
          style={{ color: "#6c757d", marginBottom: "0px", width: "16rem" }}
        >
          <span> {activeProcesses === "topProcesses" ? "Top" : "Lowest"} </span>
          <span style={{ color: "#4650be", fontWeight: "bold" }}>
            {chartProcesses.length}{" "}
          </span>
          <span>Applications:</span>
        </h2>
        <div
          className="d-flex g-4 p-2"
          style={{ backgroundColor: "#ECEEF9", borderRadius: "30px" }}
        >
          <div
            className="py-2 px-4 mouse-pointer"
            onClick={() => {
              setChartProcesses(topProcesses);
              setActiveProcesses("topProcesses");
            }}
            style={{
              backgroundColor:
                activeProcesses === "topProcesses" ? "white" : "",
              borderRadius: "20px",
            }}
          >
            <GreenArrow />
          </div>
          <div
            className="py-2 px-4 mouse-pointer"
            onClick={() => {
              setChartProcesses(lowProcesses);
              setActiveProcesses("lowProcesses");
            }}
            style={{
              backgroundColor:
                activeProcesses === "lowProcesses" ? "white" : "",
              borderRadius: "20px",
            }}
          >
            <RedArrow />
          </div>
        </div>
      </div>
      <div
        className="row g-2"
        style={{ display: "flex", alignItems: "stretch" }}
      >
        {processesWidgets}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "flex-start",
          marginTop: "5px",
        }}
      >
        <InfoIcon />
        <div>
          Note that browsers like chrome or edge are not included in this KPI as
          they are the most used by users
        </div>
      </div>
    </>
  );
};
