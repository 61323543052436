import styled from "styled-components";
import { DocumentBulletList } from "@styled-icons/fluentui-system-filled/DocumentBulletList";
const DocumentBulletListMultipleIcons = styled(DocumentBulletList)`
  color: #4453c5;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: #3d4bb1;
  }
`;
function DetailsIcon(props: { handleOpenModal?: () => void }) {
  return <DocumentBulletListMultipleIcons onClick={props.handleOpenModal} />;
}

export default DetailsIcon;
