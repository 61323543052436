export const containLetters = (
  string: string | undefined,
  minimumLetters: number = 1,
  maxLetters: number = 2000
): boolean => {
  //ths function takes string and number of minimam charater required and return boolean
  if (string === undefined) return false;
  let count = 0;
  const regex = /[a-zA-Z]/g;

  for (let element of string) {
    if (element.match(regex)) count++;
  }

  if (count >= minimumLetters) {
    return count <= maxLetters;
  }
  return false;
};

export const controlTextLength = (
  text: string | undefined,
  min: number = 1,
  max: number = 1000
) => {
  if (text === undefined) return false;
  if (text.length < min) return false;
  if (text.length > max) return false;
  return true;
};

export function removeLetters(
  inputString: string,
  lettersToRemove: string
): string {
  // Create a regular expression pattern with the letters to remove
  const pattern = new RegExp(`[${lettersToRemove}]`, "g");

  // Use the replace method to remove the specified letters from the input string
  const resultString = inputString.replace(pattern, "");

  return resultString;
}
