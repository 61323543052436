import { Table } from "../Table";
import { AgentAvailabilityModal } from "./AgentAvailabilityModal";
import { NoData } from "../NoData";
import { DetailsIcon } from "../Icons";
import { ConvertSecondsToHHMMSS } from "../../utils/TimeUtility";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";

export type deviceInfor = {
  computerId: number;
  computerName: string;
  daysCount: number;
  lastActiveDate: string;
  totalDuration: string;
};
export const AgentAvailabilityTable = (props: {
  tabelData: any;
  showModal: boolean;
  onHide: () => void;
  modalTitle: string;
  deviceId: number | null;
  onDetailsClick: (computerName: string, computerId: number) => void;
}) => {
  const { tabelData, showModal, onHide, modalTitle, deviceId, onDetailsClick } =
    props;
  const colwidth =
    window.innerWidth <= 700 ? { width: "120px" } : { width: "170px" };

  const tableColumns = [
    { name: "Device Name", attr: "computerName", style: colwidth },
    { name: "Days", attr: "daysCount", style: { width: "70px" } },
    {
      name: "Duration",
      attr: "totalDuration",
      style: { width: "160px" },
    },
    {
      name: "Last Active Time",
      attr: "lastActiveDate",
      style: { width: "150px" },
    },
    { name: "Details", attr: "", style: { width: "80px" } },
  ];

  const rows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr key={index}>
        <td>{process.computerName}</td>
        <td>{process.daysCount}</td>
        <td>{process.totalDuration}</td>
        <td>{process.lastActiveDate ? process.lastActiveDate : "-"}</td>
        <td>
          <DetailsIcon
            handleOpenModal={() =>
              onDetailsClick(process.computerName, process.computerId)
            }
          />
        </td>
      </tr>
    ));
  };

  const rowStrings = {
    key: "computerName",
    items: tabelData.map((process: any) => [process.computerName]),
  };

  const activeDevicesData = tabelData.filter(
    (computer: any) => computer.daysCount !== 0
  );

  const rowsData = activeDevicesData.map((el: any) => {
    return {
      computerId: el.computerId,
      computerName: el.computerName,
      daysCount: el.daysCount,
      totalDuration: ConvertSecondsToHHMMSS(el.totalDuration),
      lastActiveDate: ConverToLocalAndFormat(
        el.lastActiveDate,
        "dd/MM/yyyy HH:mm:ss"
      ),
    };
  });
  const excelData = activeDevicesData.map((item: any) => {
    return {
      computerName: item.computerName,
      daysCount: item.daysCount,
      totalDuration: ConvertSecondsToHHMMSS(item.totalDuration),
      lastActiveDate: ConverToLocalAndFormat(
        item.lastActiveDate,
        "dd/MM/yyyy HH:mm:ss"
      ),
    };
  });

  const deviceDetails = rowsData.find(
    (device: deviceInfor) => device.computerId === deviceId
  );

  return (
    <>
      {rowsData.length > 0 ? (
        <div className="globalSpaceBetweenContainers">
          <Table
            pageTitle={"Devices Compliance"}
            rows={rows}
            rowsData={rowsData}
            excelData={excelData}
            columns={tableColumns}
            rowsStrings={rowStrings}
          />
          {showModal ? (
            <AgentAvailabilityModal
              modalData={deviceDetails}
              showModal={showModal}
              onHide={onHide}
              modalTitle={modalTitle}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};
