import { useEffect, useState } from "react";
import "./ConfigureAlertsBody.css";
import ConfigureAlertsTable from "./ConfigureAlertsTable";
import { NoData } from "../NoData";
import ConfigureAlertsModal from "./ConfigureAlertsModal";
import {
  ActivateAlert,
  AddAlert,
  AlertErrorType,
  AlertType,
  DeactivateAlert,
  DeleteAlert,
  GetApplications,
  GetCriterias,
  UpdateAlert,
} from "../../services/AlertService";
import { ToastFunction, ToastSuccessFunction } from "../../utils/ToastFunction";
import ConfigureAlertsConfirmModal from "./ConfigureAlertsConfirmModal";
import DiscardChangesModal from "../common/DiscardChangesModal/DiscardChangesModal";
import { DeleteModal } from "../DataEntry";

const ConfigureAlertsBody = ({ data }: any) => {
  const InitialAlert: AlertType = {
    id: 0,
    applicationName: "",
    criteria: "",
    failureThreshold: 5,
    monitoringPeriodInHours: 6,
    emailRecipients: "",
    percentageOfAffectedUsers: 0,
    isConfActive: true,
  };
  const [alerts, setAlerts] = useState<AlertType[]>(data);
  const [alert, setAlert] = useState<AlertType>(InitialAlert);
  const [errors, setErrors] = useState<AlertErrorType>({});
  const [applications, setApplications] = useState<string[]>([]);
  const [criterias, setCriterias] = useState<string[]>([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const onSubmetForm = async () => {
    if (editMode) {
      await callEditAlert();
    } else {
      await callAddAlert();
    }
    onDiscardClicked();
  };
  const handelAfterConfirm = () => {
    setShowConfirmModal(false);
  };
  const callEditAlert = async () => {
    const originalAlerts = [...alerts];
    const alertsCopy = [...alerts];
    const index = alertsCopy.findIndex((a) => a.id === alert.id);
    if (index >= 0) {
      alertsCopy[index] = { ...alert };
      setAlerts(alertsCopy);
    }
    try {
      await UpdateAlert(alert);
      ToastSuccessFunction("Alert edited successfully");
    } catch (error: any) {
      setAlerts(originalAlerts);
      ToastFunction(error.message);
    }
  };

  const callAddAlert = async () => {
    try {
      const newAlert = await AddAlert(alert);
      const allAlerts = [...alerts];
      allAlerts.push(newAlert);
      setAlerts(allAlerts);
      ToastSuccessFunction("Alert added successfully");
    } catch (error: any) {
      ToastFunction(error.message);
    }
  };

  const onDeleteAlert = async (SelectedAlert: AlertType) => {
    setShowDeleteModal(true);
    setAlert(SelectedAlert);
  };

  const handleDelete = async () => {
    const originalAlerts = [...alerts];
    const newAlerts = alerts.filter((a) => a.id !== alert.id);
    setAlerts(newAlerts);
    handleCloseDeleteModal();
    try {
      const { data: res } = await DeleteAlert(alert.id);
      if (res === null) throw new Error("This alert has already been deleted.");
      ToastSuccessFunction("The alert has been deleted successfully.");
    } catch (ex: any) {
      if (ex.response && ex.response.status === 404) {
        ToastFunction("Alert has not been deleted.");
        setAlerts(originalAlerts);
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setAlert(InitialAlert);
  };

  const onEditAlert = (SelectedAlert: any) => {
    if (validateForm("Edit")) {
      SelectedAlert.criteria = "Failure";
      setAlert(SelectedAlert);
      setEditMode(true);
      setShowAlertModal(true);
    }
  };
  const isAlertModified = (modifiedAlert: AlertType | null) => {
    if (editMode) {
      return JSON.stringify(alert) !== JSON.stringify(modifiedAlert);
    } else {
      return JSON.stringify(alert) !== JSON.stringify(InitialAlert);
    }

  };
  const handleCloseAlertModal = (modifiedAlert: AlertType | null) => {


    if (isAlertModified(modifiedAlert)) {
      setShowDiscardModal(true);
      setErrors({});
    } else onDiscardClicked();
  };



  type Type = "Add" | "Edit";

  function validateForm(type: Type) {

    let checkingAlerts;
    if (type === "Edit") {
      checkingAlerts = alerts.filter((item) => item.id !== alert.id);
    } else {
      checkingAlerts = alerts;
    }

    const existingAlert = checkingAlerts.find(
      (item) =>
        // item.criteria === alert.criteria &&
        item.applicationName === alert.applicationName
    );

    if (existingAlert) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        applicationName:
          "An alert with the same criteria and application exist before",
      }));
      return false;
    }
    return true;
  }

  const onAddAlertClicked = () => {
    const type = editMode ? "Edit" : "Add";
    if (validateForm(type)) {
      setErrors({});
      setShowConfirmModal(true);
      setShowAlertModal(false);
    }
  };

  const onBackToConfigurationClicked = () => {
    setShowConfirmModal(false);
    setShowAlertModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowDiscardModal(true);
  };

  const onDiscardClicked = () => {
    setShowAlertModal(false);
    setShowConfirmModal(false);
    setShowDiscardModal(false);
    setAlert(InitialAlert);
    setEditMode(false);
  };

  const handleChange = ({ currentTarget: input }: any) => {
    let val = input.value;

    if (
      input.name === "failureThreshold" ||
      input.name === "monitoringPeriodInHours"
    ) {

      val = val === '' ? '' : Number(val).toString();
    }
    setAlert((prevState) => ({
      ...prevState,
      [input.name]: val,
    }));
  };

  const onSwitchClicked = async (alert: AlertType) => {
    const originalAlerts = [...alerts];
    const alertsCopy = alerts.map((a) =>
      a.id === alert.id ? { ...a, isConfActive: !alert.isConfActive } : a
    );
    setAlerts(alertsCopy);
    try {
      if (alert.isConfActive) {
        await DeactivateAlert(alert.id);
        ToastSuccessFunction("Alert deactivated successfully");
      } else {
        await ActivateAlert(alert.id);
        ToastSuccessFunction("Alert activated successfully");
      }
    } catch (error) {
      setAlerts(originalAlerts);
      ToastFunction("Error changing alert state");
      throw error;
    }
  };

  useEffect(() => {
    const callGetData = async () => {
      try {
        const criterias: string[] = await GetCriterias();
        setCriterias(criterias);

        const { data: res } = await GetApplications();
        setApplications(res.data);
      } catch (error) {
        ToastFunction("Failed to fetch");
      }
    };
    callGetData();
  }, []);

  const deleteButton = (
    <div>
      <button onClick={() => handleDelete()} className="delete-btn">
        Delete
      </button>
    </div>
  );

  return (
    <>
      <div className="ConfigureAlertsBtnContainer">
        <button onClick={() => setShowAlertModal(true)}><i className="icon-plus-circle" /> New Alert</button>
      </div>
      {alerts?.length > 0 ? (
        <>
          <ConfigureAlertsTable
            alerts={alerts}
            onSwitchClicked={onSwitchClicked}
            onEditAlert={onEditAlert}
            onDeleteAlert={onDeleteAlert}
          />
          {showDeleteModal && (
            <DeleteModal
              showDeleteModal={showDeleteModal}
              handleCloseDeleteModal={handleCloseDeleteModal}
              currentAppName={alert.applicationName + " Alert"}
              deleteButton={deleteButton}
            />
          )}
        </>
      ) : (
        <NoData />
      )}
      {showAlertModal && (
        <ConfigureAlertsModal
          alert={alert}
          errors={errors}
          setErrors={setErrors}
          handleChange={handleChange}
          editMode={editMode}
          applications={applications}
          criterias={criterias}
          showAlertModal={showAlertModal}
          onAddAlertClicked={onAddAlertClicked}
          handleCloseAlertModal={handleCloseAlertModal}
        />
      )}
      {showConfirmModal && (
        <ConfigureAlertsConfirmModal
          showConfirmModal={showConfirmModal}
          monitoringPeriodInHours={alert.monitoringPeriodInHours}
          handleCloseConfirmModal={handleCloseConfirmModal}
          onBackToConfigurationClicked={onBackToConfigurationClicked}
          onSubmetForm={onSubmetForm}
          handelAfterConfirm={handelAfterConfirm}
        />
      )}
      {showDiscardModal && (
        <DiscardChangesModal
          showDiscardModal={showDiscardModal}
          onDiscardClicked={onDiscardClicked}
          handleCloseDiscardModal={() => setShowDiscardModal(false)}
        />
      )}
    </>
  );
};

export default ConfigureAlertsBody;
