import ChartContainer from "./ChartContainer";
import ChartRightContainerInfo from "./ChartRightContainerInfo";
import { useParams } from "react-router-dom";
import { roundNumber } from "../../../utils/Mathimatics";

export function RadialBarChart(props: {
  complied: number;
  notComplied: number;
  text: "Applications" | "Devices";
}) {
  const { complied, notComplied, text } = props;
  const percentage = (roundNumber(complied / (complied + notComplied), 3)*100).toFixed(5);
  const params = useParams();
  const screenParam = params["*"];
  const title =
    screenParam === "applicationsCompliance"
      ? "Applications Compliance Percentage"
      : "Devices Compliance Percentage";
  return (
    <div className="globalSpaceBetweenContainers">
      <div className="ChartMainConatiner">
        <div className="ChartHeading">
          <h3>Compliance Overview</h3>
        </div>
        <div className="Chart-paragraph">
          <p className="mb-0">{title}</p>
        </div>
        <div className="ChartVectorConatiner">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-md-start align-items-center justify-content-center justify-content-md-start">
                <ChartContainer percent={Number(percentage)} />
              </div>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <div className="d-flex justify-content-center align-items-center h-100">
                <ChartRightContainerInfo
                  complied={complied}
                  notComplied={notComplied}
                  text={text}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RadialBarChart;
