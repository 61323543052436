import { ConvertSecondsToHHMMSS } from "../../utils/TimeUtility";
import { Table } from "../Table";

export const VisitedSitesModal = (props: { details: any }) => {
  const { details } = props;

  const columns = [
    { name: "Device Name", attr: "computerName" },
    { name: "Date", attr: "time" },
    { name: "Duration", attr: "durationMin" },
  ];
  const rowStrings = {
    key: "computerName",
    items: details.map((detail: any) => [detail.computerName]),
  };
  const rows = (array: Array<any>) => {
    return array.map((site, index) => (
      <tr key={index}>
        <td>{site.computerName}</td>
        <td>{site.time.substring(0, 10)}</td>
        <td>{site.durationMin}</td>
      </tr>
    ));
  };
  const rowsData = details.map((el: any) => ({
    computerName: el.computerName,
    time: el.time.substring(0, 10),
    durationMin: ConvertSecondsToHHMMSS(el.durationSec),
  }));

  return (
    <Table
      pageTitle={"Visited Sites Details"}
      rows={rows}
      rowsData={rowsData}
      excelData={rowsData}
      columns={columns}
      rowsStrings={rowStrings}
    />
  );
};
