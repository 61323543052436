import { Skeleton } from "@mui/material";
import { Loader } from "rsuite";
function TimeUtilLoader() {
  return (
    <div
      style={{ height: "60vh" }}
      className="d-flex justify-content-center align-items-center w-100"
    >
      <div className="timeUtli-Loader">
        <div className="skeleton">
          <Skeleton
            sx={{ bgcolor: "#E1e5ff" }}
            variant="rectangular"
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: "8px" }}
          />
        </div>
        <div className="loader">
          <Loader className="loader-element" content="loading..." vertical />
        </div>
      </div>
    </div>
  );
}

export default TimeUtilLoader;
