//import "../../../css/ToolTip&LegendStyles.css";
import ReactApexChart from "react-apexcharts";
import { MaliciousTypes } from "../../staticData/securityMonitoring";
import { colorArray } from "../ItPerfromanceComponents/DountChart";
type Props = {
  data: {
    key: string;
    value: number;
  };
};
export default function MaliciousActivitiesDonutChart(data: Props) {
  const maliciousTypes = Object.keys(data.data).map(
    (type: string) => MaliciousTypes[parseInt(type)]
  );
  const seriesValues: (string | number)[] = Object.values(data.data);
  if (seriesValues.length === 0) return <div></div>;
  return (
    <div
      className="py-4 px-2 w-50 my-4 rounded"
      style={{ backgroundColor: "#ECEEF9" }}
    >
      <h3 style={{ fontWeight: "500" }} className="px-2">
        Malicious URL Types
      </h3>

      <ReactApexChart
        options={{
          colors: colorArray,

          labels: maliciousTypes,
          grid: {
            padding: {
              top: 40,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            onItemHover: {
              highlightDataSeries: false,
            },
          },

          dataLabels: {
            enabled: true,
            formatter: (val) => Number(val).toFixed(1) + "%",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "50%",
              },
            },
          },
          // title: {
          //   text: "Malicious URL Types",
          //   style: { fontSize: "1.3em" },
          // },

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  height: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        series={seriesValues as ApexNonAxisChartSeries}
        type={"donut"}
        height={403}
      />
    </div>
  );
}
