import AssignRoleBody from "../../components/AssignRole/AssignRoleBody";
import { Page } from "../../components/Page";
import { GetUsersRoles } from "../../services/Roles";

export const AssignRole = () => {
  return (
    <Page
      fetchData={() => fetchData()}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Users",
        subTitle: "Assign Role",
      }}
    />
  );
};

async function fetchData() {
  return await GetUsersRoles();
}

function shouldShowNoData(): boolean {
  // return data.length === 0;
  return false;
}

function body(data: any) {
  return <AssignRoleBody data={data} />;
}
