import { useEffect, useState } from "react";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { Loader } from "../../components/Loader/Loader";
import {
  ApplicationLicenseTable,
  ApplicationLicenseDetailsModal,
  ApplicationLicenseFormModal,
} from "../../components/ApplicationLicense";
import { DeleteModal } from "../../components/DataEntry";
import { ToastSuccessFunction } from "../../utils/ToastFunction";
import {
  UpdateApplicationLicense,
  InsertApplicationLicense,
} from "./../../services/ApplicationLicenseService";
import { AppData } from "../../components/ApplicationLicense/ApplicationLicenseTable";
import { ConvertLocalDateTimeToUTC } from "../../utils/Date-Time-Util/DateConverter";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";
type State =
  | {
      kind: "loading";
    }
  | {
      kind: "loaded";
      processes: Array<AppData>;
    }
  | { kind: "errored" };
export const ApplicationLicense = () => {
  const ApplicationInitialState = {
    applicationName: "",
    applicationId: "",
    noLicenses: "",
    appVersion: "",
    licenseStartDate: "",
    licenseExpirationDate: "",
  };
  const ApplicationDetailsInitialState = {
    applicationId: "",
    applicationName: "",
    applicationVersion: "",
    details: [],
  };
  const authedClient = useAuthedClient();
  useEffect(() => {
    callGetAppLicense();
    getAppsWithoutLicense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [state, setState] = useState<State>({ kind: "loading" });
  const [applicationDetails, setApplicationDetails] = useState(
    ApplicationDetailsInitialState
  );
  const [data, setData] = useState(ApplicationInitialState);
  const [errors, setErrors] = useState<any>({});
  const [lgShow, setLgShow] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [appsWithoutLicese, setAppsWithoutLicese] = useState<
    Array<{ appName: string; id: number; appVersion: string }>
  >([]);
  function handleResponseReceived(response: Array<AppData>) {
    setState({ kind: "loaded", processes: response });
  }
  function handleErrored() {
    setState({ kind: "errored" });
  }
  function getAppsWithoutLicense() {
    authedClient.getAllowedApps().then((res) => {
      let elemnt: Array<{
        appName: string;
        id: number;
        appVersion: string;
      }> = [];
      // eslint-disable-next-line array-callback-return
      res
        .filter((app) => app.isAllowed === true)
        .forEach((el) => {
          elemnt.push({
            id: el.id,
            appName: el.applicationName,
            appVersion: el.applicationVersion,
          });
        });
      setAppsWithoutLicese(elemnt);
    });
  }
  function callGetAppLicense() {
    authedClient
      .getApplicationLicense()
      .then((res) => {
        handleResponseReceived(res);
      })
      .catch(() => handleErrored());
  }
  function callDeleteApplicationLicense(
    id: number | undefined,
    currentAppName: string,
    currentAppVersion: string
  ) {
    authedClient.deleteApplicationLicense(id).then(() => {
      handleCloseDeleteModal();
      setShowDetailsModal(false);
      ToastSuccessFunction(
        `Success Delete License for ${currentAppName} Version ${
          currentAppVersion ? currentAppVersion : "All"
        }`
      );
      setData(ApplicationInitialState);
      setApplicationDetails(ApplicationDetailsInitialState);
      callGetAppLicense();
      getAppsWithoutLicense();
    });
  }
  async function callAddEditApplicationLicense(
    id: number | undefined,
    editing: boolean,
    noLicenses: number,
    licenseStartDate: any,
    licenseExpirationDate: any
  ) {
    if (editing) {
      try {
        await UpdateApplicationLicense(
          id,
          noLicenses,
          licenseStartDate,
          licenseExpirationDate
        );
      } catch (error) {}
    } else {
      try {
        await InsertApplicationLicense(
          id,
          noLicenses,
          licenseStartDate,
          licenseExpirationDate
        );
      } catch (error) {}
    }
    callGetAppLicense();
    getAppsWithoutLicense();
  }
  const handleAddNew = () => {
    if (appsWithoutLicese.length === 0) {
      setErrors((prevState: any) => ({
        ...prevState,
        list: "Enter a new application in Installed Applications Page",
      }));
    } else delete errors["list"];
    setEditing(false);
    setLgShow(true);
  };
  const handleShowDetails = (app: any) => {
    setApplicationDetails({
      applicationId: app.applicationId,
      applicationName: app.applicationName,
      applicationVersion: app.applicationVersion,
      details: app.details,
    });
    setShowDetailsModal(true);
  };
  const handleEdit = (process: any) => {
    setEditing(true);
    setData({
      applicationName: applicationDetails.applicationName,
      applicationId: process.recordId,
      noLicenses: process.numberOfLicenses,
      appVersion: applicationDetails.applicationVersion,
      licenseStartDate: ConverToLocalAndFormat(
        process.licenseStartDate,
        "yyyy-MM-dd"
      ),
      licenseExpirationDate: ConverToLocalAndFormat(
        process.licenseExpirationDate,
        "yyyy-MM-dd"
      ),
    });
    setLgShow(true);
  };
  const handleChange = ({ currentTarget: input }: any) => {
    // const errorsMessage = validateProperty(input);
    // if (errorsMessage) {
    //   setErrors((prevState: any) => ({
    //     ...prevState,
    //     [input.name]: errorsMessage,
    //   }));
    // } else delete errors[input.name];
    setData((prevState) => ({
      ...prevState,
      [input.name]: input.value,
    }));
  };
  const validate = () => {
    const errors: any = {};
    // Validate Application Name //
    if (data.applicationName === "")
      errors["applicationName"] = `This field is reqiured`;
    else delete errors["applicationName"];
    // Validate Application Version //
    if (data.appVersion === "") errors["appVersion"] = `This field is reqiured`;
    else delete errors["appVersion"];
    // Validate Application Capacity  //
    if (data.noLicenses === "") {
      errors["noLicenses"] = `This field is reqiured`;
    } else if (
      (data.noLicenses !== "" &&
        !Number.isInteger(Number(data.noLicenses)) &&
        Number.isFinite(Number(data.noLicenses))) ||
      Number(data.noLicenses) <= 0
    ) {
      errors["noLicenses"] = `capacity accepts positive whole number`;
    } else delete errors["noLicenses"];
    // Validate Start date
    if (data.licenseStartDate === "")
      errors["licenseStartDate"] = `This field is reqiured`;
    else delete errors["licenseStartDate"];
    /////// Validate Date /////////
    if (data.licenseStartDate !== "") {
      if (data.licenseExpirationDate || data.licenseExpirationDate !== null) {
        const startDate = new Date(data.licenseStartDate).getTime();
        const endDate = new Date(data.licenseExpirationDate).getTime();
        if (startDate >= endDate) {
          errors["licenseStartDate"] = `Start date must be less than end date`;
          errors[
            "licenseExpirationDate"
          ] = `Start date must be less than end date`;
        } else {
          delete errors["licenseStartDate"];
          delete errors["licenseExpirationDate"];
        }
      }
    }
    /////////////////////////////
    if (Object.keys(errors).length === 0) return null;
    else return errors;
  };
  const handleSubmit = () => {
    const allErrors = validate();
    setErrors(allErrors || {});
    if (allErrors) return;
    doSubmit();
  };
  const doSubmit = () => {
    callAddEditApplicationLicense(
      Number(data.applicationId),
      editing,
      Number(data.noLicenses),
      ConvertLocalDateTimeToUTC(data.licenseStartDate),
      ConvertLocalDateTimeToUTC(data.licenseExpirationDate)
    );
    handleCloseModal();
    setShowDetailsModal(false);
    setApplicationDetails(ApplicationDetailsInitialState);
    if (editing)
      ToastSuccessFunction(
        `Success Editing ${data.applicationName}'s Informations`
      );
    else if (!editing)
      ToastSuccessFunction(`Success added ${data.applicationName}`);
  };
  const handleDelete = (
    recordId: number,
    applicationName: string,
    applicationVersion: string
  ) => {
    setData((prevState: any) => ({
      ...prevState,
      applicationId: recordId,
      applicationName: applicationName,
      appVersion: applicationVersion,
    }));
    setShowDeleteModal(true);
  };
  const handleCloseModal = () => {
    setLgShow(false);
    setData(ApplicationInitialState);
    setErrors({});
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const deleteButton = (
    <div>
      <button
        onClick={() =>
          callDeleteApplicationLicense(
            Number(data.applicationId),
            data.applicationName,
            data.appVersion
          )
        }
        className="delete-btn"
        type="submit"
      >
        Delete
      </button>
    </div>
  );
  if (state.kind === "loading") {
    return (
      <div className="page-container">
        {/* <div className="page-title d-xl-flex">
          <h1 className="me-auto">Application License</h1>
        </div> */}
        {/* <div className="page-title">
          <h2 className="me-auto">Configuration</h2>
          <h3 className="me-auto">
            <span>Configuration /</span> Application License
          </h3>
        </div> */}
        <Loader />
      </div>
    );
  } else if (state.kind === "loaded") {
    return (
      <div className="page-container">
        {/* <div className="page-title">
          <h2 className="me-auto">Configuration</h2>
          <h3 className="me-auto">
            <span>Configuration /</span> Application License
          </h3>
        </div> */}
        <ApplicationLicenseTable
          handleShowDetails={handleShowDetails}
          processes={state.processes}
          handleAddNew={handleAddNew}
        />
        {showDetailsModal && (
          <ApplicationLicenseDetailsModal
            applicationDetails={applicationDetails}
            showDetailsModal={showDetailsModal}
            setShowDetailsModal={setShowDetailsModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )}
        <ApplicationLicenseFormModal
          data={data}
          errors={errors}
          appsWithoutLicese={appsWithoutLicese}
          lgShow={lgShow}
          editing={editing}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleCloseModal={handleCloseModal}
        />
        <DeleteModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          currentAppName={data.applicationName}
          deleteButton={deleteButton}
        />
      </div>
    );
  } else {
    return <div>Something wrong happened. Please, contact the developers.</div>;
  }
};
