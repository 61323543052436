import { useState } from "react";
import logo from "../img/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { User } from "@styled-icons/boxicons-solid/User";
import { Eye } from "@styled-icons/open-iconic/Eye";
import { EyeOff } from "@styled-icons/ionicons-solid/EyeOff";
import ImageBg from "../img/background.png";
import Skeleton from "@mui/material/Skeleton";
import "../css/LoginStyles.css";
const UserIcon = styled(User)`
  width: 18px;
  height: 18px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: #9c9c9c;
`;
const EyeIcon = styled(Eye)`
  width: 18px;
  height: 18px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: #9c9c9c;
`;
const EyeIconClosed = styled(EyeOff)`
  width: 18px;
  height: 18px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: #9c9c9c;
`;

type FormData = {
  username: string;
  password: string;
};
const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();
function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(schema),
  });
  const onSubmit = handleSubmit((data: any) => {
    const { username, password } = data;
    auth.login(username, password);
  });
  const [inputType, setInputType] = useState("password");
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const resetAPiErrorMsg = () => {
    auth.setValidationError({ show: false, message: "" });
  };
  const load = (src: any) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", resolve);
      image.addEventListener("error", reject);
      image.src = src;
    });
  };
  load(ImageBg).then(() => {
    setLoading(false);
  });
  return (
    <div className="login">
      <Row className="w-100 g-0 h-100 flex-sm-column flex-lg-row">
        <Col lg={8} sm={12} md={12} className="">
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={"100%"}
            />
          ) : (
            <div className="login-about"></div>
          )}
        </Col>
        <Col sm={12} lg={4} md={12}>
          <div className="login-form">
            <form onSubmit={onSubmit}>
              <div className="w-50 logo-container">
                <img src={logo} alt="logo" />
              </div>
              <div className="sign-In-Text text-center text-lg-start Login-Head-Container">
                <span>Sign in to your account</span>
              </div>
              <div className="login-Error-Container">
                {auth.validationError.show ? (
                  <span className="login-Error">
                    {auth.validationError.message}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-control-container">
                <label className="form-control-label" htmlFor="username">
                  Username
                </label>
                <div className={`mb-1 inputIcon`}>
                  <div className="username-Icon">
                    <UserIcon />
                  </div>
                  <input
                    id="username"
                    type="text"
                    className={`form-control ${
                      auth.validationError.message || errors.username?.message
                        ? "login-error-input"
                        : ""
                    }`}
                    placeholder="Enter Username"
                    {...register("username", { required: true })}
                    onFocus={resetAPiErrorMsg}
                    maxLength={20}
                  />
                </div>
                <p className="error-span">{errors.username?.message}</p>
              </div>
              <div className="form-control-container">
                <label className="form-control-label" htmlFor="password">
                  Password
                </label>
                <div className={`mb-1 inputIcon`}>
                  <input
                    id="password"
                    className={`form-control ${
                      auth.validationError.message || errors.password?.message
                        ? "login-error-input"
                        : ""
                    }`}
                    placeholder="Enter Password"
                    {...register("password", { required: true })}
                    onFocus={resetAPiErrorMsg}
                    type={inputType}
                    maxLength={20}
                  />
                  <div
                    className="Password-Icon"
                    onClick={() =>
                      setInputType(
                        inputType === "password" ? "text" : "password"
                      )
                    }
                  >
                    {inputType === "password" ? <EyeIconClosed /> : <EyeIcon />}
                  </div>
                </div>
                <p className="error-span">{errors.password?.message}</p>
              </div>
              <div className="btn-container">
                <button type="submit" className="btn">
                  Log in
                </button>
              </div>
            </form>
          </div>
          <div className="copyright text-center">
            Copyright © 2022 <span>Nexey</span>.
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Login;
