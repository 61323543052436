import React, { useEffect, useState } from "react";
import LineChartWithAccordionContainer from "../TimeUtilizationCharts/LineChartContainer";
import AccordionWithPieChartContainer from "./AccordionWithPieChartContainer";
import { useTimeUtilizationContext } from "../TimeUtilizationResponseProvider";
function ChartsContainer(data: any) {
  const [activePage, setActivePage] = useState(0);
  const pageTabs = ['Applidcation Usage', "Application Summary"];

  function tabChangeHandler(index: number) {
    setActivePage(index)
  }
  const { setRes } = useTimeUtilizationContext();
  useEffect(() => {
    setRes(data)
  }, [data, setRes]);


  return (
    <React.Fragment>
      <div className="tabs ms-1">
        <div className="customTab">
          {/* TABS */}
          <ul className="nav nav-tabs ps-0">
            {pageTabs.map((item, index) => <li className="nav-item" key={index} onClick={() => tabChangeHandler(index)} >
              <span
                className={index === activePage ? "nav-link active" : "nav-link"}>
                {item}
              </span>
            </li>)}
          </ul>
          {
            activePage === 0 ? <>
              <LineChartWithAccordionContainer />
            </> :
              <AccordionWithPieChartContainer />
          }
        </div>
      </div>


    </React.Fragment>
  );
}
export default ChartsContainer;
