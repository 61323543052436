import InfoElement from "./InfoElement";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function SystemResources({ data }: any) {
  const { id } = useParams();
  const deviceResources = data?.data.find(
    (i: any) => i.computerId === parseInt(id as string)
  );
  return (
    <div>
      {data.data && (
        <div className="d-flex flex-column gap-3 m-2">
          <InfoElement
            title={"RAM"}
            description={`${deviceResources.installedRam} GB `}
            details={` Usable : ${deviceResources.usableRam} `}
          />
          <InfoElement
            title={"CPU"}
            description={`${deviceResources.cpuName} ${deviceResources.computerOsArchitecture},
            (Cores: ${deviceResources.cpuNumberOfCores}), ( Logical Processors: ${deviceResources.cpuNumberOfLogicalProcessors})`}
          />
          <InfoElement
            title={"Disk Capacity"}
            description={deviceResources.diskTotalSpace}
          />
          <InfoElement
            title={"Disk Free Space"}
            description={deviceResources.diskFreeSpace}
          />
          <InfoElement
            title={"Last updated date/time"}
            description={moment(
              deviceResources.timeStamp,
              "YYYY-MM-DD HH:mm"
            ).toString()}
          />
        </div>
      )}
    </div>
  );
}
