import ConfigureAlertsBody from "../../components/ConfigureAlerts/ConfigureAlertsBody";
import { Page } from "../../components/Page";
import { GetAlerts } from "../../services/AlertService";

export const ConfigureAlerts = () => {
  return (
    <Page
      fetchData={() => fetchData()}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Configurate",
        subTitle: "Configure Alerts",
      }}
    />
  );
};
async function fetchData() {
  try {
    const { data: res } = await GetAlerts();
    return res;
  } catch (error) {
    console.error("Error fetching alerts: ", error);
    throw error;
  }
}

function shouldShowNoData(): boolean {
  // return data.length === 0;
  return false;
}

function body(data: any) {
  return <ConfigureAlertsBody data={data.data} />;
}
