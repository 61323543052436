import { ConvertToUTC, ConvertUTCDateTimeToLocal } from "./DateConverter";
import { DateTime } from "luxon";

/**
 * Format a UTC date to the user's local time zone and return it in the specified format.
 * @param UTCDateTimeInput Input date in UTC. It can be a DateTime object, a Date object, or a string.
 * @param format Required date format.
 * @returns Formatted local date time.
 */
export const ConverToLocalAndFormat = (
  UTCDateTimeInput: Date | string,
  format: any
) => {
  try {
    // Convert UTC date to local time
    const localDateTime = ConvertUTCDateTimeToLocal(UTCDateTimeInput);
    // Format the local date using moment with the specified format
    const formattedDate = localDateTime.toFormat(format);
    console.log(formattedDate);
    return formattedDate;
  } catch (error) {
    console.log(error);
    return "Error Invalid Date";
  }
};

/**
 * Format a DateTime object to a string in the desired UTC format.
 * @param dateTimeInput UTC DateTime object.
 * @returns Formatted date time string.
 */
export function formatUTCforAPI(dateTimeInput: DateTime) {
  return dateTimeInput.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
}

/**
 * Format a DateTime object to a string in the desired UTC format.
 * @param dateTimeInput UTC DateTime object. It can be a DateTime object, a Date object, or a string.
 * @param format Required date format.
 * @returns Formatted date time string.
 */
export function ConvertToUTCAndFormat(dateTimeInput: DateTime | Date | string) {
  var DateUTC = ConvertToUTC(dateTimeInput);
  // Format the date-time to the desired shape
  return DateUTC.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
}
