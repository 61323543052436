import { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { useAuthedClient } from "../context/AuthedClientContext";
import { ToastSuccessFunction } from "../utils/ToastFunction";

export const ChangePassword = (props: {
  handleCloseAllModals: () => void;
  handleCloseChangePassword: () => void;
}) => {
  const { handleCloseChangePassword, handleCloseAllModals } = props;

  const [oldPassWord, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldpassError, setoldpassError] = useState({ msg: "", show: false });
  const [newpassError, setNewPassError] = useState({ msg: "", show: false });
  const [confirmPassError, setConfirmPassError] = useState({
    msg: "",
    show: false,
  });
  const [passwordsInputType, setPasswordsInputType] = useState({
    oldPass: "password",
    newPass: "password",
    confirmNewPass: "password",
  });
  const auth = useAuthedClient();
  async function handlesumbit() {
    if (oldPassWord !== "" && newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        if (newPassword.length < 6) {
          setConfirmPassError({
            show: true,
            msg: "Password must be at least 6 characters and contain both numbers and letters",
          });
        } else if (!(/[a-zA-Z]/.test(newPassword) && /\d/.test(newPassword))) {
          setConfirmPassError({
            show: true,
            msg: "Password must contain both numbers and letters",
          });
        } else {
          const res = await auth.changeUserPassword(oldPassWord, newPassword);
          if (res.data) {
            handleCloseAllModals();
            ToastSuccessFunction("Password Changed Successfully");
            handleCloseChangePassword();
          } else {
            setoldpassError({ msg: "Wrong Password", show: true });
            setNewPassError({
              msg: "",
              show: false,
            });
            setConfirmPassError({
              msg: "",
              show: false,
            });
          }
        }
      } else {
        setoldpassError({ msg: "", show: false });
        setNewPassError({
          msg: "",
          show: true,
        });
        setConfirmPassError({
          msg: "New Password and Confirm Password mismatch",
          show: true,
        });
      }
    } else {
      if (oldPassWord === "") {
        setoldpassError({ msg: "Required", show: true });
      }
      if (newPassword === "") {
        setNewPassError({ msg: "Required", show: true });
      }
      if (confirmPassword === "") {
        setConfirmPassError({ msg: "Required", show: true });
      }
    }
  }
  const handleShowPasswords = (type: string) => {
    switch (type) {
      case "oldPass":
        setPasswordsInputType((prevState) => {
          return {
            ...prevState,
            oldPass: prevState.oldPass === "password" ? "text" : "password",
          };
        });
        break;
      case "newPass":
        setPasswordsInputType((prevState) => {
          return {
            ...prevState,
            newPass: prevState.newPass === "password" ? "text" : "password",
          };
        });
        break;
      case "confirmPass":
        setPasswordsInputType((prevState) => {
          return {
            ...prevState,
            confirmNewPass:
              prevState.confirmNewPass === "password" ? "text" : "password",
          };
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
        <CloseButton onClick={handleCloseAllModals} />
      </Modal.Header>
      <Modal.Body className="change-password-modal-body">
        <div>
          <label className="form-label">
            Old Password <span className="error-span">*</span>
          </label>
          <input
            onChange={(e) => {
              setOldPassword(e.target.value);
              setoldpassError({ msg: "", show: false });
            }}
            value={oldPassWord}
            autoComplete="new-password"
            ref={(input) => {
              if (input) {
                input.type = passwordsInputType.oldPass;
              }
            }}
            className={`form-control ${oldpassError.show ? "error-input" : ""}`}
            placeholder="Old Password"
            max={20}
            // minLength={6}
          />
          {passwordsInputType.oldPass === "password" ? (
            <i
              className="icon-eye-off"
              onClick={() => handleShowPasswords("oldPass")}
            />
          ) : (
            <i
              className="icon-eye"
              onClick={() => handleShowPasswords("oldPass")}
            />
          )}
          {oldpassError.show && (
            <span className="error-span">{oldpassError.msg}</span>
          )}
        </div>
        <div>
          <label className="form-label">
            New Password <span className="error-span">*</span>
          </label>
          <input
            onChange={(e) => {
              setNewPassword(e.target.value);
              setNewPassError({ msg: "", show: false });
            }}
            value={newPassword}
            autoComplete="new-password"
            ref={(input) => {
              if (input) {
                input.type = passwordsInputType.newPass;
              }
            }}
            className={`form-control ${
              newpassError.show || confirmPassError.show ? "error-input" : ""
            }`}
            placeholder="New Password"
            max={20}
            // minLength={6}
          />
          {passwordsInputType.newPass === "password" ? (
            <i
              className="icon-eye-off"
              onClick={() => handleShowPasswords("newPass")}
            />
          ) : (
            <i
              className="icon-eye"
              onClick={() => handleShowPasswords("newPass")}
            />
          )}
          {newpassError.show && (
            <span className="error-span">{newpassError.msg}</span>
          )}
        </div>
        <div>
          <label className="form-label">
            Confirm New Password <span className="error-span">*</span>
          </label>
          <input
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPassError({ msg: "", show: false });
            }}
            value={confirmPassword}
            autoComplete="new-password"
            ref={(input) => {
              if (input) {
                input.type = passwordsInputType.confirmNewPass;
              }
            }}
            className={`form-control ${
              confirmPassError.show ? "error-input" : ""
            }`}
            placeholder="Confirm New Password"
            maxLength={20}
            minLength={6}
          />
          {passwordsInputType.confirmNewPass === "password" ? (
            <i
              className="icon-eye-off"
              onClick={() => handleShowPasswords("confirmPass")}
            />
          ) : (
            <i
              className="icon-eye"
              onClick={() => handleShowPasswords("confirmPass")}
            />
          )}

          {confirmPassError.show && (
            <span className="error-span">{confirmPassError.msg}</span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-4">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => handlesumbit()}
        >
          Submit
        </button>
        <button
          onClick={handleCloseChangePassword}
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Back
        </button>
      </Modal.Footer>
    </>
  );
};
