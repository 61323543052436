import http from "./httpService";

const url = "/CommandCategory";

export type Category = {
  id: number;
  category: string;
};

export async function GetCategories(): Promise<{ data: { data: Category[] } }> {
  return await http.get(`${url}/GetAllCategories`);
}
