/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import logo from "../img/nexey_logo.png";
import { sidebarMenu } from "../staticData/SideBarMenu";
import "../css/sidebarStyles.css";
import styled from "styled-components";
import { ArrowLeft } from "@styled-icons/feather/ArrowLeft";
// back icon
const BackIcon = styled(ArrowLeft)`
  width: 18px;
  height: 18px;
  color: #fff;
`;
export const SideBar = ({
  showSidebar,
  setShowSidebar,
  shownGroupIndex,
  onGroupClicked,
  setActiveOption,
}: {
  showSidebar: boolean;
  setShowSidebar: (showSideBar: boolean) => void;
  shownGroupIndex: number | undefined;
  onGroupClicked: (index: number) => void;
  setActiveOption: (activeOption: string) => void;
}) => {
  const user = useAuth().user;
  const sideBarMainMenu = sidebarMenu.map((menu, index) => {
    const permissionMatch = (user?.permissions || []).some((permission) =>
      menu.allowedPermissions.includes(permission)
    );
    return permissionMatch ? (
      <li
        key={index}
        className={shownGroupIndex === menu.groupIndex ? "active" : ""}
      >
        <a
          onClick={() => onGroupClicked(menu.groupIndex)}
          href="#submenu-1"
          aria-expanded={shownGroupIndex === menu.groupIndex}
        >
          <div className="d-flex align-items-center justify-content-between">
            <span className="menu-item-icon">{menu.icon}</span>
            <span className="menu-item-title">{menu.title}</span>
          </div>
          <a
            href="#"
            aria-expanded={shownGroupIndex === menu.groupIndex}
            className="dropdown-toggle"
          >
            {" "}
          </a>
        </a>

        <ul
          className={`
          collapse submenu-collapse sidebar-links-list
          ${shownGroupIndex === menu.groupIndex ? "show" : ""}
          `}
          id="submenu-1"
          data-bs-parent="#main-menu"
        >
          {menu.links.map((link, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  if (window.innerWidth < 769) {
                    setShowSidebar(false);
                  }
                  setActiveOption("Search Pages");
                }}
                className={` sidebar-link ${
                  window.location.pathname === link.route
                    ? "sidebar-link-active"
                    : ""
                } `}
              >
                <Link to={link.route}>
                  <span className="bullet">&bull;</span> {link.RouteName}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    ) : (
      <></>
    );
  });
  return (
    <nav id="sidebar" className={showSidebar ? "active" : undefined}>
      <div className="sidebar-header">
        <div className="logo-container d-flex justify-content-between">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div
            className="backBtnContainer d-lg-none d-flex"
            onClick={() => setShowSidebar(false)}
          >
            <BackIcon />
          </div>
        </div>
      </div>
      <ul id="main-menu">{sideBarMainMenu}</ul>
    </nav>
  );
};
