import moment from "moment";
import InfoElement from "./InfoElement";

const titles = [
  { title: "Device Type", attr: "deviceType" },
  { title: "Device Model", attr: "deviceModel" },
  { title: "Serial Number", attr: "serialNumber" },

  //{ title: "Agent Version", attr: "totalRunningDuration" },
];

export default function DeviceInformation(data: any) {
  return (
    <div>
      {data && (
        <ul className="m-2 d-flex flex-column gap-3">
          <InfoElement
            title={"Device Name"}
            description={data.data["deviceName"]}
          />
          <InfoElement
            title={"OS"}
            description={`${data.data["deviceOsName"]}  ${data.data["deviceOsVersion"]}`}
          />

          {titles.map((element: { title: string; attr: string }, index) => (
            <div key={index}>
              <InfoElement
                title={element.title}
                description={data.data[element.attr as keyof typeof data]}
              />
            </div>
          ))}
          <InfoElement
            title={"Last Seen Online"}
            description={moment(
              data.data["lastSeenOnline"],
              "YYYY-MM-DD HH:mm"
            ).toString()}
          />
          <InfoElement
            title={"Total Running Duration"}
            description={
              Math.round(data.data["totalRunningDuration"] / 60 / 60) + " H"
            }
          />
          <InfoElement
            title={"Agent Version"}
            description={data.data["agentVersion"] ?? "-"}
          />
        </ul>
      )}
    </div>
  );
}
