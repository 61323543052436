import { GetApplicationsAndDevicesComplianceResponseDevice } from "../../../client/AuthedClient";
import { Table, width80pxStyle } from "../../Table";

export const ApplicationsComplianceDevicesTable = (props: {
  devices: Array<GetApplicationsAndDevicesComplianceResponseDevice>;
}) => {
  const { devices } = props;

  const colwidth =
    window.innerWidth <= 482 ? { width: "150px" } : width80pxStyle;

  const columns = [
    { name: "Device Name", attr: "computerName" },
    { name: "Device Serial", attr: "computerSerial", style: colwidth },
    { name: "Compliant Apps", attr: "allowedApps" },
    { name: "Non-Compliant  Apps", attr: "notAllowedApps" },
  ];

  const rowsData: any = [];
  // eslint-disable-next-line array-callback-return
  devices.map((el) => {
    const dataObj = {
      computerName: el.computerName,
      computerSerial: el.computerSerial,
      allowedApps: el.allowedApps,
      notAllowedApps: el.notAllowedApps,
    };
    rowsData.push(dataObj);
  });

  // const rowStrings = devices.map((device) => [
  //   device.computerName,
  //   device.computerSerial,
  // ]);
  const rowStrings = {
    key: ["computerName", "computerSerial"],
    items: devices.map((device) => [
      device.computerName,
      device.computerSerial,
    ]),
  };
  const rows = (
    array: Array<GetApplicationsAndDevicesComplianceResponseDevice>
  ) => {
    return array.map((device, index) => (
      <tr key={index}>
        <td>{device.computerName}</td>
        <td>{device.computerSerial}</td>
        <td>{device.allowedApps}</td>

        <td>{device.notAllowedApps}</td>
      </tr>
    ));
  };

  return (
    <div className="globalSpaceBetweenContainers">
      <Table
        pageTitle={"Devices Compliance"}
        excelData={rowsData}
        rows={rows}
        rowsData={rowsData}
        columns={columns}
        rowsStrings={rowStrings}
      />
    </div>
  );
};
