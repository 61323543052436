import { useAuthedClient } from "../context/AuthedClientContext";
import AuthedClient, { GetVisitedSitesResponse } from "../client/AuthedClient";
import { Page } from "../components/Page";
import { Filters } from "../context/FiltersContext";
import { VisitedSitesTable } from "../components/VisitedSites";
import { VisitedSitesPieChart } from "../components/VisitedSites/VisitedSitesPieChart";
import "../components/VisitedSites/VisitedSites.css";
import "../css/ChartContainerStyles.css";
export function VisitedSites() {
  const authedClient = useAuthedClient();

  return (
    <Page<GetVisitedSitesResponse>
      fetchData={(filters) => fetchData(authedClient, filters)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Dashboard",
        subTitle: "Visited Sites",
      }}
    />
  );
}

async function fetchData(
  authedClient: AuthedClient,
  filters: Filters
): Promise<GetVisitedSitesResponse> {
  const { startDate, endDate, group, user } = filters;

  return await authedClient.getVisitedSites(startDate, endDate, group, user);
}

function shouldShowNoData(data: GetVisitedSitesResponse): boolean {
  return data.length === 0;
}

function body(data: GetVisitedSitesResponse) {
  return (
    <div>
      <div className="upperRow">
        <div className="globalSpaceBetweenContainers">
          <VisitedSitesPieChart sites={data} />
        </div>
        <div className="rightcolumn globalSpaceBetweenContainers">
          <VisitedSitesTable sites={data} />
        </div>
      </div>
    </div>
  );
}
