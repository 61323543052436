import { CloseButton, Dropdown, Modal } from "react-bootstrap";
import { ChangeEvent, MutableRefObject, useRef, useState } from "react";
import moment from "moment";
import "./ApplicationLicense.css";
export const ApplicationLicenseFormModal = (props: any) => {
  const {
    data,
    errors,
    appsWithoutLicese,
    lgShow,
    editing,
    handleSubmit,
    handleChange,
    handleCloseModal,
  } = props;
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [versionSearchTerm, setVersionSearchTerm] = useState("");
  const searchInputEl = useRef<HTMLInputElement | null>(null);
  const getApplicationVersion = (Id: any) =>
    data.appVersion ? data.appVersion : getAppVersion(Number(Id));
  const filteredApplications = appsWithoutLicese
    .filter(
      (app: any, index: any, self: any) =>
        index === self.findIndex((obj: any) => obj.appName === app.appName)
    )
    .filter((app: any) =>
      app.appName
        .toLocaleLowerCase()
        .includes(nameSearchTerm.toLocaleLowerCase())
    )
    .map((option: any, index: number) => (
      <Dropdown.Item
        disabled={editing}
        href="#"
        key={index}
        eventKey={option.appName}
        className={`${
          option.appName === data["applicationName"]
            ? "page-header-dropdown page-header-dropdown-light-text-active"
            : "page-header-dropdown"
        }`}
      >
        {option.appName}
      </Dropdown.Item>
    ));
  const versionsOptions = appsWithoutLicese
    .filter((el: any) => el.appName === data.applicationName)
    .filter((app: any) =>
      app.appVersion
        ? app.appVersion
            .toLocaleLowerCase()
            .includes(versionSearchTerm.toLocaleLowerCase())
        : true
    )
    .map((el: any, index: number) => (
      <Dropdown.Item
        href="#"
        key={index}
        eventKey={el.id}
        className={`${
          el.applicationVersion === data["appVersion"]
            ? "page-header-dropdown page-header-dropdown-light-text-active"
            : "page-header-dropdown"
        }`}
      >
        {el.appVersion ? el.appVersion : "No Version"}
      </Dropdown.Item>
    ));
  const handleResetFormOnClose = () => {
    setNameSearchTerm("");
    setVersionSearchTerm("");
    handleCloseModal();
  };
  function getAppVersion(id: number) {
    if (id) {
      let obj = appsWithoutLicese.filter((el: any) => el.id === id);
      return obj[0].appVersion ? obj[0].appVersion : "No Version";
    } else return 0;
  }
  function focus(
    nextShow: boolean,
    el: MutableRefObject<HTMLInputElement | null>
  ) {
    if (nextShow) {
      setTimeout(() => el.current?.focus());
    }
  }
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
    setNameSearchTerm("");
    setVersionSearchTerm("");
  };
  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={handleResetFormOnClose}
      show={lgShow}
      size="lg"
    >
      <form className="modal-content" onSubmit={handleFormSubmit}>
        <Modal.Header>
          <Modal.Title>
            {editing ? data.applicationName : "Add New"}
          </Modal.Title>
          <CloseButton onClick={handleResetFormOnClose} />
        </Modal.Header>
        <Modal.Body className="overflow-visible width-max-content app-license-drop-down">
          <div className="row g-3">
            <div className="col-lg-6">
              <label className="form-label">
                Application name
                {!editing && <span className="error-span">*</span>}
              </label>
              <Dropdown
                style={{ width: "100%" }}
                onSelect={(eventKey: any) => {
                  delete errors["applicationName"];
                  handleChange({
                    currentTarget: {
                      name: "applicationName",
                      value: eventKey,
                    },
                  });
                  handleChange({
                    currentTarget: {
                      name: "appVersion",
                      value: "",
                    },
                  });
                }}
                onToggle={(nextShow: any) => {
                  focus(nextShow, searchInputEl);
                  setNameSearchTerm("");
                }}
              >
                <Dropdown.Toggle
                  variant="light"
                  style={
                    editing
                      ? { pointerEvents: "none" }
                      : errors["applicationId"]
                      ? { border: "1px solid red", marginBottom: "0.5rem" }
                      : {}
                  }
                >
                  {data.applicationName
                    ? data.applicationName
                    : "Choose Application"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-filter-dropdown-menu">
                  <div>
                    <input
                      ref={searchInputEl}
                      type="search"
                      placeholder="Search"
                      value={nameSearchTerm}
                      className="form-control navbar-drop-down-search"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNameSearchTerm(e.target.value)
                      }
                    />
                  </div>
                  {filteredApplications.length === 0 ? (
                    <span className="Span-No-Data">No Data Found</span>
                  ) : (
                    filteredApplications
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {(errors["list"] && (
                <span className="error-span">{errors["list"]}</span>
              )) ||
              (errors["applicationName"] && !editing) ? (
                <span className="error-span">{errors["applicationName"]}</span>
              ) : (
                <></>
              )}
            </div>
            <div className="col-lg-6">
              <div className="row alignItems">
                <div className="col">
                  <label className="form-label">
                    Application Version
                    {!editing && <span className="error-span">*</span>}
                  </label>
                  <Dropdown
                    style={{ width: "100%" }}
                    onSelect={(eventKey: any) => {
                      delete errors["appVersion"];
                      handleChange({
                        currentTarget: {
                          name: "applicationId",
                          value: Number(eventKey),
                        },
                      });
                      handleChange({
                        currentTarget: {
                          name: "appVersion",
                          value: getApplicationVersion(Number(eventKey)),
                        },
                      });
                    }}
                    onToggle={(nextShow: any) => {
                      focus(nextShow, searchInputEl);
                      setVersionSearchTerm("");
                    }}
                  >
                    <Dropdown.Toggle
                      variant="light"
                      style={
                        editing || !data["applicationName"]
                          ? { pointerEvents: "none", minWidth: "160px" }
                          : errors["appVersion"]
                          ? { border: "1px solid red", marginBottom: "0.5rem" }
                          : { minWidth: "160px" }
                      }
                    >
                      {data.appVersion
                        ? data.appVersion
                        : data.applicationId
                        ? "No Version"
                        : "Choose Version"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="user-filter-dropdown-menu">
                      <div>
                        <input
                          ref={searchInputEl}
                          type="search"
                          placeholder="Search"
                          value={versionSearchTerm}
                          className="form-control navbar-drop-down-search"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setVersionSearchTerm(e.target.value)
                          }
                        />
                      </div>
                      {versionsOptions.length === 0 ? (
                        <span className="Span-No-Data">No Data Found</span>
                      ) : (
                        versionsOptions
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors["appVersion"] && !editing ? (
                    <span className="error-span">{errors["appVersion"]}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col appCap">
                  <label className="form-label">
                    Application Capacity <span className="error-span">*</span>
                  </label>
                  <input
                    type="number"
                    name="noLicenses"
                    value={data["noLicenses"]}
                    onChange={(e: any) => {
                      handleChange(e);
                      if (e.target.value === "") {
                        errors["noLicenses"] = `This field is reqiured`;
                      } else if (
                        (!Number.isInteger(Number(e.target.value)) &&
                          Number.isFinite(Number(e.target.value))) ||
                        Number(e.target.value) <= 0
                      ) {
                        errors[
                          "noLicenses"
                        ] = `capacity accepts positive whole number`;
                      } else {
                        delete errors["noLicenses"];
                      }
                    }}
                    className={`form-control noLicensesInput ${
                      errors["noLicenses"] ? "error-input" : ""
                    }`}
                    // placeholder="Capacity"
                  />
                  {errors["noLicenses"] && (
                    <span className="error-span">{errors["noLicenses"]}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                License Start Date <span className="error-span">*</span>
              </label>
              <input
                type="date"
                name="licenseStartDate"
                value={data["licenseStartDate"] ? data["licenseStartDate"] : ""}
                onChange={(e) => {
                  handleChange(e);
                  const startDate = moment(e.target.value);
                  const startDateFormat = startDate.format("MM/DD/YYYY");
                  const expireDate = moment(data.licenseExpirationDate);
                  const expireDateFormat = expireDate.format("MM/DD/YYYY");
                  if (e.target.value === "") {
                    errors["licenseStartDate"] = "This field is required";
                  } else if (startDateFormat >= expireDateFormat) {
                    errors["licenseStartDate"] =
                      "Start date must be less than end date";
                  } else {
                    delete errors["licenseStartDate"];
                    delete errors["licenseExpirationDate"];
                  }
                }}
                className={`form-control ${
                  errors["licenseStartDate"] ? "error-input" : ""
                }`}
                placeholder="License Start Date"
              />
              {(errors["licenseStartDate"] ||
                errors["licenseExpirationDate"]) && (
                <div className="errorClass">
                  <span className="error-span errorClass">
                    {errors["licenseStartDate"] ||
                      errors["licenseExpirationDate"]}
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">License Expiration Date</label>
              <input
                type="date"
                name="licenseExpirationDate"
                value={
                  data["licenseExpirationDate"]
                    ? data["licenseExpirationDate"]
                    : ""
                }
                onChange={handleChange}
                className={`form-control`}
                placeholder="License Expiration Date"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button
            onClick={handleResetFormOnClose}
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
