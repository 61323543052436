function ChartRightContainerInfo({
  complied,
  text,
  notComplied,
}: {
  complied: number;
  text: string;
  notComplied: number;
}) {
  return (
    <div className="d-flex justify-content-center align-items-center appComplianceDataContainer flex-column flex-md-row gap-4 ">
      <div className="countNo d-flex justify-content-center align-items-center flex-column text-center">
        <div>
          <h3>{complied + notComplied}</h3>
          <p>Total {text}</p>
        </div>
      </div>
      <div className="countNo d-flex justify-content-center align-items-center flex-column text-center">
        <div>
          <h3 className="text-danger">{notComplied}</h3>
          <p>Non Compliant {text}</p>
        </div>
      </div>

      <div className="countNo d-flex justify-content-center align-items-center flex-column text-center">
        <div>
          <h3 style={{ color: "#34B53A" }}>{complied}</h3>
          <p>Compliant {text}</p>
        </div>
      </div>
    </div>
  );
}

export default ChartRightContainerInfo;
