import http from "../httpService";

const url = "/UrlLookup/InsertURL";
http.setAuthorizationToken();

type Props = {
  url: string;
  labelId: number | null;
};
export function addMaliciousUrl(data: Props) {
  return http.post(url, data);
}
