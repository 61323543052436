
import styled, { keyframes } from 'styled-components';
import { Spinner } from "@styled-icons/evil/Spinner"

// Define a spin animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Create a styled icon with the spin animation
const Spinnerload = styled(Spinner)`
  animation: ${spin} 1s linear infinite;
  font-size: 2rem; /* Adjust size as needed */
  color: #ffffff;  /* Adjust color as needed */
`;

const LoaderSpin = () => {
  return <Spinnerload />;
};

export default LoaderSpin;
