import { CloseButton, Modal } from "react-bootstrap";
import { Table } from "../Table";
import noDataImg from "../../img/no-data.png";
import { useCallback, useEffect, useState } from "react";
import { getAgentDevicesAvailabilityDetails } from "../../services/AgentDeviceAvailabilityDetailsService";
import { Loader } from "../Loader/Loader";
import { ConvertSecondsToHHMMSS } from "../../utils/TimeUtility";
import { useFilters } from "../../context/FiltersContext";
import { ConverToLocalAndFormat } from "../../utils/Date-Time-Util/DateTimeFormater";

export const AgentAvailabilityModal = (props: {
  modalData: any;
  showModal: boolean;
  onHide: () => void;
  modalTitle: string;
}) => {
  const { modalData, showModal, onHide, modalTitle } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { startDate, endDate }: any = useFilters();

  const fetchData = useCallback(async () => {
    await getAgentDevicesAvailabilityDetails(
      startDate,
      endDate,
      modalData.computerId
    )
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [startDate, endDate, modalData.computerId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tableColumns = [
    { name: "Date", attr: "date", style: { width: "115px" } },
    {
      name: "Duration",
      attr: "totalDuration",
      style: { width: "160px" },
    },
  ];

  const rows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr key={index}>
        <td>{process.date}</td>
        <td>{process.totalDuration}</td>
      </tr>
    ));
  };

  const rowStrings = {
    key: "date",
    items: data,
  };
  const rowsData = data.map((el: any) => {
    return {
      date: ConverToLocalAndFormat(el.date, "dd/MM/yyyy"),
      totalDuration: ConvertSecondsToHHMMSS(el.totalDuration),
    };
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Modal
          keyboard={false}
          centered={true}
          scrollable={true}
          show={showModal}
          onHide={() => onHide()}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
            <CloseButton onClick={() => onHide()} />
          </Modal.Header>

          <Modal.Body>
            {rowsData.length > 0 ? (
              <Table
                pageTitle={"Devices Compliance Details"}
                rows={rows}
                rowsData={rowsData}
                excelData={rowsData}
                columns={tableColumns}
                rowsStrings={rowStrings}
              />
            ) : (
              <div className="white-box m-0">
                <div
                  className="noData"
                  style={{ minHeight: "100px", padding: 0 }}
                >
                  <div className="align-middle">
                    <i>
                      <img src={noDataImg} alt="No Data Found !" />
                    </i>
                    <p>No Data Found !</p>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
