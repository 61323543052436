import { ApplicationsPerfromanceGuageChart } from "./ApplicationsPerfromanceGuageChart";
import styled from "styled-components";
import { Timer } from "@styled-icons/material-sharp/Timer";
import { useNavbarContext } from "../../layout/Layout";
import "../../css/CardsStyles.css";
import { ConvertSecondsToHHMMSS } from "../../utils/TimeUtility";

const TimerIcon = styled(Timer)`
  width: 16px;
  height: 16px;
  color: #4453c5;
  margin-top: -3px;
`;
export const ApplicationsPerformanceChartCard = (props: {
  processName: string;
  computersCount: number;
  failureCount: number;
  failureComputersCount: number;
  totalDurationInSeconds: number;
  percentage: number;
}) => {
  const {
    processName,
    totalDurationInSeconds,
    computersCount,
    failureCount,
    failureComputersCount,
    percentage,
  } = props;
  const { showSidebar } = useNavbarContext();
  const percent = percentage * 100;
  return (
    <div className="d-flex flex-column w-100">
      <div className="header-container">
        <h2 className="mb-0">{processName}</h2>
      </div>
      <div className="d-flex justify-content-between mt-4 w-100">
        <div className="d-flex flex-column justify-content-center w-50">
          <div className="d-flex h6  align-items justify-content-between w-100">
            <p className="">Total Devices</p>
            <p className="text-center col-2 h6 ">{computersCount}</p>
          </div>
          <div className="d-flex h6 justify-content-between w-100">
            <p>Failure Devices</p>
            <p className="text-center col-2 h6">{failureComputersCount}</p>
          </div>
          <div className="d-flex h6 justify-content-between w-100">
            <p>Failure Count</p>
            <p className="text-center col-2 h6 ">{failureCount}</p>
          </div>
        </div>
        <div className="d-flex flex-column justify-content text-center align-items-center gap-2">
          <div
            className="p-1 text-primary bg-light mb-2 shadow-sm"
            style={{ borderRadius: "20px" }}
          >
            <div className="d-flex align-items-baseline ">
              <div className="p-1">
                <TimerIcon />
              </div>

              <div style={{ color: "#4453C5" }}>
                <span>Duration : </span>
                <span> {ConvertSecondsToHHMMSS(totalDurationInSeconds)}</span>
              </div>
            </div>
          </div>

          <div>
            <ApplicationsPerfromanceGuageChart
              showSidebar={showSidebar}
              percentage={percentage}
            />
          </div>
          <p
            className={` ${
              percent >= 90 ? "text-success" : percent < 90 ? "text-danger" : ""
            } w-100  `}
            style={{ fontSize: "1.2rem" }}
          >
            {+percent?.toFixed(2)}%
          </p>
        </div>
      </div>
    </div>
  );
};
